import {CHANGE_REGISTER_DRAWER_VISIBILITY} from "../../types/authTypes";

export function changeRegisterDrawerVisibilityAction(visibility) {
    return ((dispatch) => {
        dispatch(changeRegisterDrawerVisibility(visibility));
    });
}

const changeRegisterDrawerVisibility = (visibility) => ({
    type: CHANGE_REGISTER_DRAWER_VISIBILITY,
    payload: visibility
})
