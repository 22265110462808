import React from "react";
import {DateTime} from "luxon";
import {ChevronRight as RightIcon, GetAppOutlined as DownloadIcon} from "@material-ui/icons";
import {Button, Card, CardActions, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import useVoucher from "../../../hooks/useVoucher";

const useStyles = makeStyles((theme) => ({
    resendButton: {
        marginTop: "1rem"
    },
    voucherDataHeader: {
        display: "flex",
        gap: "1rem",
        alignItems: "baseline"
    },
    voucherDataTitle: {
        paddingBottom: "0.5rem"
    },
    voucherActions: {
        padding: 0
    },
    voucherDataCard: {
        marginLeft: "-1rem",
        textAlign: "left",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        background: grey[100]

    }
}));

const ButtonForDownloadVoucher = () => {
    const classes = useStyles();
    const { voucherSelected, onClickDownload, onClickSendMail} = useVoucher();

    return (
        <Card className={classes.voucherDataCard}>
            <div className={classes.voucherDataHeader}>
                <Typography className={classes.voucherDataTitle} variant="h6">Estado del cupón:</Typography>

                <span style={voucherSelected.getStatusStyle()}>{voucherSelected.getStatus().toUpperCase()}</span>
            </div>
            {
                voucherSelected.type === "product" ?
                    <React.Fragment>
                        <Typography>{`Número de usos inicial: ${voucherSelected.amount}`}</Typography>
                        <Typography>{`Número de usos restantes: ${voucherSelected.getRemainingAmount()}`}</Typography>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Typography>{`Valor restante: ${voucherSelected.getRemainingAmount().toFixed(2)}€`}</Typography>
                        <Typography>{`Valor inicial: ${voucherSelected.amount.toFixed(2)}€`}</Typography>
                    </React.Fragment>
            }
            <Typography>{`Fecha de compra: ${DateTime.fromISO(voucherSelected.createdAt).setLocale('es').toLocaleString(DateTime.DATE_FULL)}`}</Typography>
            <Typography>{`Válido hasta: ${DateTime.fromISO(voucherSelected.expirationDate).setLocale('es').toLocaleString(DateTime.DATE_FULL)}`}</Typography>

            <CardActions className={classes.voucherActions}>
                <Button variant="contained" color="secondary" className={classes.resendButton} onClick={onClickDownload}
                        disabled={voucherSelected.status !== "active"} download endIcon={<DownloadIcon/>} href={""}>
                    DESCARGAR
                </Button>
                <Button variant="contained" color="secondary" className={classes.resendButton} onClick={onClickSendMail}
                        disabled={voucherSelected.status !== "active"} endIcon={<RightIcon/>}>
                    ENVIAR POR EMAIL
                </Button>
            </CardActions>
        </Card>
    )
};

export default ButtonForDownloadVoucher;
