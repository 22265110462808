import { combineReducers } from 'redux';

import accountReducer from "./accountReducer";
import addressReducer from "./addressReducer";
import authReducer from "./authReducer";
import cartReducer from "./cartReducer";
import categoryReducer from "./categoryReducer";
import clientReducer from "./clientReducer";
import creditCardReducer from "./creditCardReducer";
import deliveryDateReducer from "./deliveryDateReducer"
import detailReducer from "./detailReducer";
import notificationReducer from "./notificationReducer";
import orderReducer from "./orderReducer";
import paymentMethodReducer from "./paymentMethodReducer";
import productReducer from "./productReducer";
import shippingMethodReducer from "./shippingMethodReducer";
import voucherReducer from "./voucherReducer";
import infoReducer from "./infoReducer"

export default combineReducers({
   accountReducer,
   addressReducer,
   authReducer,
   cartReducer,
   categoryReducer,
   clientReducer,
   creditCardReducer,
   deliveryDateReducer,
   detailReducer,
   notificationReducer,
   orderReducer,
   paymentMethodReducer,
   productReducer,
   shippingMethodReducer,
   voucherReducer,
   infoReducer
});
