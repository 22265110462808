import axiosClient from "../config/axios";

export function fetchPricesByCategoryIdAsync(familyId, limit = 10, offset = 0) {
    return axiosClient.get(`/clients/${localStorage.getItem('clientId')}/families/${familyId}/prices?offset=${offset}&size=${limit}`)
        .then(response => response.data)
        .catch(error => new Error(error.toString()));
}
export function fetchPricesByFeeIdAndCategoryId(familyId, unregisteredFeeId, limit = 50, offset = 0) {
    return axiosClient.get(`/fees/${unregisteredFeeId}/prices?familyId=${familyId}&offset=${offset}&size=${limit}`)
        .then(response => response.data)
        .catch(error => new Error(error.toString()));
}

export function fetchFeaturedPricesAsync() {
    return axiosClient.get(`/clients/${localStorage.getItem('clientId')}/prices?type=featured`)
        .then(response => response.data)
        .catch(error => new Error(error.toString()))
}
export function fetchFeaturedPricesByFeeId(unregisteredFeeId, limit = 50, offset = 0) {
    return axiosClient.get(`/fees/${unregisteredFeeId}/prices?featured=${true}&offset=${offset}&size=${limit}`)
        .then(response => response.data)
        .catch(error => new Error(error.toString()));
}