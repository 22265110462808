import {NEWSLETTER_SHOWING} from "../../types/infoTypes.js";

export function changeNewsletterVisibilityAction(visibility) {
    return (dispatch) => {
        dispatch(changeVisibility(visibility));
    }
}

const changeVisibility = visibility => ({
    type: NEWSLETTER_SHOWING,
    payload: visibility
});