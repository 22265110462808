import React from "react";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Detail from '../../../models/Detail'
import useCart from "../../../hooks/useCart";
import drawerStyles from "../../ui/DrawerStyles";
const useStyles = makeStyles(() => ({

    paper: {
        background: "black"
    },
    detailItem: {
        paddingLeft: 10,
        paddingRight: 10,
        alignItems: "center",
        justifyItems: "center",
        background: "white",
        borderBottom: "1px solid #f5f5f5"

    },
    detailPrice: {
        display: "flex",
        webkitBoxOrient: "vertical",
        webKitBoxDirection: "normal",
        webkitFlexDirection: "column",
        flexDirection: "column",
        webkitBoxAlign: "end",
        webkitAlignItems: "flex-end",
        alignItems: "flex-end",
        overflow: "hidden",
        webkitFlexShrink: 0,
        flexShrink: 0,
        position: "relative"
    },
    detailProductName: {
        alignItems: "center",
        lineHeight: "1"
    },
    detailProductImage: {
        marginTop: 0
    }
}));

const DetailInOrderCell = ({ detail }) => {
    const classes = useStyles();

    if(!detail)
    return null;

    return (
        (detail.price.product.voucherInfo && detail.price.product.voucherInfo.type === "product") ?
            <ListItem alignItems="flex-start" key={detail.price.id} className={classes.detailItem}>

                <ListItemAvatar className={classes.detailProductImage}>
                    <Avatar alt={detail.price.product.name} src={Detail.getMainPhoto(detail)} />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        detail.price.product.name
                    }
                    primaryTypographyProps={
                        {
                            component: "span",
                            variant: "body2",
                            color: "text.primary"
                        }}
                    secondary={
                        <div>
                        <div>{detail.price.product.voucherInfo.type === "product" ?
                        `${detail.quantity} x adulto`:
                        `Valor: ${detail.quantity*detail.price.value} €`}
                        </div>
                        {detail.optionsSelected ?
                            detail.optionsSelected.map(option => option.quantity ? <div>{option.quantity} x {option.name}</div>:""):""
                        }
                        </div>
                    }
                    secondaryTypographyProps={
                        {
                            variant: "subtitle1",
                        }
                    }
                    onClick={() => {}}
                    className={classes.detailProductName}
                />
                <ListItemText
                    primary={<React.Fragment>
                        <Typography
                            component="span"
                            variant="subtitle2"
                            color="textPrimary"
                        >
                            {Detail.getPrice(detail).toFixed(2)}€
                    </Typography>
                    </React.Fragment>}
                    className={classes.detailPrice}
                />
            </ListItem>
            :
            <ListItem alignItems="flex-start" key={detail.price.id} className={classes.detailItem}>

                <ListItemAvatar className={classes.detailProductImage} onClick={() => {}}>
                    <Avatar alt={detail.price.product.name} src={Detail.getMainPhoto(detail)} />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        detail.price.product.name
                    }
                    primaryTypographyProps={
                        {
                            component: "span",
                            variant: "body2",
                            color: "textPrimary"
                        }}
                    secondary={<div>
                        <div>
                        {detail.quantity +" x "+detail.price.value + "€/ud"}
                        </div>
                        {detail.optionsSelected ?
                            detail.optionsSelected.map(option => option.quantity ? <div>{option.quantity} x {option.name}</div>:""):""
                        }
                        </div>
                    }
                    secondaryTypographyProps={
                        {
                            variant: "subtitle1",
                        }

                    }
                    onClick={() => {}}
                    className={classes.detailProductName}
                />
                <ListItemText
                    primary={<React.Fragment>
                        <Typography
                            component="span"
                            variant="subtitle2"
                            color="textPrimary"
                        >
                            {Detail.getFinalPrice(detail).toFixed(2)}€
                    </Typography>
                    </React.Fragment>}
                    className={classes.detailPrice}
                />
            </ListItem>
    )
}

const DetailsInOrderList = () => {
    const drawerClasses = drawerStyles();
    const { detailsInCart } = useCart();

    if (!detailsInCart) {
        return <Typography>Carrito Vacío</Typography>
    }

    return (
        <div className={drawerClasses.container}>
            <List>
                {

                    detailsInCart.map(detail => <DetailInOrderCell key={detail.price.id} detail={detail} />)
                }
            </List>

        </div>
    )
}

export default DetailsInOrderList;
