
const statusStyles ={
    statusChip:{
        height:"32px",
        fontSize:"0.8125rem",
        textAlign:"center",
        paddingLeft:"12px",
        paddingRight:"12px",
        borderRadius:"1.5rem",

        display:"inline-flex",
        alignItems:"center",
        fontFamily:"Roboto Slab",
        fontWeight:500
     },
     statusChipActive:{
        backgroundColor:"#1976d2",
        color:"white",
     },
     statusChipCanceled:{
        backgroundColor:"#e53935",
        color:"white",
     },
     statusChipRedimed:{
        backgroundColor:"#d0cdd1",
        color:"black",
     },

}

class Voucher {
    constructor({id,amount, client, createdAt, dedication, exchanges,expirationDate, product,receiver,shippingInfo,status,type,value}) {
       this.id = id;
       this.amount = amount;
       this.client = client;
       this.createdAt = createdAt;
       this.dedication = dedication;
       this.exchanges = exchanges;
       this.expirationDate = expirationDate;
       this.product = product;
       this.receiver = receiver;
       this.shippingInfo = shippingInfo;
       this.status = status;
       this.type = type;
       this.value = value;
    }

    getStatus() {
        switch (this.status){
            case "active": return "Activo"
            case "cancelled": return "Cancelado"
            case "exchanged": return "Canjeado"
            case "expired": return "Caducado"
            default: return "Activo";
        }
    }
    getConsumedAmount(){
        return this.exchanges.reduce((sum, exchange) => sum + exchange.amount, 0);
    }
    getRemainingAmount(){
        return this.amount - this.getConsumedAmount()
    }
    getActiveStatusStyle(){
        switch (this.status){
            case "active": return "statusChipActive"
            case "cancelled": return "statusChipCanceled"
            case "exchanged": return "statusChipRedimed"
            case "expired": return "statusChipCanceled"
            default: return "statusChipActive"
        }
    }
    getStatusStyle(){
        return {...statusStyles.statusChip,...statusStyles[this.getActiveStatusStyle()]}
    }
}

export default Voucher;

