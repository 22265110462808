import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch } from "react-redux";
import { createAddressAction } from "../../../redux/actions/address/createAddressAction";
import drawerStyles from "../../ui/DrawerStyles";
import DrawerHeader from '../cart/DrawerHeader'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useAddress from "../../../hooks/useAddress";
import useAccount from "../../../hooks/useAccount";
import { loadClientAction } from "../../../redux/actions/client/loadClientAction"

const styles = makeStyles(() => ({
    actionBar: {
        marginTop: 20
    },
}))

const addressSchema = Yup.object({
    name: Yup.string().min(3, "Mínimo 3 carácteres")
        .required('Debes introducir un nombre'),
    city: Yup.string()
        .required('Debes de introducir el nombre de la ciudad'),
    phone: Yup.string().trim().min(9, "Faltan números").matches(/^[0-9]+$/, "El teléfono solo debe de estar compuesto por números").required('Debes introducir un teléfono'),
    postalCode: Yup.string().trim().matches(/^[0-9]+$/, "Código postal incorrecto")
        .length(5, "Código postal incorrecto")
        .required("Debes proporcionar un código postal"),
    street: Yup.string()
        .min(5, 'Mínimo 5 carácteres')
        .required('Debes de introducir la calle, número y piso'),
})

const CreateAddressDrawer = () => {
    const dispatch = useDispatch();
    const classes = drawerStyles();
    const myClasses = styles();

    const { addressEditorOpened, onCloseAddressEditor } = useAddress();
    const { client, updatingClientError, updatingClientSuccess, isUpdatingClient, isLoadingClient } = useAccount();

    useEffect(() => {
        if (!client) {
            dispatch(loadClientAction())
        }

    }, [addressEditorOpened])


    return (
        <Drawer anchor={"right"} open={addressEditorOpened} onClose={onCloseAddressEditor} width={1 / 3}>
            <DrawerHeader title="Añadir una nueva dirección" onHandleClose={onCloseAddressEditor} />
            <Formik
                initialValues={{
                    name: client ? client.name : "",
                    city: "",
                    phone: client ? client.phone : "",
                    postalCode: client ? client.postalCode : "",
                    street: "",
                }}
                validationSchema={addressSchema}
                onSubmit={(values) => {
                    const addressForm = addressSchema.cast(values)//esto es necesario para que las transformaciones (por ejemplo, el trim) se apliquen
                    dispatch(createAddressAction(addressForm));
                }}>
                {props => (
                    <form onSubmit={props.handleSubmit}>
                        <DialogContent className={classes.container}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                name="name"
                                label="Nombre del destinatario"
                                type="text"
                                color="secondary"
                                variant="outlined"
                                fullWidth
                                {...props.getFieldProps('name')}
                                helperText={props.touched.name && props.errors.name}
                                error={props.touched.name && props.errors.name !== undefined}
                            />
                            <TextField
                                margin="dense"
                                id="street"
                                name="street"
                                label="Calle y Número"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('street')}
                                helperText={props.touched.street && props.errors.street}
                                error={props.touched.street && props.errors.street !== undefined}
                            />
                            <TextField
                                margin="dense"
                                id="city"
                                name="city"
                                label="Ciudad"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('city')}
                                helperText={props.touched.city && props.errors.city}
                                error={props.touched.city && props.errors.city !== undefined}
                            />
                            <TextField
                                margin="dense"
                                id="postalCode"
                                name="postalCode"
                                label="Código Postal"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('postalCode')}
                                helperText={props.touched.postalCode && props.errors.postalCode}
                                error={props.touched.postalCode && props.errors.postalCode !== undefined}
                            />
                            <TextField
                                margin="dense"
                                key="phone"
                                id="phone"
                                name="phone"
                                fullWidth
                                color="secondary"
                                label="Teléfono del destinatario"
                                variant="outlined"
                                placeholder="Teléfono del destinatario para el repartidor"
                                {...props.getFieldProps('phone')}
                                helperText={props.touched.phone && props.errors.phone}
                                error={props.touched.phone && props.errors.phone}
                            />
                            <div className={myClasses.actionBar}>
                                <Button fullWidth variant="contained" className={classes.acceptButton} type="submit">
                                    GUARDAR
                                </Button>
                            </div>
                        </DialogContent>
                    </form>
                )}
            </Formik>
        </Drawer>
    );
}


export default CreateAddressDrawer;
