import {CHANGE_PAYMENT_METHOD_SELECTED} from "../../types/paymentMethodTypes";

export function changePaymentMethodSelectedAction(paymentMethod) {
    return (dispatch) => {
        dispatch(changeShippingMethodSelected(paymentMethod));
    }
}

const changeShippingMethodSelected = paymentMethod => ({
    type: CHANGE_PAYMENT_METHOD_SELECTED,
    payload: paymentMethod
});
