import {COOKIE_POLICY_SHOWING} from "../../types/infoTypes.js";

export function changeCookiePolicyVisibilityAction(visibility) {
    return (dispatch) => {
        dispatch(changeVisibility(visibility));
    }
}

const changeVisibility = visibility => ({
    type: COOKIE_POLICY_SHOWING,
    payload: visibility
});