import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import clsx from 'clsx';
import logo from '../assets/manzanoshop.png'
import CategoryList from "../components/category/CategoryList"
import {
    AppBar,
    Badge,
    Box,
    Toolbar, makeStyles
} from '@material-ui/core';
import {
    ShoppingCart as CartIcon,
    User as UserIcon
} from 'react-feather';
import {changeAuthFormVisibilityAction} from "../../redux/actions/auth/changeAuthFormVisibilityAction";
import {changeAccountDrawerVisibilityAction} from "../../redux/actions/account/changeAccountDrawerVisibilityAction";
import {changeCartVisibilityAction} from "../../redux/actions/cart/changeCartVisibilityAction";
import {Link} from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
    },
    toolbar: {
        minHeight: 64,
        backgroundColor: '#FFFFFF'
    },
    logo: {
        height: "4em",
        padding: "0.5em"
    }
}));

function TopBar({className, onMobileNavOpen, ...rest}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const productsInCart = useSelector(state => state["cartReducer"].detailsInCart);
    const isLogged = useSelector(state => state["authReducer"].isLogged);

    const onCartIconClicked = () => {
        dispatch(changeCartVisibilityAction(true))
    }

    const onUserIconClicked = () => {
        if (isLogged) {
            dispatch(changeAccountDrawerVisibilityAction(true))
        } else {
            dispatch(changeAuthFormVisibilityAction(true))
        }
    }

    return (
        <AppBar
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Toolbar className={classes.toolbar}>
                <Link to={"/"}><img src={logo} className={classes.logo} alt={""}/></Link>
                {
                    fullScreen ? "" :
                        <CategoryList/>
                }
                <Box
                    ml={2}
                    flexGrow={1}
                />
                <UserIcon onClick={onUserIconClicked}/>
                <Box ml={2}>
                    <Badge badgeContent={productsInCart.length} color="secondary" onClick={onCartIconClicked}>
                        <CartIcon/>
                    </Badge>
                </Box>
            </Toolbar>
        </AppBar>

    );
}


export default TopBar;
