import { createTheme } from '@material-ui/core/styles';
import "@fontsource/roboto-slab"
import "@fontsource/baskervville"

export default createTheme({
  statusChipCanceled:{
    height:"32px",
    fontSize:"0.8125rem",
    textAlign:"center",
    paddingLeft:"12px",
    paddingRight:"12px",
    borderRadius:"1.5rem",
    color:"white",
    display:"inline-flex",
    backgroundColor:"red",
    alignItems:"center",
    fontFamily:"Roboto"
 },
 swalIcon:{
  width:"75px",
  height:"75px"
},
  mainDrawer:{
    overflowY: 'unset'
  },
  palette: {
    primary: {
      light: '#ffffff',
      main: '#fafafa',
      dark: '#c7c7c7',
      contrastText: '#000000',
    },
   /* secondary: {
      light: '#6d6d6d',
      main: '#424242',
    // main: '#90594C',
      dark: '#1b1b1b',
      contrastText: '#ffffff',
    },*/
    secondary: {
      light: '#c28678',
      main: '#90594C',
      dark: '#602f24',
      contrastText: '#ffffff',
    },
    delete:{
      light: '#FF0000',
      main: '#FF0000',
      dark: '#FF0000',
      contrastText: '#ffffff',
    }
  },
  typography:{
    fontFamily: "'Roboto Slab', serif",
    h1:{
      fontFamily:"'Baskervville'"
    },
    h2:{
      fontFamily:"'Baskervville'",
      color:"#90594c",
      fontWeight:"100",
      fontSize:"3rem"
    },h3:{
      fontFamily:"'Baskervville'"
    },h4:{
      fontFamily:"'Baskervville'"
    },h5:{
      fontFamily:"'Baskervville'"
    },
    h6:{
      fontFamily:"'Baskervville'",
      lineHeight:"1.5",
      fontSize:"18px"
    },
    body2:{
      fontSize:"13px",
      lineHeight:"1.5"
    },
    body1:{
      color:"#7A7A7A",
      fontSize:"13px",
      lineHeight:"1.5",
      fontWeight:"300"
    },
    subtitle2:{
      fontWeight:"700"
    },
    subtitle1:{
      lineHeight:"1.5",
      fontSize:"0.6rem"
    }
  },
  acceptButton: {
    marginBottom: "1rem",
    backgroundColor: "#4fd053",
    color:"white",
    '&:hover': {
        backgroundColor: '#47ba4a',
    },
},
overrides: {

  MuiCssBaseline: {
    '@global': {
      ".swal-button": {
        backgroundColor: "#90594C",
      },
      ".swal-title": {
        fontFamily: "'Baskervville'",
        fontWeight:"normal"
      },
      ".swalert": {
        zIndex: "2000 !Important"
      },
      ".swal2-confirm":{

        minWidth: "150px !important",
        fontSize: "20px !important",
        fontFamily: 'Baskervville',
      },
      ".swal2-cancel":{

        minWidth: "150px !important",
        fontSize: "20px !important",
        fontFamily: 'Baskervville',
      },
      ".swal2-icon":{
       // borderColor:"white !Important",
      },
      ".swal2-title":{
        paddingTop:"20px !important",
        fontFamily: "'Baskervville'",
        fontWeight:"normal"
      },

    },
  },
},
});
