import useDetail from "../../../hooks/useDetail";
import {MinusCircle as MinusIcon, PlusCircle as PlusIcon, Trash2 as DeleteIcon} from "react-feather";
import React from "react";
import {Box, IconButton, ListItemText} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({ 
    quantityPicker: {
        width: "100%",
        display: "inline-flex",
        flexDirection: "row",
        marginTop: "1auto",
        boxShadow: "0 2px 5px 0 hsl(0deg 0% 51% / 50%)",
        padding: "5px",
        borderRadius: "6px"
    },
    quantitySelectorLabel:{
        flexGrow: 4,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center"
},

}));


const AddToCartButton = () => {
    const { detailSelected, onMinusClicked, onPlusClicked } = useDetail();
    const classes = useStyles();

    return (
        <Box variant="outlined" className={classes.quantityPicker}>
            <IconButton size="small" onClick={() => onMinusClicked(detailSelected)}
                        style={ detailSelected.quantity === 1 ? {
                                color: "#f44336"
                            } : {}
                        }>{detailSelected.quantity > 1 ? <MinusIcon/> : <DeleteIcon/>}</IconButton>

            {
                <ListItemText className={classes.quantitySelectorLabel} primary={`${detailSelected.getPrice().toFixed(2)}€`}
                              secondary={`${detailSelected.quantity} ${detailSelected.quantity === 1 ? "unidad" : "unidades"} en el carrito`}/>
            }
            <IconButton size="small" onClick={ () => onPlusClicked(detailSelected) }>
                <PlusIcon/>
            </IconButton>
        </Box>
    );
}

export default AddToCartButton;
