import {
    ADD_PRODUCT,
    ADD_PRODUCT_TO_CART,
    FETCH_FEATURED_PRODUCTS_SUCCESS,
    FETCH_PRODUCTS,
    FETCH_PRODUCTS_ERROR,
    FETCH_PRODUCTS_SUCCESS,
    GET_PRODUCT_TO_EDIT,
    GET_PRODUCT_TO_REMOVE,
    PRODUCT_ADDED_ERROR,
    PRODUCT_ADDED_SUCCESS,
    PRODUCT_EDITED_SUCCESS, PRODUCT_REMOVED_ERROR, PRODUCT_REMOVED_SUCCESS,
} from '../types/productTypes';

const initialState = {
    products: [],
    error: null,
    featuredProducts: [],
    loading: false,
    productToEdit: null,
    productToRemove: null,
    productsInCart: []
}

const state = (state = initialState, action) => {
    switch (action.type) {

        case ADD_PRODUCT:
            return {
                ...state,
                loading: true,
                error: null
            }

        case ADD_PRODUCT_TO_CART:
            return {
                ...state,
                productsInCart: [...state.productsInCart, action.payload]
            }

        case FETCH_FEATURED_PRODUCTS_SUCCESS:
            return {
                ...state,
                featuredProducts: action.payload,
                loading: false
            }

        case FETCH_PRODUCTS:
            return {
                ...state,
                loading: true,
                products: []
            }

        case FETCH_PRODUCTS_ERROR:
        case PRODUCT_ADDED_ERROR:
        case PRODUCT_REMOVED_ERROR:
            return {
                ...state,
                error: true,
                loading: false
            }

        case FETCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                products: action.payload
            }

        case GET_PRODUCT_TO_EDIT:
            return {
                ...state,
                productToEdit: action.payload
            }

        case GET_PRODUCT_TO_REMOVE:
            return {
                ...state,
                productToRemove: action.payload
            }

        case PRODUCT_ADDED_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                products: [...state.products, action.payload]
            }

        case PRODUCT_EDITED_SUCCESS:
            return {
                ...state,
                products: state.products.map(product => product.id === state.productToEdit.id ? action.payload : product),
                productToEdit: null,
                error: null
            }

        case PRODUCT_REMOVED_SUCCESS:
            return {
                ...state,
                products: state.products.filter(product => product.id !== state.productToRemove),
                productToRemove: null
            }

        default:
            return state;
    }
};

export default state;