import {CHANGE_VOUCHER_SELECTED} from "../../types/voucherTypes";

export function changeVoucherSelectedAction(voucher) {
    return (dispatch) => {
        dispatch(changeVoucher(voucher));
    }
}

const changeVoucher = voucher => ({
   type: CHANGE_VOUCHER_SELECTED,
   payload: voucher
});
