import { COMPLETE, ERROR,IDLE,LOADING } from "../types/asyncStatusTypes";
import {
    CHANGE_AUTH_FORM_VISIBILITY,
    CHANGE_REGISTER_DRAWER_VISIBILITY,
    IS_LOGGING,
    LOGIN_ERROR, 
    LOGIN_SUCCESS, 
    LOGOUT,
    RECOVER_PASSWORD_COMPLETE,
    RECOVER_PASSWORD_ERROR,
    RECOVER_PASSWORD_RESET,
    RECOVER_PASSWORD_START
} from "../types/authTypes";

const initialState = {
    authFormOpen: false,
    registerDrawerOpen: false,
    isLogged: localStorage.getItem('clientId') !== null,
   // isLogged: false,
    isLogging:false,
    recoverPasswordStatus:IDLE,
    errorMessage: undefined
}

const state = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_AUTH_FORM_VISIBILITY:
            return {
                ...state,
                authFormOpen: action.payload
            }
        case CHANGE_REGISTER_DRAWER_VISIBILITY:
            return {
                ...state,
                registerDrawerOpen: action.payload
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLogged: true,
                errorMessage: undefined,
                isLogging: false,
            }
        case IS_LOGGING:
            return {
                ...state,
                isLogged: false,
                errorMessage: undefined,
                isLogging: true
            }
        case LOGIN_ERROR:
            return {
                ...state,
                errorMessage: action.payload,
                isLogged: false,
                isLogging: false,
            }
        case LOGOUT:
            return {
                ...state,
                isLogged: false
            }
        case RECOVER_PASSWORD_START:{
            return {
                ...state,
                recoverPasswordStatus:LOADING,
                errorMessage:undefined
            }
        }
        case RECOVER_PASSWORD_COMPLETE:{
            return {
                ...state,
                recoverPasswordStatus:COMPLETE,
                errorMessage:undefined
            }
        }
        case RECOVER_PASSWORD_ERROR:{
            return {
                ...state,
                recoverPasswordStatus:ERROR,
                errorMessage: action.payload
            }
        }
        case RECOVER_PASSWORD_RESET:{
            return {
                ...state,
                recoverPasswordStatus:IDLE,
                errorMessage: undefined
            }
        }

        default:
            return state;
    }
}

export default state;
