import {IconButton, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Check as SelectedIcon, LocalShipping as ShippingIcon} from "@material-ui/icons"
import useCart from "../../../hooks/useCart";
import useShippingMethod from "../../../hooks/useShippingMethod";


const useStyles = makeStyles(() => ({
    container: {
        margin: 0,
        width: 400
    },
    shippingItem: {
        paddingLeft: 15,
        paddingRight: 0,
        alignItems: "center",
        justifyItems: "center",
        background: "white",
        borderBottom: "1px solid #f5f5f5"
    },
}));

const SelectShippingMethodCell = ({shippingMethod, shippingMethodSelected}) => {
    const classes = useStyles();

    const {detailsInCart} = useCart();
    const {onClickShippingMethod, shippingMethodValueDescription} = useShippingMethod();


    if (!shippingMethod) return null;

    return (
        <ListItem onClick={() => onClickShippingMethod(shippingMethod)} className={classes.shippingItem}>
            <ListItemIcon>
                <ShippingIcon/>
            </ListItemIcon>
            <ListItemText
                primary={shippingMethod.name}
                secondary={
                    shippingMethodValueDescription(detailsInCart, shippingMethod)
                }
            />
            {(shippingMethodSelected && shippingMethodSelected.id === shippingMethod.id) ?
                <ListItemIcon>
                    <IconButton edge="end" aria-label="comments">
                        <SelectedIcon/>
                    </IconButton>
                </ListItemIcon> : ""
            }
        </ListItem>
    )
}

const SelectShippingMethodList = (props) => {
    const classes = useStyles();

    const {shippingMethods, shippingMethodSelected} = props;

    if (!shippingMethods) return (<p>Ningún método de envío disponible para tu dirección</p>)

    return (
        <div className={classes.container}>
            <List>
                {
                    shippingMethods.map(shippingMethod => <SelectShippingMethodCell
                        key={shippingMethod.id}
                        shippingMethod={shippingMethod}
                        shippingMethodSelected={shippingMethodSelected}/>)
                }
            </List>

        </div>

    )
}


export default SelectShippingMethodList;
