import {useDispatch, useSelector} from "react-redux";
import { changeDetailSelectedAction } from "../redux/actions/details/changeDetailSelectedAction";
import {changeOptionSelectedAction} from "../redux/actions/details/changeOptionSelectedAction";
import {changeProductOptionsVisibilityAction} from "../redux/actions/details/changeProductOptionsVisibilityAction";
import {changeCartVisibilityAction} from "../redux/actions/cart/changeCartVisibilityAction";
import {changeVoucherOptionsVisibilityAction} from "../redux/actions/details/changeVoucherOptionsVisibilityAction";
import {addDetailToCartAction} from "../redux/actions/cart/addDetailToCartAction";
import {decDetailInCartAction} from "../redux/actions/cart/decDetailInCartAction";
import {fetchDetailsByCategoryAction} from "../redux/actions/details/fetchDetailsByCategoryAction";
import {fetchFeaturedDetailsAction} from "../redux/actions/details/fetchFeaturedDetailsAction";
import Detail from "../models/Detail"
import { changeInvalidOptionVisibilityAction } from "../redux/actions/details/changeInvalidOptionVisibilityAction";


const useDetail = () => {
    const dispatch = useDispatch();

    const details = useSelector(state => state["detailReducer"].details);
    const detailsFeatured = useSelector(state => state["detailReducer"].detailsFeatured);
    const detailSelected = useSelector(state => state["detailReducer"].detailSelected);
    const invalidOptionOpened = useSelector(state => state["detailReducer"].invalidOptionOpened)
    const productOptionsOpened = useSelector(state => state["detailReducer"].productOptionsOpened);
    const optionSelected = useSelector(state => state["detailReducer"].optionSelected);
    const detailsLoading = useSelector(state => state['detailReducer'].loading);


    const fetchFeaturedDetails = () => {
        dispatch(fetchFeaturedDetailsAction());
    }
    const getDetailsByCategoryId = (categoryId) => {
        dispatch(fetchDetailsByCategoryAction(categoryId));
    }

    const onCloseDetail = () => {
        dispatch(changeDetailSelectedAction(null));
    }
    const onCloseProductOptions = () => {
        dispatch(changeProductOptionsVisibilityAction(false));
    }
    const onProductOptionsSuccess = () => {
        dispatch(changeProductOptionsVisibilityAction(false));
        dispatch(changeDetailSelectedAction(null))
        dispatch(changeCartVisibilityAction(true));
    }

    const onClickAddToCart = () => {
        if (detailSelected.price.product.voucherInfo) {
            dispatch(changeVoucherOptionsVisibilityAction(true));
        } else if (detailSelected.hasOptions()) {
            dispatch(changeProductOptionsVisibilityAction(true));
        } else {
            dispatch(addDetailToCartAction(detailSelected));
            onCloseDetail();
            dispatch(changeCartVisibilityAction(true));
        }
    }

    const onClickDetail = (detail) => {
        dispatch(changeDetailSelectedAction(Detail.copy(detail)))
    }

    const onClickOption = (option) => {
        dispatch(changeOptionSelectedAction(option))
    }

    const onChangeInvalidOptionVisibility = (visible) => {
        dispatch(changeInvalidOptionVisibilityAction(visible))
    }




    const onPlusClicked = (detail) => {
        let newDetail = Detail.copy(detail);
        newDetail.addQuantity(1)
        dispatch(addDetailToCartAction(detail));//esta bien que sea detail y no newDetail
        if (detailSelected && detail.price.id === detailSelected.price.id) {
            dispatch(changeDetailSelectedAction(newDetail));
        }
    }
    const onMinusClicked = (detail) => {
         let newDetail = Detail.copy(detail);
        dispatch(decDetailInCartAction(detail));//esta bien que sea detail y no newDetail
        newDetail.quantity = newDetail.quantity = newDetail.quantity == 0 ? 0 : newDetail.quantity - 1;
        if (detailSelected && detail.price.id === detailSelected.price.id) {
            dispatch(changeDetailSelectedAction(newDetail));
        }
    }
    const onDeleteClicked = () => {
        detailSelected.quantity = 1
        dispatch(decDetailInCartAction(detailSelected));
    }

    return {
        details,
        detailsFeatured,
        detailsLoading,
        detailSelected,
        optionSelected,
        productOptionsOpened,
        invalidOptionOpened,

        fetchFeaturedDetails,
        getDetailsByCategoryId,

        onDeleteClicked,
        onClickAddToCart,
        onClickDetail,
        onClickOption,

        onCloseDetail,
        onCloseProductOptions,
        onMinusClicked,
        onPlusClicked,
        onProductOptionsSuccess,
        onChangeInvalidOptionVisibility
    }
}

export default useDetail;
