import React, {useState, useEffect} from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {useDispatch} from "react-redux";
import 'react-gallery-carousel/dist/index.css'
import {makeStyles} from '@material-ui/core/styles';
import {Close as CloseIcon} from "@material-ui/icons"
import VoucherOptions from "../../components/voucher/VoucherOptions"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import { Box, Button, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import SelectOption from "./SelectOption"
import useDetail from "../../../hooks/useDetail";

const useStyles = makeStyles(() => ({
    dialogActions: {
        display: "flex",
        padding: "12px",
        boxShadow: "-1px -1px 12px 0 #d5d5d5"
    },
    dialogBody: {
        overflowY: "auto",
        maxHeight: "calc(100vh - 112px)"
    },
    dialogTitle: {
        paddingBottom: 0,
        paddingTop: 0,
        paddingRight: 4,
        boxShadow: "-1px -1px 12px 0 #d5d5d5"
    }
}));

const DetailOptionDialog = () => {
    const dispatch = useDispatch();

    const { detailSelected, optionSelected, onClickOption } = useDetail();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [state, setState] = useState({
        option: null,
    });

    useEffect(() => {

        if (optionSelected) {
            setState({
                ...state,
                option: (JSON.parse(JSON.stringify(optionSelected)))
            });
        }
        // eslint-disable-next-line
    }, [optionSelected]);

    const classes = useStyles();

    const handleAccept = () => {
        var foundIndex = detailSelected.price.product.options.findIndex(x => x.name == state.option.name);
        detailSelected.price.product.options[foundIndex] = state.option;
        onClickOption(null)
    };

    const handleClose = () => {
        setState({
             ...state,
            option: null
        });
        onClickOption(null)
    }

    if (!detailSelected || !detailSelected.price.product.options) {
        return null;
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={optionSelected}
            maxWidth={"md"}
            fullScreen={fullScreen}
            fullWidth>

            <DialogTitle className={classes.dialogTitle} >
                <Box display="flex" alignItems="center" style={{padding: 0}}>
                    <Box flexGrow={1} style={{padding: 0}}>Opciones y complementos</Box>
                    <Box>
                        <IconButton onClick={handleClose}><CloseIcon/></IconButton>
                    </Box>
                </Box>
            </DialogTitle>

            <div className={classes.dialogBody}>
                {state.option ?
                    <DialogContent>
                        <SelectOption option={state.option} fullScreen={fullScreen}/>
                    </DialogContent>
                    : ""
                }
            </div>

            <DialogActions className={classes.dialogActions}>
                <Button variant="outlined" onClick={handleClose}>Cancelar</Button>
                <Button variant="outlined" onClick={handleAccept} color="secondary" type="submit">Aceptar</Button>
            </DialogActions>

        </Dialog>

    );
}


export default DetailOptionDialog;