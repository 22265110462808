import firebaseAdmin from "../../../config/firebaseConfig";
import {IS_LOGGING,LOGIN_ERROR, LOGIN_SUCCESS} from "../../types/authTypes";
import {LOAD_CLIENT_SUCCESS} from "../../types/clientTypes";
import {getClientByEmailAsync} from "../../../services/clientServices";

export function loginUserAction(credentials) {
    return async (dispatch) => {
        dispatch(changeLoginStatus())
        const firebaseUserId = await firebaseAdmin.signInWithEmailAndPassword(credentials.email, credentials.password)
            .then(result => result.user.uid)
            .catch(error => {
                localStorage.removeItem('clientId');
                dispatch(changeErrorMessage(error.code));
                return undefined;
            });

        if (firebaseUserId) {
            const client = await getClientByEmailAsync(credentials.email);
            if (client) {
               
                // Login OK
                localStorage.setItem('clientId', client.id);
                dispatch(changeAuthStatus());
                dispatch(loadClient(client));
            } else {
                localStorage.removeItem('clientId');
                dispatch(changeErrorMessage("no_user"));
            }
        }
    }
}

const changeAuthStatus = () => ({
    type: LOGIN_SUCCESS
});

const changeLoginStatus = () => ({
    type: IS_LOGGING
});

const changeErrorMessage = (errorMessage) => ({
    type: LOGIN_ERROR,
    payload: errorMessage
});
const loadClient = (client) => ({
    type: LOAD_CLIENT_SUCCESS,
    payload: client
});
