import {CHANGE_SHIPPING_ADDRESS_SELECTED} from "../../types/addressTypes";

export function changeShippingAddressSelectedAction(address) {
    return (dispatch) => {
        dispatch(changeShippingAddressSelected(address));
    }
}

const changeShippingAddressSelected = address => ({
    type: CHANGE_SHIPPING_ADDRESS_SELECTED,
    payload: address
});
