import {ADD_DETAIL_TO_CART, REMOVE_DETAIL_FROM_CART} from "../../types/cartTypes";

export function decDetailInCartAction(detail) {
    detail.quantity = detail.quantity - 1;
    if (detail.quantity === 0)  return (dispatch) => {
        dispatch(removeDetailFromCart(detail))
    }
    return (dispatch) => {
        
        dispatch(addDetailToCart(detail))
    }
}

const removeDetailFromCart = detail => ({
    type:  REMOVE_DETAIL_FROM_CART,
    payload: detail
});
const addDetailToCart = detail => ({
    type:  ADD_DETAIL_TO_CART,
    payload: detail
});