import React, {useState, useEffect} from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {useDispatch} from "react-redux";
import 'react-gallery-carousel/dist/index.css'
import {makeStyles} from '@material-ui/core/styles';
import {Close as CloseIcon} from "@material-ui/icons"
import {addDetailToCartAction} from "../../redux/actions/cart/addDetailToCartAction";
import VoucherOptions from "../components/voucher/VoucherOptions"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { PlusCircle as PlusIcon, MinusCircle as MinusIcon } from "react-feather";
import {Box, Button, Dialog, DialogTitle, IconButton, TextField,Typography} from "@material-ui/core";
import useDetail from "../../hooks/useDetail";

const useStyles = makeStyles(() => ({
    dialogActions: {
        display: "flex",
        padding: "12px",
        boxShadow: "-1px -1px 12px 0 #d5d5d5"
    },
    dialogBody: {
        overflowY: "auto",
        maxHeight: "calc(100vh - 112px)"
    },
    dialogTitle: {
        paddingBottom: 0,
        paddingTop: 0,
        paddingRight: 4,
        boxShadow: "-1px -1px 12px 0 #d5d5d5"
    },
     personPicker: {
        width: "100%",
        display: "inline-flex",
        flexDirection: "row",
        marginBottom: "8px",
        borderRadius: "6px",
        justifyContent: "center",
        alignItems: "center",
        verticalAlign: "middle"
    },
     detailUnits: {
        display: "inline-flex",
        webkitBoxOrient: "vertical",
        webKitBoxDirection: "normal",
        webkitFlexDirection: "column",
        flexDirection: "column",
        webkitBoxAlign: "top",
        webkitAlignItems: "top",
        alignItems: "top",
         textAlign: "center",
        paddingLeft: "5px"
    },
    personsTextField: {
        marginRight: "0rem"
    },
     optionTotal: {
        fontSize: "15px",
        paddingLeft: "20px",
        paddingRight: "0px",
        minWidth: "90px",
        fontWeight: "bold",
        textAlign: "center"
    },
}));

const ProductOptionsDialog = () => {
    const dispatch = useDispatch();

    const { detailSelected, productOptionsOpened, onProductOptionsSuccess, onCloseProductOptions } = useDetail();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [state, setState] = useState({
        options: null,
        emailError: null,
        quantity: 1,
    });

    useEffect(() => {
        if (!state.quantity) {
            setState({
                ...state,
                quantity: (detailSelected ? detailSelected.quantity : 0)
            });
        }

        if (detailSelected && detailSelected.price.product.options) {
            setState({
                ...state,
                options: (JSON.parse(JSON.stringify(detailSelected.price.product.options)))
            });
        }
        // eslint-disable-next-line
    }, [productOptionsOpened]);

    const classes = useStyles();

    const handleAccept = () => {
        detailSelected.price.product.options = state.options;
        detailSelected.quantity = state.quantity;
        dispatch(addDetailToCartAction(detailSelected));
        onProductOptionsSuccess();
         setState({
            ...state,
            quantity: null,
        });
    };

    const handleClose = () => {
        setState({
            ...state,
            quantity: null,
            optionsSelected: detailSelected.price.product.options
        });
        onCloseProductOptions();
    }

    if (!detailSelected || !detailSelected.price.product.options) {
        return null;
    }


    const SelectDetailQuantityButton = () => {
    
    const voucherUnits = () => {
        if (detailSelected && detailSelected.price.product.name.toLowerCase().includes("caja")) {
            return "cajas"
        }
        if (detailSelected && detailSelected.price.product.name.toLowerCase().includes("alojamiento")) {
            return "noches"
        }
        return "unidades"
    }

    const plusClicked = () => {
        setState({ ...state, quantity: (state.quantity + 1) })
      
    }
    const minusClicked = () => {
        setState({ ...state, quantity: state.quantity - 1 })
    }

    return (
        <React.Fragment>
            <Box className={classes.personPicker}>
                {<React.Fragment>
                    <TextField
                        label={`Número de ${voucherUnits()}`}
                        type="number"
                        fullWidth
                        className={classes.personsTextField}
                        color="secondary"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true
                        }}
                       // {...props.getFieldProps('quantity')}
                        placeholder="Número de adultos"
                        value={state.quantity} />
                </React.Fragment>
                }
                <div className={classes.detailUnits}>
                    <IconButton size="small" onClick={plusClicked}
                        ><PlusIcon /></IconButton>
                    <IconButton size="small" onClick={minusClicked}
                        ><MinusIcon /></IconButton>
                </div>
                <Typography
                    className={classes.optionTotal}>{state.quantity ? ((detailSelected.getUnitaryPrice() * state.quantity).toFixed(2) + " €") : "0.00 €"}</Typography>
            </Box>
        </React.Fragment>
    );

};


    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={productOptionsOpened}
            maxWidth={"md"}
            fullScreen={fullScreen}
            fullWidth>

            <DialogTitle className={classes.dialogTitle} >

                <Box display="flex" alignItems="center" style={{padding: 0}}>

                    <Box flexGrow={1} style={{padding: 0}}>Opciones y complementos</Box>
                    <Box>
                        <IconButton onClick={handleClose}><CloseIcon/></IconButton>
                    </Box>
                </Box>
            </DialogTitle>

            <div className={classes.dialogBody}>
               
                {state.options ?
                    <DialogContent>
                         <SelectDetailQuantityButton/>
                        <VoucherOptions options={state.options} maxUnits={state.quantity} fullScreen={fullScreen}/>
                    </DialogContent>
                    : ""
                }
            </div>

            <DialogActions className={classes.dialogActions}>
                <Button variant="outlined" onClick={handleClose}>Cancelar</Button>
                <Button variant="outlined" onClick={handleAccept} color="secondary" type="submit">Aceptar</Button>
            </DialogActions>

        </Dialog>

    );
}


export default ProductOptionsDialog;
