import {AVISO_LEGAL_SHOWING} from "../../types/infoTypes.js";

export function changeAvisoLegalVisibilityAction(visibility) {
    return (dispatch) => {
        dispatch(changeVisibility(visibility));
    }
}

const changeVisibility = visibility => ({
    type: AVISO_LEGAL_SHOWING,
    payload: visibility
});