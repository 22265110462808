import {
    AVISO_LEGAL_SHOWING,
    SHOP_CONDITIONS_SHOWING,
    PRIVACY_POLICY_SHOWING,
    NEWSLETTER_SHOWING,
    COOKIE_POLICY_SHOWING,
    RSS_POLICY_SHOWING
} from "../types/infoTypes";

const initialState = {
    avisoLegalOpened: false,
    shopConditionsOpened:false,
    privacyPolicyOpened:false,
    cookiePolicyOpened:false,
    rssPolicyOpened:false,
    newsletterOpened:false
   
};


const state = (state = initialState, action) => {

    switch (action.type) {

        case AVISO_LEGAL_SHOWING:
            return {
                ...state,
                avisoLegalOpened: action.payload,
            };
            case SHOP_CONDITIONS_SHOWING:
                return {
                    ...state,
                    shopConditionsOpened: action.payload,
                };
                case PRIVACY_POLICY_SHOWING:
                    return {
                        ...state,
                        privacyPolicyOpened: action.payload,
                    };
                    case COOKIE_POLICY_SHOWING:
                        return {
                            ...state,
                            cookiePolicyOpened: action.payload,
                        };
                        case RSS_POLICY_SHOWING:
                            return {
                                ...state,
                                rssPolicyOpened: action.payload,
                            };
                    case NEWSLETTER_SHOWING:
                    return {
                        ...state,
                        newsletterOpened: action.payload,
                    };


        default:
            return state;
    }
}

export default state;