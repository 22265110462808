import {CHANGE_CREDIT_CARD_SELECTOR_VISIBILITY} from "../../types/creditCardTypes";

export function changeCreditCardSelectorVisibilityAction(visibility) {
    return (dispatch) => {
        dispatch(changeVisibility(visibility));
    }
}

const changeVisibility = visibility => ({
    type: CHANGE_CREDIT_CARD_SELECTOR_VISIBILITY,
    payload: visibility
})
