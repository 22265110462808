import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {Formik} from 'formik';
import * as Yup from 'yup';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import useLegalPolicy from "../../hooks/useLegalPolicy";

const useStyles = makeStyles(() => ({
    bigTitle: {
        padding: "24px",
        fontSize: "40px"
    },
    dialogActions: {
        display: "flex",
        padding: "12px",
        boxShadow: "-1px -1px 12px 0 #d5d5d5"
    },
    dialogBody: {
        overflowY: "auto",
        maxHeight: "calc(100vh - 112px)",
        paddingBottom: "20px"
    },
    emailTextField: {
        minWidth: "500px",
        paddingBottom: "30px"
    },
    errorMessage: {
        top: "20px",
        borderColor: "red",
        borderStyle: "dashed",
        borderWidth: "thin",
        padding: "10px",
        borderRadius: "8px",
        color: "red",
    }
}));

const newsletterSchema = Yup.object({
    email: Yup.string().email("Debes de introducir un email válido").trim().lowercase().required('Debes introducir un email válido'),
    name: Yup.string().trim().required('Debes introducir un nombre'),
    surname: Yup.string().trim().required('Debes introducir, al menos, un apellido'),
})
const mailchimpURL = "https://distributioapp.us9.list-manage.com/subscribe/post?u=ff46a0d185de23795375559ec&id=3c76104ffe";

export default function NewsletterDialog() {
    const classes = useStyles();
    const { newsletterOpened, onCloseNewsletter} = useLegalPolicy();

    return (
        <Dialog
            open={newsletterOpened}
            onClose={onCloseNewsletter}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}
        >
            <Typography className={classes.bigTitle} variant="h2">Boletín de noticias, novedades y eventos</Typography>
            <MailchimpSubscribe url={mailchimpURL} render={({subscribe, status, message}) => (
                <CustomForm
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                    onHandleClose={onCloseNewsletter}
                />
            )}>

            </MailchimpSubscribe>
        </Dialog>
    );
}

const CustomForm = ({status, message, onValidated, onHandleClose}) => {
    const classes = useStyles();
    const ReactSwal = withReactContent(Swal);

    const messageTranslated = () => {
        if (message.includes("already")) {
            return "Ya estás apuntado a esta lista"
        }
        return "Se ha producido un error"
    }

    React.useEffect(() => {
        if (status === "success") {
            ReactSwal.fire({
                icon: "success",
                text: "Te mantendremos informado",
                customClass: {
                    container: "swalert"
                },
                confirmButtonColor: "#90594c",
                timer: 3000,
                timerProgressBar: true,
                title: "Gracias por subscribirte",
            });
            onHandleClose()
        }
        // eslint-disable-next-line
    }, [status]);

    return (<Formik
        initialValues={{
            email: "",
            name: ""
        }
        }
        validationSchema={
            newsletterSchema
        }
        onSubmit={(values) => {
            //esto es necesario para que las transformaciones (por ejemplo, el trim) se apliquen
            const form = newsletterSchema.cast(values)
            onValidated({
                EMAIL: form.email,
                MERGE1: form.name,
                MEGE2: form.surname
            });
        }}
    >
        {props => (
            <form
                onSubmit={props.handleSubmit}
            >
                <div className={classes.dialogBody}>
                    <DialogContent>
                        <TextField id="email" className={classes.emailTextField} color="secondary" label="Email"
                                   variant="outlined"
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                                   placeholder="Escriba su email"
                                   {...props.getFieldProps('email')}
                                   helperText={props.touched.email && props.errors.email}
                                   error={props.touched.email && props.errors.email}
                        />

                        <TextField id="name" className={classes.emailTextField} color="secondary" label="Nombre"
                                   variant="outlined"
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                                   placeholder="Escriba su nombre"
                                   {...props.getFieldProps('name')}
                                   helperText={props.touched.name && props.errors.name}
                                   error={props.touched.name && props.errors.name}
                        />

                        <TextField id="surname" className={classes.emailTextField} color="secondary" label="Apellidos"
                                   variant="outlined"
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                                   placeholder="Escriba su/s apellido/s"
                                   {...props.getFieldProps('surname')}
                                   helperText={props.touched.surname && props.errors.surname}
                                   error={props.touched.surname && props.errors.surname}
                        />

                    </DialogContent>
                </div>
                <DialogActions dividers className={classes.dialogActions}>
                    {status === "error"
                        ?
                        <Typography variant="body1" className={classes.errorMessage}>{messageTranslated()} </Typography>
                        : ""
                    }
                    <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        disabled={status === "sending"}>
                        {status === "sending"
                            ? "ENVIANDO..."
                            : "SUBSCRÍBETE"
                        }
                    </Button>
                </DialogActions>
            </form>
        )}
    </Formik>)
}
