import {CHANGE_SHIPPING_METHOD_SELECTOR_VISIBILITY} from "../../types/shippingMethodTypes";

export function changeShippingMethodSelectorVisibilityAction(visibility) {
    return (dispatch) => {
        dispatch(changeVisibility(visibility));
    }
}

const changeVisibility = visibility => ({
    type: CHANGE_SHIPPING_METHOD_SELECTOR_VISIBILITY,
    payload: visibility
})
