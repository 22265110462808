import {FETCH_SHIPPING_METHODS_SUCCESS, INIT_FETCH_SHIPPING_METHOD} from "../../types/shippingMethodTypes";
import {getShippingAreaByProvinceIdAsync} from "../../../services/shippingAreaServices";

export function fetchShippingMethodsAction(provinceId) {
    return async (dispatch) => {
    //dispatch(initFetch())//COMENTO ESTA LÍNEA PORQUE PROVOCABA UN PEQUEÑO BUCLE EN SHOPPINGINFO
        const shippingArea = await getShippingAreaByProvinceIdAsync(provinceId);
        if (shippingArea instanceof Error) {
            dispatch(loadShippingMethods([]));
        } else {
            dispatch(loadShippingMethods(shippingArea.shippingMethods));
        }
    }
}

const initFetch = () => ({
    type: INIT_FETCH_SHIPPING_METHOD
});

const loadShippingMethods = shippingMethods => ({
    type: FETCH_SHIPPING_METHODS_SUCCESS,
    payload: shippingMethods
});
