import {IconButton, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import {
    Add as AddIcon,
    EditOutlined as EditIcon,
    LocationOn as LocationIcon,
    Check as SelectedIcon
} from "@material-ui/icons";

import useAddress from "../../../hooks/useAddress";

const useStyles = makeStyles(() => ({
    container: {
        margin: 0,
        minWidth: "calc(min(100vw, 375px))",
        maxWidth: "calc(min(100vw, 375px))",
    },
    addressItem: {
        paddingLeft: 15,
        paddingRight: 15,
        alignItems: "center",
        justifyItems: "center",
        background: "white",
        borderBottom: "1px solid #f5f5f5"

    },
}));

const SelectShippingAddressCell = ({address, addressSelected, onAddressSelected, forEditing}) => {
    const classes = useStyles();

    if (!address) return null;

    const onClickAddress = () => {
        onAddressSelected(forEditing, address)
    }

    return (
        <ListItem alignItems="flex-start" className={classes.addressItem} onClick={onClickAddress}>
            <ListItemIcon>
                <LocationIcon/>
            </ListItemIcon>
            <ListItemText
                primary={address.name}
                secondary={
                    address.street + " " + address.city + " " + address.postalCode + " " + address.province.name
                }
            />

            {
                forEditing ?
                    <ListItemIcon>
                        <IconButton edge="end" aria-label="comments">
                            <EditIcon/>
                        </IconButton>
                    </ListItemIcon>
                    :
                    ((addressSelected && addressSelected.id === address.id) ?
                        <ListItemIcon>
                            <IconButton edge="end" aria-label="comments">
                                <SelectedIcon/>
                            </IconButton>
                        </ListItemIcon> : "")
            }
        </ListItem>
    )
}

const SelectShippingAddressList = ({onAddressSelected, forEditing}) => {
    const classes = useStyles();

    const {onClickCreateNewAddress} = useAddress();
    let {addresses, addressSelected} = useAddress();


    if (!addresses) {
        addresses = [];
    }

    return (
        <div className={classes.container}>
            <List>
                {
                    addresses.map(address => <SelectShippingAddressCell
                        key={address.id}
                        address={address}
                        addressSelected={addressSelected}
                        onAddressSelected={onAddressSelected}
                        forEditing={forEditing}/>)
                }
                <ListItem key={"listItem"} onClick={() => onClickCreateNewAddress(forEditing)}>
                    <ListItemIcon>
                        <AddIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={`AÑADIR UNA NUEVA DIRECCIÓN`}
                    />
                </ListItem>
            </List>

        </div>

    )

}

export default SelectShippingAddressList;
