import React, {useEffect} from 'react';
import SelectPaymentMethodList from "./SelectPaymentMethodList";
import {CircularProgress, Drawer} from "@material-ui/core";
import DrawerHeader from '../cart/DrawerHeader'
import usePaymentMethod from "../../../hooks/usePaymentMethod";

const SelectPaymentMethodDrawer = () => {
    const { loadingPaymentMethods, paymentMethods, paymentMethodSelected, paymentMethodSelectorOpened, initPaymentMethods, onCloseSelectPaymentMethod } = usePaymentMethod();

    useEffect(() => {
        if (paymentMethodSelectorOpened) {
            initPaymentMethods();
        }
        // eslint-disable-next-line
    }, [paymentMethodSelectorOpened]);

    return (
          <Drawer anchor={"right"} open={paymentMethodSelectorOpened} onClose={onCloseSelectPaymentMethod} >
          <DrawerHeader title="Elige un método de pago" onHandleClose={onCloseSelectPaymentMethod}/>
             {loadingPaymentMethods ?  <CircularProgress/> :
             <SelectPaymentMethodList paymentMethods={paymentMethods} paymentMethodSelected={paymentMethodSelected} />}
        </Drawer>
    );
}



export default SelectPaymentMethodDrawer;
