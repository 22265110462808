import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import {Button, Typography} from "@material-ui/core";
import DetailsInCartList from "../detail/DetailsInCartList";
import {Box} from '@material-ui/core/';
import DrawerHeader from './DrawerHeader'
import useCart from "../../../hooks/useCart";
import drawerStyles from "../../ui/DrawerStyles";

const CartDrawer = () => {
    const classes = drawerStyles();

    const { cartOpen, detailsInCart, onClickPaymentButton, onCloseCart } = useCart();

    if (!detailsInCart || detailsInCart.length === 0) {
        return (
            <Drawer anchor={"right"} open={cartOpen} onClose={onCloseCart} width={1/3}>
                <DrawerHeader title="Carrito" onHandleClose={onCloseCart}/>
                <Typography align="center" variant="h6" className={classes.emptyCart}>
                    No tienes ningún producto en tu carrito en estos momentos
                </Typography>
            </Drawer>

        );
    }

    return (
        <React.Fragment>
            <Drawer anchor={"right"} open={cartOpen} onClose={onCloseCart}>
            <DrawerHeader title="Carrito" onHandleClose={onCloseCart}/>
                <DetailsInCartList details={detailsInCart} />
                <Box className={classes.footer}>
                    <Button variant={"contained"} type="submit" color="secondary" fullWidth disableElevation onClick={onClickPaymentButton} >
                    <span className={classes.continueButtonQuantity}>{detailsInCart.length}</span>
                    <span className={classes.continueButtonLabel}>Ir a Pago y Envío</span>
                    <span className={classes.continueButtonImport}>{detailsInCart.reduce((a, b) => a + (b.getPrice() || 0), 0).toFixed(2)}€</span>
                    </Button>
                </Box>
            </Drawer>
        </React.Fragment>
    );
}

export default CartDrawer;
