import {ADDRESS_DELETED_ERROR,ADDRESS_DELETED_SUCCESS, INIT_DELETE_ADDRESS} from "../../types/addressTypes";
import {deleteAddressAsync} from "../../../services/addressServices";

export function deleteAddressAction(addressId) {
    return async (dispatch) => {
        dispatch(initDelete());

        const result = await deleteAddressAsync(addressId);
        if (result instanceof Error) {
            dispatch(addressDeletedError("Error borrando la dirección"));
        } else {
            dispatch(addressDeletedSuccess(addressId));
        }
    }
}

const initDelete = () => ({
    type: INIT_DELETE_ADDRESS
});

const addressDeletedSuccess = address => ({
    type: ADDRESS_DELETED_SUCCESS,
    payload: address
});
const addressDeletedError = address => ({
    type: ADDRESS_DELETED_ERROR,
    payload: address
});
