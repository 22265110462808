import {useDispatch, useSelector} from "react-redux";
import {changeAvisoLegalVisibilityAction} from "../redux/actions/info/changeAvisoLegalVisibilityAction";
import {changeShopConditionsVisibilityAction} from "../redux/actions/info/changeShopConditionsVisibilityAction";
import {changePrivacyPolicyVisibilityAction} from "../redux/actions/info/changePrivacyPolicyVisibilityAction";
import {changeCookiePolicyVisibilityAction} from "../redux/actions/info/changeCookiePolicyVisibilityAction";
import {changeRSSPolicyVisibilityAction} from "../redux/actions/info/changeRSSPolicyVisibilityAction";
import {changeNewsletterVisibilityAction} from "../redux/actions/info/changeNewsletterVisibilityAction";

const useLegalPolicy = () => {
    const dispatch = useDispatch();

    const cookiePolicyOpened = useSelector(state => state["infoReducer"].cookiePolicyOpened);
    const legalAdviseOpened = useSelector(state => state["infoReducer"].avisoLegalOpened);
    const newsletterOpened = useSelector(state => state["infoReducer"].newsletterOpened);
    const privacyPolicyOpened = useSelector(state => state["infoReducer"].privacyPolicyOpened);
    const rssPolicyOpened = useSelector(state => state["infoReducer"].rssPolicyOpened);
    const shopConditionsOpened = useSelector(state => state["infoReducer"].shopConditionsOpened);


    const onCloseCookiePolicy = () => {
        dispatch(changeCookiePolicyVisibilityAction(false))
    }
    const onCloseLegalAdvise = () => {
        dispatch(changeAvisoLegalVisibilityAction(false))
    }
    const onCloseNewsletter = () => {
        dispatch(changeNewsletterVisibilityAction(false))
    }
    const onClosePrivacyPolicy = () => {
        dispatch(changePrivacyPolicyVisibilityAction(false))
    }
    const onCloseRSSPolicy = () => {
        dispatch(changeRSSPolicyVisibilityAction(false))
    }
    const onCloseShopConditions = () => {
        dispatch(changeShopConditionsVisibilityAction(false))
    }

    return {
        cookiePolicyOpened,
        legalAdviseOpened,
        newsletterOpened,
        privacyPolicyOpened,
        rssPolicyOpened,
        shopConditionsOpened,

        onCloseLegalAdvise,
        onCloseCookiePolicy,
        onCloseNewsletter,
        onClosePrivacyPolicy,
        onCloseRSSPolicy,
        onCloseShopConditions
    }

}

export default useLegalPolicy;
