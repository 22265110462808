class Detail {


    constructor(amountModeSelected, clientNote, commercialNote, estimatedAmount, finalAmount, quantity, price) {
        this.amountModeSelected = amountModeSelected;
        this.clientNote = clientNote;
        this.commercialNote = commercialNote;
        this.estimatedAmount = estimatedAmount ? estimatedAmount:1;
        this.finalAmount = finalAmount;
        this.quantity = quantity;
        this.price = price;
    
    }
    static copy(detailObject) {
        return new Detail(
            detailObject.amountModeSelected,
            detailObject.clientNote,
            detailObject.commercialNote,
            detailObject.estimatedAmount ? detailObject.estimatedAmount:1,
            detailObject.finalAmount,
            detailObject.quantity,
            detailObject.price
        )
    }

    static createFromPrice(price) {
        return new Detail(
            'units',
            undefined,
            undefined,
            0,
            undefined,
            0,
            price
        );
    }
    static getPrice(detail) {

            return detail.quantity * Detail.getUnitaryPrice(detail)
    }
    static getFinalPrice(detail) {
        let totalPrice = Detail.getActualAmount(detail)*detail.price.value
        totalPrice += Detail.getOptionsValue(detail,false)
        return totalPrice+Detail.getActualDetailTaxes(detail)
}
static getActualDetailTaxes(detail){
    return Detail.getActualProductTaxes(detail) + Detail.getOptionsTaxes(detail)
}
static getActualProductTaxes(detail){
    return Detail.getActualAmount(detail) * detail.price.value * (detail.price.product.tax.value / 100);
}
static getActualAmount(detail){
    if (detail.finalAmount) return detail.finalAmount
    if (detail.estimatedAMount) return detail.estimatedAMount
    return detail.price.product.amount*detail.quantity
}
    static getUnitaryPrice(detail, withTaxes = true) {
        let price = detail.price.value * detail.price.product.amount
        if (withTaxes) {
            price += Detail.getTaxes(detail);
        }
        return price;
    }
    static getOptionsTaxes(detail){
        let optionsTaxes = 0;
        if (detail.optionsSelected) {
            detail.optionsSelected.forEach(option => {
               optionsTaxes += option.value * option.quantity * (detail.price.product.tax.value / 100)
            });
        }
        return optionsTaxes;
    }
    static getOptionsValue(detail,withTaxes = true){
        let value = 0;
        if (detail.optionsSelected) {
            detail.optionsSelected.forEach(option => {
                value += option.value * option.quantity;
            });
        }
        if (withTaxes){
            value += Detail.getOptionsTaxes(detail)
        }
        return value;
    }


   
    static getProductTaxes(detail) {
        return detail.price.product.amount * detail.price.value * (detail.price.product.tax.value / 100);
    }
    static getDetailTaxes(detail){
        return Detail.getProductTaxes(detail)+Detail.getOptionsTaxes(detail)
    }

   static  getMainPhoto(detail) {
        if (detail.price.product.photoUrls.length > 0) return detail.price.product.photoUrls[0];
        return "";
    }

    getUnitTaxes() {
        let detailTaxes = this.price.product.amount * this.price.value * (this.price.product.tax.value / 100);


        return detailTaxes;
    }
    getOptionsPrice(withTaxes = true){
        let optionsPrice = 0;
      
        if (this.price.product.options) {
            this.price.product.options.forEach(option => {
                if (option.quantity){
                    optionsPrice += option.value * option.quantity;
                }
            });
        }
        if (withTaxes) {
            optionsPrice += this.getOptionsTaxes();
        }

        return optionsPrice
    }
    getOptionsTaxes(){
        let optionTaxes = 0;
        if (this.price.product.options) {
            this.price.product.options.forEach(option => {
                if (option.quantity){
                optionTaxes += option.value * option.quantity * (this.price.product.tax.value / 100)
                }
            });
        }
        return optionTaxes;
    }

    getPrice() {
        let totalPrice = (this.getActualAmount() * this.price.value)
        totalPrice += this.getOptionsPrice(false)
       return totalPrice + this.getDetailTaxes()
       
    }
    getActualAmount(){
        if (this.finalAmount) return this.finalAmount
        if (this.estimatedAMount) return this.estimatedAMount
        return this.price.product.amount*this.quantity
    }
    getDetailTaxes(){
        let productTaxes = this.getActualAmount() * this.price.value * (this.price.product.tax.value / 100);
        return productTaxes + this.getOptionsTaxes()
    }
   
    getUnitaryPrice(withTaxes = true){
        
        let price = this.price.value * this.price.product.amount;
        if (withTaxes) {
            price += this.price.product.amount * this.price.value * (this.price.product.tax.value / 100);
        }
        return price;
    }
   

    getPricePerUnit(withTaxes = true) {
        let price = this.price.value * this.price.product.amount

        if (withTaxes) {
            price += this.getUnitTaxes();
        }

        return price;
    }

    addQuantity(quantity = 1) {
        if (this.amountModeSelected === 'units') {
            this.quantity += quantity;
            this.estimatedAmount = this.quantity * this.price.product.amount;
        } else {
            this.quantity = 1;
            this.estimatedAmount = quantity;
        }
    }

    getMainPhoto() {
        if (this.price.product.photoUrls.length > 0) return this.price.product.photoUrls[0];
        return "";
    }
    hasOptions(){
        return this.price.product.options && (this.price.product.options.length > 0)
    }
}

export default Detail;
