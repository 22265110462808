import {sendVoucherByEmailAsync, updateVoucherAsync} from "../../../services/voucherServices";
import { UPDATE_VOUCHER_ERROR,UPDATE_VOUCHER_SUCCESS} from "../../types/voucherTypes";
import Voucher from "../../../models/Voucher"

export function updateVoucherShippingDataAction(dedication, receiver, shippingEmail, voucherId) {
    return async (dispatch) => {
        try {
            const updatedVoucher = await updateVoucherAsync(voucherId, {dedication, receiver, shippingEmail});
            dispatch(updateVoucherSuccess(new Voucher(updatedVoucher)));
            sendVoucherByEmailAsync(updatedVoucher.id).catch(console.error);
        } catch (e) {
            dispatch(updateVoucherError());
        }
    }
}

const updateVoucherSuccess = voucher => ({
    type: UPDATE_VOUCHER_SUCCESS,
    payload: voucher
});

const updateVoucherError = () => ({
   type: UPDATE_VOUCHER_ERROR
});



