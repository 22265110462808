import {SELECT_NOTIFICATION} from "../../types/notificationTypes";

export function selectNotificationAction(notification) {
    return (dispatch) => {
        dispatch(selectNotification(notification));
    }
}

const selectNotification = notification => ({
    type: SELECT_NOTIFICATION,
    payload: notification
});