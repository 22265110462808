import axiosClient from "../config/axios";

export function fetchCreditCardsByClientIdAsync(clientId) {
    return axiosClient.get(`/clients/${clientId}/credit_cards`)
        .then(result => result.data)
        .catch(error => new Error(error));
}

export function createCreditCardAsync(clientId, creditCardBody) {
    return axiosClient.post(`/clients/${clientId}/credit_cards`, creditCardBody)
        .then(result => result.data)
        .catch(error => new Error(error));
}
