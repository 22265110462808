import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogTitle } from '@material-ui/core';
import { getCookiesPolicy } from "../components/legal_data/getLegalData";
import useLegalPolicy from "../../hooks/useLegalPolicy";

export default function CookiePolicyDialog() {

    const {cookiePolicyOpened, onCloseCookiePolicy} = useLegalPolicy();

    return (
        <Dialog
            open={cookiePolicyOpened}
            onClose={onCloseCookiePolicy}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}
        >
            <DialogContent>
                <DialogTitle>Política de Cookies</DialogTitle>
                { getCookiesPolicy() }
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    onClick={onCloseCookiePolicy}
                >
                    DE ACUERDO
                </Button>
            </DialogActions>
        </Dialog>
    );
}
