import {
    CHANGE_CREDIT_CARD_EDITOR_VISIBILITY,
    CHANGE_CREDIT_CARD_SELECTOR_VISIBILITY, 
    CHANGE_SHIPPING_CREDIT_CARD_SELECTED,
    CREDIT_CARD_CREATED_SUCCESS,
    FETCH_CREDIT_CARDS_SUCCESS, 
    INIT_CREATE_CREDIT_CARD,
    INIT_FETCH_CREDIT_CARD,
    SET_SETUP_CLIENT_SECRET
} from "../types/creditCardTypes";

const initialState = {
    creditCards: [],
    creditCardEditorOpened: false,
    creditCardSelectorOpened: false,
    loading: false,
    creditCardSelected: null,
    setupClientSecret:null
}

const state = (state = initialState, action) => {
    switch (action.type) {

        case CREDIT_CARD_CREATED_SUCCESS:
            return {
                ...state,
                loading: false,
                creditCards: [...state.creditCards, action.payload],
                creditCardSelected: action.payload,
                creditCardEditorOpened: false
            }

        case INIT_FETCH_CREDIT_CARD:
            return {
                ...state,
                creditCards: [],
                loading: true
            }

        case FETCH_CREDIT_CARDS_SUCCESS:
         
            return {
                ...state,
                creditCards: action.payload,
                loading: false
            }

        case INIT_CREATE_CREDIT_CARD:
            return {
                ...state,
                loading: true
            }

        case CHANGE_CREDIT_CARD_SELECTOR_VISIBILITY:
            return {
                ...state,
                creditCardSelectorOpened: action.payload
            }

        case CHANGE_CREDIT_CARD_EDITOR_VISIBILITY:
            return {
                ...state,
                creditCardEditorOpened: action.payload
            }

        case CHANGE_SHIPPING_CREDIT_CARD_SELECTED:
            return {
                ...state,
                creditCardSelected: action.payload,
                creditCardSelectorOpened: false
            }
            case SET_SETUP_CLIENT_SECRET:
                return {
                    ...state,
                    setupClientSecret: action.payload
                }
        default:
            return state;
    }
}

export default state;
