import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {getRssPolicy} from "../components/legal_data/getLegalData";
import useLegalPolicy from "../../hooks/useLegalPolicy";

export default function RSSPolicyDialog() {
    const {rssPolicyOpened, onCloseRSSPolicy} = useLegalPolicy();

    return (
        <Dialog
            open={rssPolicyOpened}
            onClose={onCloseRSSPolicy}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}>

            <DialogContent>
                { getRssPolicy() }
            </DialogContent>

            <DialogActions>
                <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    onClick={onCloseRSSPolicy}>
                    DE ACUERDO
                </Button>
            </DialogActions>
        </Dialog>
    );
}
