import React from 'react';
import { Box, Grid, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router";
import { selectCategoryAction } from "../../../redux/actions/category/selectCategoryAction";
import useCategory from "../../../hooks/useCategory";

const useStyles = makeStyles(() => ({
    media: {
        height: 360,
    },
    content: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 2,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
        position: "absolute",
        width: "80%",
        textAlign: "center",
        height: "100%",
        lineHeight: "100%"

    },
    card: {
        boxShadow: 'none',
        position: 'relative',
        maxWidth: 360,
        width: 360,
        margin: 15,


        '&:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            bottom: 0,
            zIndex: 1,
            background: 'linear-gradient(to top, #000, rgba(0,0,0,0))',
        },
    },
    title: {
        color: "white",
        fontSize: "3rem",
        lineHeight: "1",
        height: "360",
        fontFamily: "Baskervville"

    }
}));

const CategoryCell = ({ category }) => {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    const onClickCategory = () => {
        dispatch(selectCategoryAction(category));
        history.push(`/categories/${category.id}/details`);
    }

    return (
        <Grid item={true} styles={{ flexGrow: 5 }} >
            <Card className={classes.card} onClick={onClickCategory}>

                <CardMedia
                    className={classes.media}
                    image={category.photoUrl}
                    title={category.name}
                />
                <Box py={3} px={2} className={classes.content}>

                    <Typography>{category.description}</Typography>
                    <Typography className={classes.title}>{category.name}</Typography>

                </Box>

            </Card>
        </Grid>

    );
}

const CategoryCardList = ()  => {
    const { categories } = useCategory();

    if (!categories) return "Categorías no encontradas";

    return (
        <Grid container style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {
                categories.map((category) => {
                    return <CategoryCell key={category.id} category={category} />
                })
            }
        </Grid>

    )
}

export default CategoryCardList;
