import React, {useEffect} from 'react';
import SelectShippingMethodList from "../shipping_method/SelectShippingMethodList";
import {CircularProgress} from "@material-ui/core";
import DrawerHeader from '../cart/DrawerHeader'
import Drawer from '@material-ui/core/Drawer'
import useShippingMethod from "../../../hooks/useShippingMethod";
import useAddress from "../../../hooks/useAddress";


const SelectShippingMethodDrawer = () => {
    const {
        loadingShippingMethods, shippingMethods, shippingMethodSelected, shippingMethodSelectorOpened,
        initShippingMethods, onCloseSelectShippingMethod
    } = useShippingMethod();

    const {
        shippingAddressSelected
    } = useAddress();


    useEffect(() => {
        if (shippingMethodSelectorOpened) {
            initShippingMethods(shippingAddressSelected ? shippingAddressSelected.province.code:null);
        }
        // eslint-disable-next-line
    }, [shippingMethodSelectorOpened]);



    return (
        <Drawer anchor={"right"} open={shippingMethodSelectorOpened} onClose={onCloseSelectShippingMethod}>
             <DrawerHeader title="Elige un método de envío" onHandleClose={onCloseSelectShippingMethod}/>
             {(loadingShippingMethods) ? <CircularProgress /> :
             <SelectShippingMethodList shippingMethods={shippingMethods} shippingMethodSelected={shippingMethodSelected} />}
        </Drawer>
    );
};



export default SelectShippingMethodDrawer;
