import {CHANGE_PRODUCT_OPTIONS_VISIBILITY} from "../../types/detailTypes";

export function changeProductOptionsVisibilityAction(visibility) {
    return (dispatch) => {
        dispatch(changeVisibility(visibility));
    }
}

const changeVisibility = visibility => ({
    type: CHANGE_PRODUCT_OPTIONS_VISIBILITY,
    payload: visibility
})