import React, {useEffect} from 'react';
import {CircularProgress, Drawer} from "@material-ui/core";
import DrawerHeader from '../cart/DrawerHeader'
import OrdersList from './OrdersList'
import OrderDetail from './OrderDetail';
import useOrder from "../../../hooks/useOrder";

const OrdersHistoryDrawer = () => {
    const { loadingOrders, ordersHistory, ordersHistoryOpened, orderSelected, initOrders, onCloseOrdersHistoryDrawer } = useOrder();

    useEffect(() => {
        if (ordersHistoryOpened) {
            initOrders();
        }
        // eslint-disable-next-line
    }, [ordersHistoryOpened, orderSelected]);

    if (orderSelected) {
        return (
            <OrderDetail />
        );
    } else {
        return (

            <Drawer anchor={"right"} open={ordersHistoryOpened} onClose={onCloseOrdersHistoryDrawer} >
                <DrawerHeader title={"Tus Pedidos"} onHandleClose={onCloseOrdersHistoryDrawer}/>
                {
                    loadingOrders ? <CircularProgress/> : <OrdersList orders={ordersHistory} orderSelected={orderSelected} />
                }
            </Drawer>
        );
    }
}


export default OrdersHistoryDrawer;
