import GloriaLegalAdvise from "./gloria/GloriaLegalAdvise";
import GloriaCookiesPolicy from "./gloria/GloriaCookiesPolicy";
import GloriaPrivacyPolicy from "./gloria/GloriaPrivacyPolicy";
import GloriaRssPolicy from "./gloria/GloriaRssPolicy";
import GloriaShopConditions from "./gloria/GloriaShopConditions";

const getCookiesPolicy = () => {
    switch (process.env.REACT_APP_PARTNER_ID) {
        case '60e7203cc4bdc72898e7cf44':
            return <GloriaCookiesPolicy />
        default:
            return <div />
    }
}

const getLegalData = () => {
    switch (process.env.REACT_APP_PARTNER_ID) {
        case '60e7203cc4bdc72898e7cf44':
            return <GloriaLegalAdvise />
        default:
            return <div />
    }
}

const getPrivacyPolicy = () => {
    switch (process.env.REACT_APP_PARTNER_ID) {
        case '60e7203cc4bdc72898e7cf44':
            return <GloriaPrivacyPolicy />
        default:
            return <div />
    }
}

const getRssPolicy = () => {
    switch (process.env.REACT_APP_PARTNER_ID) {
        case '60e7203cc4bdc72898e7cf44':
            return <GloriaRssPolicy />
        default:
            return <div />
    }
}

const getShopConditions = () => {
    switch (process.env.REACT_APP_PARTNER_ID) {
        case '60e7203cc4bdc72898e7cf44':
            return <GloriaShopConditions />
        default:
            return <div />
    }
}

export {
    getCookiesPolicy,
    getLegalData,
    getPrivacyPolicy,
    getRssPolicy,
    getShopConditions
}
