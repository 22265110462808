export const INIT_FETCH_SHIPPING_METHOD = 'INIT_FETCH_SHIPPING_METHOD';
export const FETCH_SHIPPING_METHODS_SUCCESS = 'FETCH_SHIPPING_METHODS_SUCCESS';

export const FETCH_DIGITAL_SHIPPING_METHOD_SUCCESS = 'FETCH_DIGITAL_SHIPPING_METHODS_SUCCESS';


export const CHANGE_SHIPPING_METHOD_SELECTOR_VISIBILITY = 'CHANGE_SHIPPING_METHOD_SELECTOR_VISIBILITY';

export const CHANGE_SHIPPING_METHOD_SELECTED = 'CHANGE_SHIPPING_METHOD_SELECTED';

export const INIT_CREATE_SHIPPING_METHOD = 'INIT_CREATE_SHIPPING_METHOD';
export const SHIPPING_METHOD_CREATED_SUCCESS = 'SHIPPING_METHOD_CREATED_SUCCESS';

export const SHIPPING_METHOD_ERROR = 'SHIPPING_METHOD_ERROR';
