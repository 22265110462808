import useDetail from "../../../hooks/useDetail";
import {ShoppingBag, Trash2 as DeleteIcon} from "react-feather";
import {Edit as EditIcon} from "@material-ui/icons";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, IconButton, ListItemText} from "@material-ui/core";


const useStyles = makeStyles(() => ({
    paper: {
        background: "#F5F5F5",
        elevation: 1
    },
    quantityPicker: {
        width: "100%",
        display: "inline-flex",
        flexDirection: "row",
        marginTop: "1auto",
        boxShadow: "0 2px 5px 0 hsl(0deg 0% 51% / 50%)",
        padding: "5px",
        borderRadius: "6px"
    },
    quantitySelectorLabel: {
        flexGrow: 4,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center"
    }
}));

const ButtonForVoucher = () => {
    const classes = useStyles();
    const {detailSelected, onClickAddToCart, onDeleteClicked, onMinusClicked } = useDetail();

    return (
        (detailSelected.quantity === 0) ?
            <Button fullWidth variant={"contained"} onClick={onClickAddToCart} color="secondary"
                    style={{"marginTop": "auto"}}>
                AÑADIR AL CARRITO
                <ShoppingBag style={{marginLeft: "1rem"}}/>

            </Button>

            :

            <Box variant="outlined" className={classes.quantityPicker} >
                <IconButton size="small" onClick={() => onMinusClicked(detailSelected)} style={{color: "#f44336"}}>
                    <DeleteIcon/>
                </IconButton>
                {
                    <ListItemText onClick={onClickAddToCart} className={classes.quantitySelectorLabel}
                                  primary={`${detailSelected.getPrice().toFixed(2)}€`}
                                  secondary={`El cupón ya está en el carrito`}/>
                }
                <IconButton size="small" onClick={onClickAddToCart}>
                    <EditIcon/>
                </IconButton>
            </Box>
    )
};

export default ButtonForVoucher;
