import {CATEGORIES_ERROR, CATEGORIES_FETCHED_SUCCESS, FETCH_CATEGORIES} from "../../types/categoryTypes";
import {fetchCategoriesAsync} from "../../../services/categoryServices";

export function fetchCategoriesAction() {
    return async (dispatch) => {
        dispatch(initFetchCategories());

        const categoriesResponse = await fetchCategoriesAsync();

        if (categoriesResponse instanceof Error) {
            dispatch(categoriesError(categoriesResponse.toString()));
        } else {
            dispatch(categoriesFetchedSuccess(categoriesResponse));
        }
    }
}

const initFetchCategories = () => ({
    type: FETCH_CATEGORIES
});

const categoriesError = (errorMessage) => ({
    type: CATEGORIES_ERROR,
    payload: errorMessage
});

const categoriesFetchedSuccess = categories => ({
    type: CATEGORIES_FETCHED_SUCCESS,
    payload: categories
});