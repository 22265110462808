import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import swal from "sweetalert";
import {fetchFeaturedDetailsAction} from "../redux/actions/details/fetchFeaturedDetailsAction";
import useCart from "./useCart";
import {changeOrdersHistoryVisibilityAction} from "../redux/actions/order/changeOrdersHistoryVisibilityAction";
import {changeOrderSelectedAction, changeSelectedOrderAction} from "../redux/actions/order/changeOrderSelectedAction";
import {changeDeliveryDateSelectorVisibilityAction} from "../redux/actions/delivery_date/changeDeliveryDateSelectorVisibilityAction";
import {fetchOrdersHistoryAction} from "../redux/actions/order/fetchOrdersHistoryAction";

const useOrder = () => {
    const dispatch = useDispatch();

    const { clearCart } = useCart();

    const loadingOrders = useSelector(state => state["orderReducer"].loading);
    const newOrder = useSelector(state => state["orderReducer"].newOrder);
    const orderClientSecret = useSelector(state => state["cartReducer"].orderClientSecret);
    const orderSelected = useSelector(state => state["orderReducer"].orderSelected);
    const ordersHistory = useSelector(state => state["orderReducer"].ordersHistory).filter(order => order.paymentMethod.paymentInfo && order.paymentMethod.paymentInfo.status === "confirmed");
    const ordersHistoryOpened = useSelector(state => state["orderReducer"].ordersHistoryOpened);
    const paymentAlert = useSelector(state => state["orderReducer"].paymentAlert);

    useEffect(() => {
        if (paymentAlert) {
            swal({
                title: paymentAlert.message,
                text: paymentAlert.status === 'success' ?
                    "Recibirás un correo de confirmación en unos instantes."
                    : "Inténtalo de nuevo. Si el problema persiste, prueba con otra tarjeta.",
                icon: paymentAlert.status
            }).catch(console.error);

            if (paymentAlert.status === 'success') {
                clearCart();
                dispatch(fetchFeaturedDetailsAction());
            }
        }
        // eslint-disable-next-line
    }, [paymentAlert]);

    const initOrders = () => {
        dispatch(fetchOrdersHistoryAction());
    }

    const onClickSelectDeliveryDate = () => {
        dispatch(changeDeliveryDateSelectorVisibilityAction(true));
    }
    const onClickSelectOrderHistory = () => {
        dispatch(changeOrdersHistoryVisibilityAction(true));
    }

    const onClickOrderInHistory = (order) => {
        dispatch(changeOrderSelectedAction(order));
    }

    const onCloseOrdersHistoryDrawer = () => {
      
        dispatch(changeOrdersHistoryVisibilityAction(false));
    }
    const onCloseOrderDetail = () => {
      
        dispatch(changeOrderSelectedAction(null));
    }

    return {
        loadingOrders,
        newOrder,
        orderClientSecret,
        ordersHistory,
        orderSelected,
        ordersHistoryOpened,

        initOrders,

        onClickOrderInHistory,
        onCloseOrderDetail,
        onClickSelectDeliveryDate,
        onClickSelectOrderHistory,
        onCloseOrdersHistoryDrawer
    }
}

export default useOrder;
