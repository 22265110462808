import {
    FETCH_PRODUCTS,
    FETCH_PRODUCTS_ERROR,
    FETCH_PRODUCTS_SUCCESS
} from '../../types/productTypes';

import { fetchProductsAsync } from '../../../services/productServices';

export function fetchProductsAction() {

    return async (dispatch) => {
        dispatch(fetchProducts());

        const products = await fetchProductsAsync();

        if (products instanceof Error) {
            dispatch(productsFetchError());
        } else {
            dispatch( productsLoaded(products) );
        }
    };
}

const fetchProducts = () => ({
   type: FETCH_PRODUCTS
});

const productsLoaded = (products) => ({
    type: FETCH_PRODUCTS_SUCCESS,
    payload: products
});

const productsFetchError = () => ({
   type: FETCH_PRODUCTS_ERROR
});