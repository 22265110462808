import {CHANGE_ORDER_SELECTED} from "../../types/orderTypes";

export function changeOrderSelectedAction(order) {
    return (dispatch) => {
        dispatch(changeOrderSelected(order));
    }
}

const changeOrderSelected = order => ({
    type: CHANGE_ORDER_SELECTED,
    payload: order
});