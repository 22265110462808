import {CHANGE_CART_VISIBILITY} from "../../types/cartTypes";

export function changeCartVisibilityAction(visibility) {
    return ((dispatch) => {
        dispatch(changeCartVisibility(visibility));
    });
}

const changeCartVisibility = (visibility) => ({
    type: CHANGE_CART_VISIBILITY,
    payload: visibility
})
