import React, {useState, useEffect} from 'react';
import {Button, IconButton, Dialog, Typography, Box, DialogTitle, TextField, FormLabel} from '@material-ui/core/';
import DialogContent from '@material-ui/core/DialogContent';
import {useDispatch} from "react-redux";

import 'react-gallery-carousel/dist/index.css'
import {makeStyles} from '@material-ui/core/styles';
import {PlusCircle as PlusIcon, MinusCircle as MinusIcon} from "react-feather";
import {Close as CloseIcon, CardGiftcardOutlined as GiftIcon, EmailOutlined as EmailIcon, PersonOutlined as UserIcon} from "@material-ui/icons"
import {addDetailToCartAction} from "../../redux/actions/cart/addDetailToCartAction";
import {Formik} from 'formik';
import * as Yup from 'yup';
import VoucherOptions from "../components/voucher/VoucherOptions"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import useVoucher from "../../hooks/useVoucher";
import useDetail from "../../hooks/useDetail";

const useStyles = makeStyles(() => ({
    paper: {
        background: "#F5F5F5",
        elevation: 1
    },
    formLabel: {
        paddingBottom: "1.5rem",
        color: "#555555",
        display: "flex"
    },
    sectionLabel: {
        alignSelf: "center",
        paddingLeft: "10px",
        lineHeight: "1.3"
    },
    dialogBody: {
        overflowY: "auto",
        maxHeight: "calc(100vh - 112px)"
    },
    dialogTitle: {
        paddingBottom: 0,
        paddingTop: 0,
        paddingRight: 4,
        boxShadow: "-1px -1px 12px 0 #d5d5d5",
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: "20px,24px,24px,24px",
        overflowY: "hidden",
        borderBottom: 0

    },
    destinataryTextField: {
        marginBottom: "1rem",
    },
    dedicationTextField: {
        marginBottom: "1rem"
    },
    phoneTextField: {
        marginTop: "1rem"
    },
    personPicker: {
        width: "100%",
        display: "inline-flex",
        flexDirection: "row",
        marginTop: "1auto",
        borderRadius: "6px",
        justifyContent: "center",
        alignItems: "center",
        verticalAlign: "middle"
    },
    detailUnits: {
        display: "inline-flex",
        webkitBoxOrient: "vertical",
        webKitBoxDirection: "normal",
        webkitFlexDirection: "column",
        flexDirection: "column",
        webkitBoxAlign: "top",
        webkitAlignItems: "top",
        alignItems: "top",
        textAlign: "center"
    },
    personsTextField: {
        marginRight: "1rem"
    },
    optionTotal: {
        fontSize: "15px",
        paddingLeft: "20px",
        paddingRight: "0px",
        minWidth: "90px",
        fontWeight: "bold",
        textAlign: "center"
    },
    dialogButtons: {
        display: "flex",
        justifyContent: "flex-end",
        gap: "1rem",
        marginBottom: "2rem",
        paddingRight: "20px"
    }
}));

const VoucherOptionsDialog = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { detailSelected } = useDetail();
    const { voucherOptionsOpened, onCloseVoucherOptions, onVoucherOptionsSuccess } = useVoucher();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [state, setState] = useState({
        voucherData: detailSelected ? detailSelected.voucherData : null,
        voucherShippingInfo: {
            email: detailSelected ? detailSelected.email : "",
            shippingType: "digital",
            dedication: detailSelected ? detailSelected.dedication : "",
            receiver: detailSelected ? detailSelected.receiver : "",
            phone: detailSelected ? detailSelected.phone : ""
        },
        emailError: null,
        quantity: detailSelected ? detailSelected.quantity : 1,
        voucherInfo: detailSelected ? detailSelected.price.product.voucherInfo : null
    });

    useEffect(() => {
        const shippingInfo = {
            email: detailSelected ? detailSelected.email : "",
            shippingType: "digital",
            dedication: detailSelected ? detailSelected.dedication : "",
            receiver: detailSelected ? detailSelected.receiver : "",
            phone: detailSelected ? detailSelected.phone : ""
        }

        
        let quantity = detailSelected ? detailSelected.quantity : 1


        let voucherInfo;
        if (detailSelected && detailSelected.price.product.voucherInfo && !state.voucherInfo) {
            voucherInfo = detailSelected ? JSON.parse(JSON.stringify(detailSelected.price.product.voucherInfo)) : null
        }

        setState({
            ...state,
            voucherShippingInfo: shippingInfo,
            quantity,
            voucherInfo
        });
        // eslint-disable-next-line
    }, [detailSelected]);

    useEffect(() => {
        if (detailSelected) {
            setState({
                ...state,
                voucherInfo: detailSelected ? detailSelected.price.product.voucherInfo : null
            });
        }
        // eslint-disable-next-line
    }, [voucherOptionsOpened]);

    const {voucherInfo, voucherShippingInfo} = state;
    if (!voucherInfo || !voucherShippingInfo) return null;

   // const voucherIsBox = voucherInfo && detailSelected && detailSelected.price.product.name.toLowerCase().includes(" caja ")
    const voucherUnits = () => {
        if (voucherInfo && detailSelected && detailSelected.price.product.name.toLowerCase().includes(" caja ")){
            return "cajas"
        }
        if (voucherInfo && detailSelected && detailSelected.price.product.name.toLowerCase().includes("alojamiento")){
            return "noches"
        }
        return "personas"
    }

    const voucherOptionsSchema = Yup.object({
        email: Yup.string().email("Debes de introducir un email válido").trim().lowercase().required('Debes introducir un email válido'),
        isAGift: Yup.bool(),
        receiver: Yup.string().when("isAGift", {
            is: true,
            then: Yup.string()
        }),
        dedication: Yup.string(),
        phone: Yup.string().trim().min(9, "Faltan números").matches(/^[0-9]+$/, "El teléfono solo debe de estar compuesto por números"),
        quantity: Yup.number().min(voucherInfo.minUnits, `La cantidad mínima es ${voucherInfo.minUnits} €`)
    })


    const SelectNumberOfPeopleButton = (props) => (
        <React.Fragment>
            <Box className={classes.personPicker}>
                {<React.Fragment>
                    <TextField
                        label={`Número de ${voucherUnits()}`}
                        type="number"
                        fullWidth
                        className={classes.personsTextField}
                        color="secondary"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true
                        }}
                        {...props.getFieldProps('quantity')}
                        placeholder="Número de adultos"
                        value={state.quantity}/>
                </React.Fragment>
                }
                <div className={classes.detailUnits}>
                    <IconButton size="small" onClick={onPlusClicked}
                                disabled={detailSelected.price.product.voucherInfo.maxUnits === state.quantity}><PlusIcon/></IconButton>
                    <IconButton size="small" onClick={onMinusClicked}
                                disabled={detailSelected.price.product.voucherInfo.minUnits === state.quantity}><MinusIcon/></IconButton>
                </div>
                <Typography
                    className={classes.optionTotal}>{state.quantity ? ((detailSelected.getUnitaryPrice() * state.quantity).toFixed(2) + " €") : "0.00 €"}</Typography>
            </Box>
        </React.Fragment>
    );
    const onPlusClicked = () => {
        setState({...state, quantity: (state.quantity + 1)})
    }
    const onMinusClicked = () => {
        setState({...state, quantity: state.quantity - 1})
    }

    const SelectVoucherQuantityButton = (props) => (
        <React.Fragment>
            <FormLabel className={classes.formLabel}>Elige el importe del bono. Podrá canjearse, total o parcialmente,
                en cualquiera de nuestros restaurantes:</FormLabel>
            <Box className={classes.personPicker} >
                {<React.Fragment>
                    <TextField type="number" fullWidth className={classes.personsTextField} color="secondary"
                               label="Importe del cupón (en €)" variant="outlined"
                               {...props.getFieldProps('quantity')}
                               helperText={(props.touched.quantity && props.errors.quantity) ? props.errors.quantity : `Cualquier importe a partir de ${detailSelected.price.product.voucherInfo.minUnits} €`}
                               error={props.touched.quantity && props.errors.quantity}
                               InputLabelProps={{
                                   shrink: true
                               }}

                               placeholder="120"/>
                </React.Fragment>
                }
            </Box>
        </React.Fragment>
    );

    const handleEmailChanged = (event) => {
        voucherShippingInfo.email = event.target.value
        setState({...state, voucherShippingInfo: voucherShippingInfo, emailError: null});
    };

    const handleClose = () => {
        setState({...state, quantity: detailSelected.quantity, voucherInfo: detailSelected.price.product.voucherInfo})
        onCloseVoucherOptions()
    }
    const clearState = () => {
        setState({
            voucherData: null,
            voucherShippingInfo: {
                email: "",
                shippingType: "digital",
                dedication: "",
                receiver: "",
                phone:""
            },
            emailError: null,
            quantity: 1,
            voucherInfo: null
        })
    }

    if (!detailSelected || !detailSelected.price.product.voucherInfo) return null;

    return (
        <React.Fragment>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={voucherOptionsOpened}
                maxWidth={"md"}
                fullScreen={fullScreen}
                fullWidth>

                <DialogTitle className={classes.dialogTitle} >

                    <Box display="flex" alignItems="center" style={{padding: 0}}>

                        <Box flexGrow={1} style={{padding: 0}}>Opciones del cupón</Box>
                        <Box>
                            <IconButton onClick={handleClose}><CloseIcon/></IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <Formik
                    initialValues={{
                        quantity: state.quantity ? state.quantity : (voucherInfo && voucherInfo.minUnits) ? voucherInfo.minUnits : 0,
                        email: voucherShippingInfo ? voucherShippingInfo.email : "",
                        phone: voucherShippingInfo ? voucherShippingInfo.phone: "",
                        shippingType: "digital",
                        dedication: voucherShippingInfo ? voucherShippingInfo.dedication : "",
                        receiver: voucherShippingInfo ? voucherShippingInfo.receiver : "",
                        isAGift: voucherShippingInfo ? (voucherShippingInfo.receiver !== undefined) : false,
                    }}
                    validationSchema={
                        voucherOptionsSchema
                    }
                    onSubmit={(values) => {
                        //esto es necesario para que las transformaciones (por ejemplo, el trim) se aplique
                        const form = voucherOptionsSchema.cast(values)
                        detailSelected.email = form.email;
                        detailSelected.shippingType = form.shippingType;
                        detailSelected.dedication = form.dedication;
                        detailSelected.receiver = form.receiver;
                        detailSelected.phone = form.phone;
                        detailSelected.quantity = voucherInfo.type === "product" ? state.quantity : form.quantity;
                        detailSelected.price.product.voucherInfo = voucherInfo;
                        dispatch(addDetailToCartAction(detailSelected));
                        clearState();
                        onVoucherOptionsSuccess();

                    }}>
                    {
                        props => (
                            <form
                                onSubmit={props.handleSubmit}
                            >
                                <div className={classes.dialogBody}>
                                    <DialogContent dividers className={classes.dialogContent}>
                                        {voucherInfo.type === "product" ?
                                            <SelectNumberOfPeopleButton {...props}/>
                                            : <SelectVoucherQuantityButton {...props}/>
                                        }
                                    </DialogContent>
                                    { detailSelected.price.product.options ?
                                        <DialogContent dividers>
                                            <VoucherOptions options={detailSelected.price.product.options}
                                                            fullScreen={fullScreen}/>
                                        </DialogContent>
                                        : ""
                                    }

                                    <DialogContent dividers className={classes.dialogContent}>
                                        <div className={classes.formLabel}>
                                            <UserIcon styles={{alignSelf: "center"}}/>
                                            <FormLabel className={classes.sectionLabel}>Datos del  <b>destinatario</b> del cupón:</FormLabel>
                                        </div>
                                        <TextField id="email"
                                                   value={voucherShippingInfo.email || ''} color="secondary" label="Email del destinatario"
                                                   variant="outlined"
                                                   InputLabelProps={{
                                                       shrink: true
                                                   }}
                                                   onChange={handleEmailChanged}
                                            placeholder="Email al que se enviará el cupón (introduzca su propio email si desea recibirlo usted y entregárselo al destinatario en persona)"
                                                   {...props.getFieldProps('email')}
                                                   helperText={props.touched.email && props.errors.email}
                                                   error={props.touched.email && props.errors.email !== undefined}
                                        />
                                       
                                        <TextField className={classes.phoneTextField}
                                                    id="phone"
                                                   value={voucherShippingInfo.phone || ''} color="secondary" label="Teléfono  del destinatario"
                                                   variant="outlined"
                                                   InputLabelProps={{
                                                       shrink: true
                                                   }}
                                                   onChange={handleEmailChanged}
                                                   placeholder="Teléfono de contacto de la persona que canjeará el cupón"
                                                   {...props.getFieldProps('phone')}
                                                   helperText={props.touched.phone && props.errors.phone}
                                                   error={props.touched.phone && props.errors.phone !== undefined}
                                        />
                                    </DialogContent>
                                    <DialogContent dividers className={classes.dialogContent}>
                                        <div className={classes.formLabel}>
                                            <GiftIcon styles={{alignSelf: "center"}}/>
                                            <FormLabel className={classes.sectionLabel}>Si es un <b>regalo</b>, puedes
                                                añadir una <b>dedicatoria</b> al email:</FormLabel>
                                        </div>
                                        <TextField className={classes.destinataryTextField}
                                                   value={voucherShippingInfo.receiver || ''} color="secondary"
                                                   label="Para" variant="outlined"
                                                   InputLabelProps={{
                                                       shrink: true
                                                   }}
                                                   placeholder="Ejemplo: María y Carlos"
                                                   {...props.getFieldProps('receiver')}
                                                   helperText={props.touched.receiver && props.errors.receiver}
                                                   error={props.touched.receiver && props.errors.receiver !== undefined}
                                        />

                                        <TextField className={classes.dedicationTextField}
                                                   value={voucherShippingInfo.dedication || ''} rows={3} multiline
                                                   color="secondary" label="Dedicatoria" variant="outlined"
                                                   InputLabelProps={{
                                                       shrink: true
                                                   }}
                                                   placeholder="Un texto que acompañará al cupón en el correo que recibirá el destinatario"
                                                   {...props.getFieldProps('dedication')}
                                                   helperText={props.touched.dedication && props.errors.dedication}
                                                   error={props.touched.dedication && props.errors.dedication !== undefined}
                                        />
                                    </DialogContent>
                                    <div className={classes.dialogButtons}>
                                        <Button variant="outlined" onClick={handleClose}>Cancelar</Button>
                                        <Button variant={"contained"} type="submit" color="secondary">Aceptar</Button>
                                    </div>
                                </div>
                            </form>)}
                </Formik>
            </Dialog>
        </React.Fragment>
    );
}


export default VoucherOptionsDialog;
