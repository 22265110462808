import React from "react";
import {Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Trash2 as DeleteIcon, MinusCircle as MinusIcon, PlusCircle as PlusIcon} from "react-feather";
import {Edit as EditIcon} from "@material-ui/icons";
import useDetail from "../../../hooks/useDetail";
import drawerStyles from "../../ui/DrawerStyles";

const useStyles = makeStyles(() => ({
    paper: {
        background: "black"
    },
    detailItem: {
        paddingLeft: 10,
        paddingRight: 10,
        alignItems: "center",
        justifyItems: "center",
        background: "white",
        borderBottom: "1px solid #f5f5f5"

    },
    detailPrice: {
        display: "flex",
        webkitBoxOrient: "vertical",
        webKitBoxDirection: "normal",
        webkitFlexDirection: "column",
        flexDirection: "column",
        webkitBoxAlign: "end",
        webkitAlignItems: "flex-end",
        alignItems: "flex-end",
        overflow: "hidden",
        webkitFlexShrink: 0,
        flexShrink: 0,
        position: "relative"
    },
    detailUnits: {
        padding: "5px",
        display: "inline-flex",
        webkitBoxOrient: "vertical",
        webKitBoxDirection: "normal",
        webkitFlexDirection: "column",
        flexDirection: "column",
        webkitBoxAlign: "center",
        webkitAlignItems: "center",
        alignItems: "center",
        textAlign: "center"
    },
    detailProductName: {
        alignItems: "center",
        lineHeight: "1"
    },
    detailProductImage: {
        marginTop: 0
    }
}));

const DetailsInCartCell = ({detail}) => {
    const classes = useStyles();


    const voucherInfo = detail.price.product.voucherInfo;

    const { onClickDetail, onPlusClicked, onMinusClicked } = useDetail();

    return (
        (voucherInfo) ?
            <ListItem alignItems="flex-start"
                      className={classes.detailItem}>
                <div className={classes.detailUnits}>
                    <IconButton size="small" onClick={() => onClickDetail(detail)}
                                style={{paddingBottom: "0.5rem"}}><EditIcon/></IconButton>

                    <IconButton size="small" onClick={() => onMinusClicked(detail)}
                                style={{ paddingTop: "0.5rem", color: "#f44336"}}
                    ><DeleteIcon/></IconButton>
                </div>
                <ListItemAvatar className={classes.detailProductImage} onClick={() => onClickDetail(detail)}>
                    <Avatar alt={detail.price.product.name} src={detail.getMainPhoto()}/>

                </ListItemAvatar>

                <ListItemText
                    primary={
                        detail.price.product.name
                    }
                    primaryTypographyProps={
                        {
                            component: "span",
                            variant: "body2",
                            color: "textPrimary"
                        }}
                    secondary={
                        <div>
                            <div>{voucherInfo.type === "product" ?
                                `${detail.quantity} x adulto` :
                                `Valor: ${detail.getPrice().toFixed(2)} €`}
                            </div>
                            {detail.price.product.options ?
                                detail.price.product.options.map(option => option.quantity ?
                                    <div key={option.name}>{option.quantity} x {option.name}</div> : "") : ""
                            }

                        </div>


                    }
                    secondaryTypographyProps={
                        {
                            variant: "subtitle1",
                        }
                    }
                    onClick={() => onClickDetail(detail)}
                    className={classes.detailProductName}
                />
                <ListItemText
                    primary={<React.Fragment>
                        <Typography
                            component="span"
                            variant="subtitle2"
                            color="textPrimary"
                        >

                            {detail.getPrice().toFixed(2)}€
                        </Typography>
                    </React.Fragment>}
                    className={classes.detailPrice}
                />
            </ListItem>
            :
            <ListItem alignItems="flex-start" className={classes.detailItem}>
                <div className={classes.detailUnits}>
                    <IconButton size="small" onClick={() => onPlusClicked(detail)}><PlusIcon/></IconButton>
                    {detail.quantity}

                    <IconButton size="small" onClick={() => onMinusClicked(detail)}
                                style={
                                    detail.quantity === 1 ? {
                                        color: "#f44336"
                                    } : {}
                                }
                    >{detail.quantity > 1 ? <MinusIcon/> : <DeleteIcon/>}</IconButton>
                </div>
                <ListItemAvatar className={classes.detailProductImage} onClick={() => onClickDetail(detail)}>
                    <Avatar alt={detail.price.product.name} src={detail.getMainPhoto()}/>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        detail.price.product.name
                    }
                    primaryTypographyProps={
                        {
                            component: "span",
                            variant: "body2",
                            color: "textPrimary"
                        }}
                    secondary={
                        <div>
                            <div>
                                {detail.getUnitaryPrice().toFixed(2) + "€/ud"}
                            </div>
                            {detail.price.product.options ?
                                detail.price.product.options.map(option => option.quantity ?
                                    <div key={option.name}>{option.quantity} x {option.name}</div> : "") : ""
                            }
                        </div>


                    }
                    secondaryTypographyProps={
                        {
                            variant: "subtitle1",
                        }
                    }
                    onClick={() => onClickDetail(detail)}
                    className={classes.detailProductName}
                />
                <ListItemText
                    primary={<React.Fragment>
                        <Typography
                            component="span"
                            variant="subtitle2"
                            color="textPrimary"
                        >
                            {detail.getPrice().toFixed(2)}€
                        </Typography>
                    </React.Fragment>}
                    className={classes.detailPrice}
                />
            </ListItem>
    )
}

const DetailsInCartList = (props) => {
    const drawerClasses = drawerStyles();
    const {details} = props;

    if (!details) {
        return <Typography>Carrito Vacío</Typography>
    }

    return (
        <div className={drawerClasses.container}>
            <List>
                {
                    details.map(detail => <DetailsInCartCell key={detail.price.id} detail={detail}/>)
                }
            </List>

        </div>
    )
}


export default DetailsInCartList;
