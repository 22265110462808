import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import {Box, ListItem,ListItemIcon, ListItemSecondaryAction,ListItemText} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import HomeIcon from '@material-ui/icons/Home';
import RedeemIcon from '@material-ui/icons/Redeem';
import Receipt from '@material-ui/icons/Receipt';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import {Button,Drawer,IconButton} from "@material-ui/core";
import {HistoryOutlined as HistoryIcon, ChevronRight as RightIcon} from "@material-ui/icons";
import DrawerHeader from "../cart/DrawerHeader"
import ClientDataDrawer from "./ClientDataDrawer"
import useCart from "../../../hooks/useCart";
import useAddress from "../../../hooks/useAddress";
import useCreditCart from "../../../hooks/useCreditCart";
import useOrder from "../../../hooks/useOrder";
import useAuth from "../../../hooks/useAuth";
import useVoucher from "../../../hooks/useVoucher";
import useAccount from "../../../hooks/useAccount";

const useStyles = makeStyles((theme) => ({
    container: {
        minWidth:"calc(min(100vw, 375px))",
        maxWidth:"calc(min(100vw, 375px))",
        backgroundColor: theme.palette.background.paper,
    },
    topbar:{
        boxShadow:"0 8px 24px 0 rgba(37, 36, 47, .06), inset 0 -1px 0 0 #e5e5e5",
        display:"flex",
        height:56,
        paddingLeft:5,

        backgroundColor:"white"
    },
    title:{
        flexGrow:100,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column"
    },
    footer:{
        position:"absolute",
        bottom:0,
        left:0,
        right:0,
        padding: '1rem',
        boxShadow:"0 -1px 8px 0 rgba(0,0,0,.1)"
    },
    closeButton:{
        backgroundColor:"#ff373d",
        color:"white"
    }
}));

const AccountDrawer = () => {
    const classes = useStyles();

    const { onClickSelectShippingAddress } = useAddress();
    const { accountOpen, onCloseAccountDrawer } = useAccount();
    const { onClickCloseSession } = useAuth();
    const { detailsInCart } = useCart();
    const { onClickSelectCreditCard } = useCreditCart();
    const { onClickSelectOrderHistory } = useOrder();
    const { onClickVouchersHistory } = useVoucher();

    const [editingClient, setEditingClient] = React.useState(false);

    if (!detailsInCart) {
        return;
    }

    const onClickUserData = () => {
       setEditingClient(true);
    }
    const handleCloseEditingClient = () => {
        setEditingClient(false);
    }

    return (
        <React.Fragment >
            <Drawer anchor={"right"} open={accountOpen} onClose={onCloseAccountDrawer} >
            <DrawerHeader title="Tu cuenta" onHandleClose={onCloseAccountDrawer}/>
                <div className={classes.container}>
                    <List component="nav" aria-label="main mailbox folders">
                        <ListItem button onClick={onClickSelectShippingAddress}>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Tus direcciones"} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments">
                                    <RightIcon />
                                 </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                        <ListItem button onClick={onClickSelectCreditCard}>
                            <ListItemIcon>
                                <CreditCardIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Datos de pago"}  />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments">
                                    <RightIcon />
                                 </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                        <ListItem button onClick={onClickSelectOrderHistory}>
                            <ListItemIcon>
                                <HistoryIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Historial de pedidos"}   />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments">
                                    <RightIcon />
                                 </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                        <ListItem button onClick={onClickVouchersHistory}>
                            <ListItemIcon>
                                <RedeemIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Mis Bonos"}   />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments">
                                    <RightIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />

                        <ListItem button onClick={onClickUserData}>
                            <ListItemIcon>
                                <Receipt />
                            </ListItemIcon>
                            <ListItemText primary={"Datos de facturación"}   />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments">
                                    <RightIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />

                    </List>

                    <Box className={classes.footer}>
                    <Button variant={"contained"} type="submit" className={classes.closeButton} fullWidth onClick={onClickCloseSession}>
                            Cerrar sesión
                    </Button>
                </Box>

                </div>
            </Drawer>
            <ClientDataDrawer open={editingClient} onHandleClose={ handleCloseEditingClient }> </ClientDataDrawer>
        </React.Fragment>
    );
}

export default AccountDrawer;
