import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {getLegalData} from "../components/legal_data/getLegalData";
import useLegalPolicy from "../../hooks/useLegalPolicy";

export default function AvisoLegalDialog() {
    const {legalAdviseOpened, onCloseLegalAdvise} = useLegalPolicy();
    return (
        <Dialog
            open={legalAdviseOpened}
            onClose={onCloseLegalAdvise}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}
        >
            <DialogContent>
                { getLegalData() }
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    onClick={onCloseLegalAdvise}
                >
                    DE ACUERDO
                </Button>
            </DialogActions>
        </Dialog>
    );
}
