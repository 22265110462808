import {useDispatch, useSelector} from "react-redux";
import {changeShippingMethodSelectorVisibilityAction} from "../redux/actions/shipping_method/changeShippingMethodSelectorVisibilityAction";
import {fetchShippingMethodsAction} from "../redux/actions/shipping_method/fetchShippingMethodsAction";
import {changeDeliveryDateSelectorVisibilityAction} from "../redux/actions/delivery_date/changeDeliveryDateSelectorVisibilityAction";
import {changeDeliveryDateSelectedAction} from "../redux/actions/delivery_date/changeDeliveryDateSelectedAction";
import Detail from "../models/Detail";
import { changeShippingMethodSelectedAction } from "../redux/actions/shipping_method/changeShippingMethodSelectedAction";

const useShippingMethod = () => {
    const dispatch = useDispatch();

    const deliveryDateSelected = useSelector(state => state["deliveryDateReducer"].deliveryDateSelected);
    const digitalShippingMethod = useSelector(state => state["shippingMethodReducer"].digitalShippingMethod);
    const loadingShippingMethods = useSelector(state => state["shippingMethodReducer"].loading);
    const shippingMethods = useSelector(state => state["shippingMethodReducer"].shippingMethods);
    const shippingMethodSelected = useSelector(state => state["shippingMethodReducer"].shippingMethodSelected);
    const shippingMethodSelectorOpened = useSelector(state => state["shippingMethodReducer"].shippingMethodSelectorOpened);

    const initShippingMethods = (provinceCode) => {
        dispatch(fetchShippingMethodsAction(provinceCode));
    }
    const shippingMethodActualValue = (order) => {
        let cartValue = order.details.reduce((a, b) => a + (Detail.getFinalPrice(b) || 0), 0);
        if (cartValue > order.shippingMethod.freeFrom) {
            return 0;
        }
        return order.shippingMethod.value;
    }
    const shippingMethodValueDescription = (details, shippingMethod) => {
        let cartValue = details.reduce((a, b) => a + (Detail.getFinalPrice(b) || 0), 0);
        if (cartValue > shippingMethod.freeFrom) {
            return `Gratis`;
        }
        return `${shippingMethod.value.toFixed(2)} €`;
    }


    const onClickSelectShippingMethod = () => {
        dispatch(changeShippingMethodSelectorVisibilityAction(true));
    }
    const onClickShippingMethod = (shippingMethod) => {
        dispatch(changeShippingMethodSelectedAction(shippingMethod));
    }

    const onSelectDeliveryDate = ((selectedDate) => {
        //Esta ñapa es porque sin ella cuando no tienes deliveryDate seleccionada, este drawer se cerraba nada más abrirse al dispararse el onAccept
        //TO_DO lo malo es que ahora al cliente se le selecciona de maner automática la primera fecha disponible una vez que entra quí
        if (deliveryDateSelected) {
            dispatch(changeDeliveryDateSelectorVisibilityAction(false))
        }
        dispatch(changeDeliveryDateSelectedAction(selectedDate))
    });

    const onCloseSelectShippingMethod = () => {
        dispatch(changeShippingMethodSelectorVisibilityAction(false));
    }

    return {
        deliveryDateSelected,
        digitalShippingMethod,
        loadingShippingMethods,
        shippingMethods,
        shippingMethodSelected,
        shippingMethodSelectorOpened,

        initShippingMethods,
        shippingMethodActualValue,
        shippingMethodValueDescription,

        onClickShippingMethod,
        onClickSelectShippingMethod,
        onSelectDeliveryDate,

        onCloseSelectShippingMethod
    }
}

export default useShippingMethod;
