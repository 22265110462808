import { useEffect, useRef, useState } from 'react';
import {Box, Link} from "@material-ui/core";
import YouTube from 'react-youtube';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {useTheme} from '@material-ui/core/styles';
import useDetail from "../../../hooks/useDetail";
import {Typography} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from "@material-ui/core";
import React from 'react';
import { DETAIL_SELECTED } from '../../../redux/types/detailTypes';

const useStyles = makeStyles(() => ({
    blockDescription: {
    },
    blockTitle: {
         fontWeight: 'bold'
    },
    blockRoot: {
        marginTop:'16px'
    },
  

}));

const YoutubePlayer = ({ videoId, title, pdfUrl }) => {
    const ref = useRef(null);
     const classes = useStyles();

  const [parentHeight, setParentHeight] = useState(0);
    const [parentWidth, setParentWidth] = useState(0);
    
 useEffect(() => {
    setParentHeight(ref.current.offsetHeight);
    setParentWidth(ref.current.offsetWidth);
  }, []);

    return (<Accordion classes={{ root: classes.blockRoot }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.blockTitle}>{title.toUpperCase()}</Typography>
                </AccordionSummary>
        <AccordionDetails>
            <div style={{ width : "100%"}}>
                    <Box ref={ref} sx={{ marginBottom: "16px", marginTop: "16px", aspectRatio: 16 / 9, width: "100%" }}>
        <YouTube
            videoId={videoId}
            opts={{ height: parentHeight, width: parentWidth }}            // defaults -> ''
/>
            </Box>
            { pdfUrl ?
                    <Typography>También puedes <Link href={pdfUrl} target="_blank" underline="allways" color={"secondary"} variant="body2">
                    descargar la receta en PDF
                </Link> </Typography> : ""
                }
                </div>
                </AccordionDetails>
            </Accordion>)
    
}

export default YoutubePlayer;