import {useEffect} from 'react';
import {useParams} from "react-router";
import {CircularProgress, Container} from "@material-ui/core";
import ProductList from "../components/product/ProductList";
import useProduct from "../../hooks/useProduct";
import useCategory from "../../hooks/useCategory";

const ProductsPage = () => {
    const { category_id } = useParams();

    const { categorySelected } = useCategory();
    const { products, productsLoading, getProductsByCategoryId } = useProduct();

    useEffect(() => {
        if (category_id) {
            getProductsByCategoryId(category_id);
        }

        // eslint-disable-next-line
    }, [category_id]);

    if (!categorySelected) return null;

    return (
        <Container>
            {
                productsLoading
                    ? <CircularProgress />
                    : <div>
                        <p>Productos de { categorySelected.name } </p>
                        <ProductList products={products} />
                    </div>
            }
        </Container>
    )
}

export default ProductsPage;
