import {useDispatch, useSelector} from "react-redux";
import {changeRegisterDrawerVisibilityAction} from "../redux/actions/auth/changeRegisterDrawerVisibilityAction";
import {changeShoppingInfoVisibilityAction} from "../redux/actions/cart/changeShoppingInfoVisibilityAction";
import useAuth from "./useAuth";
import useCart from "./useCart";
import {changeAuthFormVisibilityAction} from "../redux/actions/auth/changeAuthFormVisibilityAction";

const useRegister = () => {
    const dispatch = useDispatch();
    const { isLogged } = useAuth();
    const { cartOpen } = useCart();

    const registerOpen = useSelector(state => state["authReducer"].registerDrawerOpen);

    const onClickLogin = () => {
        dispatch(changeAuthFormVisibilityAction(true));
        onCloseRegisterDrawer();
    }

    const onCloseRegisterDrawer = () => {
        dispatch(changeRegisterDrawerVisibilityAction(false));
        if (cartOpen && isLogged) {
            //SI EL CARRITO ESTÁ ABIERTO AL HACER LOGIN TENEMOS QUE PASAR A SHOPPING_INFO
            dispatch(changeShoppingInfoVisibilityAction(true));
        }
    }

    return {
        registerOpen,

        onClickLogin,

        onCloseRegisterDrawer
    }

}

export default useRegister;
