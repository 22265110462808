import React from 'react';
import { IconButton, Box, TextField, List, Typography, Container } from '@material-ui/core/';

import 'react-gallery-carousel/dist/index.css'
import { makeStyles } from '@material-ui/core/styles';
import {
    PlusCircle as PlusIcon,
    MinusCircle as MinusIcon
} from "react-feather";

import useDetail from "../../../hooks/useDetail";


const useStyles = makeStyles(() => ({
    option: {
        width: "100%",
        display: "inline-flex",
        flexDirection: "row",
        marginTop: "1auto",
        borderRadius: "6px",
        justifyContent: "center",
        alignItems: "center",
        verticalAlign: "middle",
        marginBottom: "0.5rem"
    },
    optionResponsive: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        marginTop: "1auto",
        borderRadius: "6px",
        justifyContent: "center",
        alignItems: "center",
        verticalAlign: "middle",
        marginBottom: "0.5rem",
        paddingRight: 0,
        paddingLeft: 0
    },
    detailUnits: {
        display: "inline-flex",
        webkitBoxOrient: "vertical",
        webKitBoxDirection: "normal",
        webkitFlexDirection: "column",
        flexDirection: "column",
        webkitBoxAlign: "top",
        webkitAlignItems: "top",
        alignItems: "top",
        textAlign: "center",
        alignSelf: "center",
        paddingLeft: "5px"
    }, personsTextField: {
        marginRight: "1rem"
    },
    optionsList: {
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    optionContainer: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: 0,
        paddingRight: 0
    },
    optionTitle: {
        paddingLeft: "0px",
        fontWeight: "bold",
        fontSize: "14px"
    },
    optionValue: {
        fontSize: "15px",
        padding: "0px",
        minWidth: "90px",
        alignSelf: "center"
    },
    optionTotal: {
        fontSize: "15px",
        paddingLeft: "20px",
        paddingRight: "0px",
        minWidth: "90px",
        textAlign: "center",
        fontWeight: "bold",
        alignSelf: "center"
    },
    selectUnitsContainer: {
        display: "flex",
        wrap: "nowrap",
        paddingTop: "5px",
        paddingLeft: 0,
        paddingRight: 0,
        justifyContent: "right"
    },
    unitsTextField: {
        alignSelf: "center"
    }
}));

  
const VoucherOptions = (props) => {
    const { options, maxUnits, fullScreen } = props;
      const {onChangeInvalidOptionVisibility } = useDetail();

    const classes = useStyles();

    const VoucherOption = ({ option, maxUnits }) => {
        const [quantity, setQuantity] = React.useState(option.quantity ? option.quantity : 0);

        const onPlusClicked = () => {
            const newQuantity = quantity + 1;
            setQuantity(newQuantity)
            option.quantity = newQuantity
        }
        const onMinusClicked = () => {
            if (quantity > 0) {
                const newQuantity = quantity - 1;
                setQuantity(newQuantity)
                option.quantity = newQuantity
            }
        }

        const onClickAddOption = () => {
        //SI la opción es embalaje en caja, miramos si hay ya metido algún libro
        if (option.name.toLowerCase().includes("caja")) {
            if (options.find(option => option.name.toLowerCase().includes("libro") && option.quantity > 0)) {
                onChangeInvalidOptionVisibility(true)
                return;
            } 
            
        }
         //SI la opción es libro, miramos a ver si esta metido el embalaje en caja
        if (option.name.toLowerCase().includes("libro")) {
            if (options.find(option => option.name.toLowerCase().includes("caja") && option.quantity > 0)) {
                onChangeInvalidOptionVisibility(true)
                return;
          }
        } 
        onPlusClicked(option)
 }
   

        return (
            <React.Fragment key={option.name}>
                <Box className={fullScreen ? classes.optionResponsive : classes.option}>
                    <Container className={classes.optionContainer}>
                        <Typography className={classes.optionTitle}>{option.name}</Typography>
                    </Container>
                    <Container className={classes.selectUnitsContainer}>
                        <Typography
                            className={classes.optionValue}>{(option.value * 1.10).toFixed(2) + " €/ud"}</Typography>
                        <TextField
                            type="number"
                            className={classes.unitsTextField}
                            color="secondary"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true
                            }}
                            placeholder="unidades"
                            value={quantity} />

                        <div className={classes.detailUnits}>
                            <IconButton size="small" onClick={onClickAddOption}
                                disabled={maxUnits > 0 && maxUnits === quantity}><PlusIcon /></IconButton>
                            <IconButton size="small" onClick={onMinusClicked}
                                disabled={quantity === 0}><MinusIcon /></IconButton>
                        </div>
                        <Typography className={classes.optionTotal}>
                            {
                                quantity
                                    ? ((option.value * quantity * 1.1).toFixed(2) + " €")
                                    : "0.00 €"
                            }
                        </Typography>
                    </Container>
                </Box>
            </React.Fragment>
        )
    };

    function isTransfer(option) {
        return option && option.name && option.name.toLowerCase().includes("transfer")
    }

    if (!options || options.count === 0) {
        return null;
    }

    return (
        <React.Fragment>

            <List className={classes.optionsList}>
                {
                    options.map(option => <VoucherOption key={option.name} option={option} maxUnits={isTransfer(option) ? 1 : maxUnits} />)
                }
            </List>
        </React.Fragment>
    );
}


export default VoucherOptions;
