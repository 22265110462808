import {CHANGE_VOUCHERS, LOAD_VOUCHERS} from "../../types/voucherTypes";
import {getVouchersByClientIdAsync} from "../../../services/voucherServices";
import Voucher from "../../../models/Voucher"

export function getVouchersByClientIdAction(clientId) {
    return async (dispatch) => {
        dispatch(loadVouchers());

        const vouchers = await getVouchersByClientIdAsync(clientId);

        if (vouchers) {
            dispatch(changeVouchers(vouchers.map(voucher => new Voucher(voucher))));
        } else {
            // Launch Error
        }
    }
}

const loadVouchers = () => ({
   type: LOAD_VOUCHERS
});

const changeVouchers = vouchers => ({
    type: CHANGE_VOUCHERS,
    payload: vouchers
})
