export const INIT_FETCH_PAYMENT_METHOD = 'INIT_FETCH_PAYMENT_METHOD';
export const FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS';

export const CHANGE_PAYMENT_METHOD_SELECTOR_VISIBILITY = 'CHANGE_PAYMENT_METHOD_SELECTOR_VISIBILITY';

export const CHANGE_PAYMENT_METHOD_SELECTED = 'CHANGE_PAYMENT_METHOD_SELECTED';

export const INIT_CREATE_PAYMENT_METHOD = 'INIT_CREATE_PAYMENT_METHOD';
export const PAYMENT_METHOD_CREATED_SUCCESS = 'PAYMENT_METHOD_CREATED_SUCCESS';

export const PAYMENT_METHOD_ERROR = 'PAYMENT_METHOD_ERROR';
