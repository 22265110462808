import {FETCH_FEATURED_DETAILS_SUCCESS, INIT_FETCH_FEATURED_DETAILS} from "../../types/detailTypes";
import {fetchFeaturedPricesAsync, fetchFeaturedPricesByFeeId} from "../../../services/priceServices";
import Detail from "../../../models/Detail";

export function fetchFeaturedDetailsAction() {
    return async (dispatch) => {
        dispatch(initFetch);

        let featuredPrices;
        if (localStorage.getItem('clientId')) {
            featuredPrices = await fetchFeaturedPricesAsync();
        } else {
            featuredPrices = await fetchFeaturedPricesByFeeId(process.env.REACT_APP_UNREGISTERED_FEE_ID);
        }


        if (featuredPrices instanceof Error) {

        } else {
            dispatch(fetchDetailsSuccess(featuredPrices.map(price => Detail.createFromPrice(price))));
        }
    }
}

const initFetch = ({
   type: INIT_FETCH_FEATURED_DETAILS
});

const fetchDetailsSuccess = details => ({
    type: FETCH_FEATURED_DETAILS_SUCCESS,
    payload: details
})
