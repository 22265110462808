import {useDispatch, useSelector} from "react-redux";
import {changeVoucherOptionsVisibilityAction} from "../redux/actions/details/changeVoucherOptionsVisibilityAction";
import {changeDetailSelectedAction} from "../redux/actions/details/changeDetailSelectedAction";
import {changeCartVisibilityAction} from "../redux/actions/cart/changeCartVisibilityAction";
import {changeVouchersVisibilityAction} from "../redux/actions/voucher/changeVouchersVisibilityAction";
import {changeVoucherSelectedAction} from "../redux/actions/voucher/changeVoucherSelectedAction";

const useVoucher = () => {
    const dispatch = useDispatch();

    const vouchers = useSelector(state => state["voucherReducer"].vouchers);
    const voucherHistoryOpened = useSelector(state => state["voucherReducer"].vouchersVisibility);
    const voucherOptionsOpened = useSelector(state => state["detailReducer"].voucherOptionsOpened);
    const voucherSelected = useSelector(state => state["voucherReducer"].voucherSelected);
    const voucherUpdatedResult = useSelector(state => state["voucherReducer"].voucherUpdatedResult);

    function onClickDownload() {
        const a = document.createElement("a");
        a.href = `${process.env.REACT_APP_BASE_URL}/vouchers/${voucherSelected.id}/pdf`;
        a.setAttribute("download", voucherSelected.product.name+".pdf");
        a.click();
    }
    const onClickSendMail = () => {
        dispatch(changeVoucherOptionsVisibilityAction(true))
    }
    const onVoucherSelected = (voucher) => {
        dispatch(changeVoucherSelectedAction(voucher))
    }
    const onClickVouchersHistory = () => {
        dispatch(changeVouchersVisibilityAction(true));
    }

    const onCloseVoucherDetail = () => {
        dispatch(changeVoucherSelectedAction(null))
    }
    const onCloseVoucherDrawer = () => {
        dispatch(changeVouchersVisibilityAction(false));
    };

    const onCloseVoucherOptions = () => {
        dispatch(changeVoucherOptionsVisibilityAction(false));
    }

    const onVoucherOptionsSuccess = () => {
        dispatch(changeVoucherOptionsVisibilityAction(false));
        dispatch(changeDetailSelectedAction(null))
        dispatch(changeCartVisibilityAction(true));
    }

    return {
        vouchers,
        voucherHistoryOpened,
        voucherOptionsOpened,
        voucherSelected,
        voucherUpdatedResult,

        onClickDownload,
        onClickSendMail,
        onVoucherSelected,
        onClickVouchersHistory,

        onCloseVoucherDrawer,
        onCloseVoucherDetail,
        onCloseVoucherOptions,

        onVoucherOptionsSuccess
    }
}

export default useVoucher;
