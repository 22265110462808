import axiosClient from "../config/axios";

export function fetchCategoriesAsync() {
    return axiosClient.get("/families").then(result => result.data).catch(error => new Error(error.toString()));
}

export function getCategoryByIdAsync(categoryId) {
    return axiosClient.get(`/families/${categoryId}`)
        .then(result => result.data)
        .catch(error => new Error(error.toString()));
}