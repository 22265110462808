import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useDetail from '../../../hooks/useDetail';
import {createStyles, makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    body: {
        overflowY: "auto"
    },
}));

export default function IncompatibleOptionDialog() {
    const {invalidOptionOpened, onChangeInvalidOptionVisibility} = useDetail();

    return (
        <Dialog
            open={invalidOptionOpened}
            onClose={()=>onChangeInvalidOptionVisibility(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}>
 <DialogTitle>No es posible elegir la opción</DialogTitle>
            <DialogContent>
                <Typography>La opción “caja de madera” es incompatible con cualquiera de los dos libros por cuestiones de espacio.</Typography>
            </DialogContent>

            <DialogActions>
                <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    onClick={()=>onChangeInvalidOptionVisibility(false)}>
                    CERRAR
                </Button>
            </DialogActions>
        </Dialog>
    );
}
const IncompatibleOptionsMessage = () => {
    const classes = useStyles();

    return (<div className={classes.body} dangerouslySetInnerHTML={{
        __html: `
 <p><strong>T&Eacute;RMINOS Y CONDICIONES DE USO</strong></p>

 `
    }}/>)
}

