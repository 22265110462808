import firebaseAdmin from "../../../config/firebaseConfig";
import {
    RECOVER_PASSWORD_COMPLETE,
    RECOVER_PASSWORD_ERROR,
    RECOVER_PASSWORD_RESET,
    RECOVER_PASSWORD_START} 
    from "../../types/authTypes";



export function recoverPasswordAction(email) {
    return async (dispatch) => {

        dispatch(recoverPasswordStart())
        await firebaseAdmin.sendPasswordResetEmail(email)
            .then(result => {
                dispatch(recoverPasswordComplete())
            })
            .catch(error => {
                dispatch(recoverPasswordError(error.code));
            });
    }
}

export function resetRecoverPasswordStatus(){
    return async (dispatch) => {
        dispatch(recoverPasswordReset());
    }
}

const recoverPasswordStart = () => ({
    type: RECOVER_PASSWORD_START
});

const recoverPasswordReset = () => ({
    type: RECOVER_PASSWORD_RESET
});


const recoverPasswordError = (errorMessage) => ({
    type: RECOVER_PASSWORD_ERROR,
    payload: errorMessage
});
const recoverPasswordComplete = () => ({
    type: RECOVER_PASSWORD_COMPLETE,
});
