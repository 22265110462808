import {CHANGE_CREATE_ADDRESS_VISIBILITY} from "../../types/addressTypes";

export function changeAddressEditorVisibilityAction(visibility) {
    return (dispatch) => {
        dispatch(changeVisibility(visibility));
    }
}

const changeVisibility = visibility => ({
    type: CHANGE_CREATE_ADDRESS_VISIBILITY,
    payload: visibility
});
