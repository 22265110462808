import React from 'react';
import { useDispatch } from "react-redux";
import {Container, Typography} from "@material-ui/core";
import {useHistory} from "react-router";
import {selectCategoryAction} from "../../../redux/actions/category/selectCategoryAction";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useCategory from "../../../hooks/useCategory";

const CategoryList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);

    const { categories } = useCategory();

    const handleChange = (event, newValue) => {
        const category = categories[newValue];

        if (!category) {
            return
        }
        dispatch(selectCategoryAction(category));
        history.push(`/categories/${category.id}/details`);
        setValue(newValue);
    }


    if (!categories) {
        return "NO HAY CATEGORÍAS"
    }

    return (
        <Container style={{maxWidth: "2000px"}}>
            <Tabs
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="primary"
                centered
                value={value}
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                {
                    categories.map(category => {
                        return <Tab label={<Typography
                            style={{fontFamily: "Roboto", fontSize: "0.9rem"}}>{category.name}</Typography>}
                                    key={category.id} category={category}/>
                    })
                }

            </Tabs>

        </Container>
    )
}

export default CategoryList;
