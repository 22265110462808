import {useDispatch, useSelector} from "react-redux";
import {clearCartAction} from "../redux/actions/cart/clearCartAction";
import {changeCartVisibilityAction} from "../redux/actions/cart/changeCartVisibilityAction";
import {changeShoppingInfoVisibilityAction} from "../redux/actions/cart/changeShoppingInfoVisibilityAction";
import {changeRegisterDrawerVisibilityAction} from "../redux/actions/auth/changeRegisterDrawerVisibilityAction";

const useCart = () => {
    const dispatch = useDispatch();

    const cartOpen = useSelector(state => state["cartReducer"].cartOpened);
    const detailsInCart = useSelector(state => state["cartReducer"].detailsInCart);
    const shoppingInfoOpened = useSelector(state => state["cartReducer"].shoppingInfoOpened);

    const isLogged = useSelector(state => state["authReducer"].isLogged);


    const onClickPaymentButton = () => {
        if (isLogged) {
            dispatch(changeShoppingInfoVisibilityAction(true));
        } else {
            dispatch(changeRegisterDrawerVisibilityAction(true))
        }
    }

    const onCloseShoppingInfo = () => {
        dispatch(changeShoppingInfoVisibilityAction(false));
    }

    const clearCart = () => {
        dispatch(clearCartAction());
    }
    const onCloseCart = () => {
        dispatch(changeCartVisibilityAction(false));
    }

    return {
        cartOpen,
        detailsInCart,
        shoppingInfoOpened,

        clearCart,

        onClickPaymentButton,

        onCloseCart,
        onCloseShoppingInfo
    }

}

export default useCart;
