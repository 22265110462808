import {
    CHANGE_VOUCHER_OPTIONS_VISIBILITY,
    CHANGE_PRODUCT_OPTIONS_VISIBILITY,
    CHANGE_INVALID_OPTION_VISIBILITY,
    DETAIL_ADDED_ERROR,
    DETAIL_ADDED_SUCCESS,
    DETAIL_SELECTED,
    OPTION_SELECTED,
    FETCH_DETAILS,
    FETCH_DETAILS_ERROR,
    FETCH_DETAILS_SUCCESS,
    FETCH_FEATURED_DETAILS_SUCCESS,
    INIT_FETCH_FEATURED_DETAILS,
} from '../types/detailTypes';

const initialState = {
    details: [],
    detailSelected: null,
    optionSelected:null,
    error: null,
    detailsFeatured: [],
    loading: false,
    voucherOptionsOpened: false,
    productOptionsOpened: false,
    invalidOptionOpened: false
}

const state = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FEATURED_DETAILS_SUCCESS:
            return {
                ...state,
                detailsFeatured: action.payload,
                loading: false
            }

        case FETCH_DETAILS:
            return {
                ...state,
                loading: true,
                details: []
            }

        case FETCH_DETAILS_ERROR:
        case DETAIL_ADDED_ERROR:
        case FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                details: action.payload
            }

        case DETAIL_ADDED_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                details: [...state.details, action.payload]
            }

        case DETAIL_SELECTED:
            return {
                ...state,
                detailSelected: action.payload
            }
        
          case OPTION_SELECTED:
            return {
                ...state,
                optionSelected: action.payload
            }

        case INIT_FETCH_FEATURED_DETAILS:
            return {
                ...state,
                detailsFeatured: [],
                loading: true
            }
        case CHANGE_VOUCHER_OPTIONS_VISIBILITY:

            return {
                ...state,
                voucherOptionsOpened: action.payload
            }
        case CHANGE_PRODUCT_OPTIONS_VISIBILITY:
            return {
                ...state,
                productOptionsOpened: action.payload
            }
         case CHANGE_INVALID_OPTION_VISIBILITY:
            return {
                ...state,
                invalidOptionOpened: action.payload
            }
        default:
            return state;
    }
};

export default state;
