import useDetail from "../../../hooks/useDetail";
import {ShoppingBag} from "react-feather";
import React from "react";
import {Button} from "@material-ui/core";
import AddToCartButton from "../cart/AddCartButton";

const ButtonForDetail = () => {
    const { detailSelected, onClickAddToCart } = useDetail();

    return (
        (detailSelected.quantity === 0)
            ? (<Button fullWidth variant={"contained"} onClick={onClickAddToCart} color="secondary"
                        style={{"marginTop": "auto"}}>
                    {detailSelected.hasOptions() ? "AÑADIR AL CARRITO" :
                        "AÑADIR AL CARRITO"}
                    <ShoppingBag style={{marginLeft: "1rem"}}/>
                </Button>)
            : <AddToCartButton />
    )
};

export default ButtonForDetail;
