import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {useTheme} from '@material-ui/core/styles';
import useDetail from "../../../hooks/useDetail";
import {Typography} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from "@material-ui/core";
import React from 'react';

const useStyles = makeStyles(() => ({
    blockDescription: {
    },
    blockTitle: {
         fontWeight: 'bold'
    },
    blockRoot: {
        marginTop:'16px'
    },
  

}));

const DescriptionBlocks = () => {
    const classes = useStyles();
    
    const { detailSelected, onCloseDetail } = useDetail();

    if (!detailSelected) {
        return null;
    }
    if (!detailSelected.price.product.descriptionBlocks) {
        return <></>;
    }
    return <React.Fragment>
        {detailSelected.price.product.descriptionBlocks.map((block) =>
            <Accordion classes={{ root: classes.blockRoot }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.blockTitle}>{block.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classes.blockDescription}>
                        <div dangerouslySetInnerHTML={{ __html: block.description }} />
                    </Typography>
                </AccordionDetails>
            </Accordion>)
        }
    </React.Fragment>
}

export default DescriptionBlocks;