import {
    CHANGE_VOUCHERS,
    CHANGE_VOUCHERS_VISIBILITY,
    CHANGE_VOUCHER_SELECTED,
    LOAD_VOUCHERS,
    UPDATE_VOUCHER_CLEAR_RESULT,
    UPDATE_VOUCHER_ERROR,
    UPDATE_VOUCHER_SUCCESS
} from "../types/voucherTypes";
import update from 'immutability-helper';

const initialState = {
    loading: false,
    vouchers: [],
    voucherSelected: null,
    vouchersVisibility: false,
    voucherUpdatedResult: undefined
}

const state = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_VOUCHERS:
            return {
                ...state,
                vouchers: action.payload,
                loading: false
            }
        case CHANGE_VOUCHERS_VISIBILITY:
            return {
                ...state,
                vouchersVisibility: action.payload,
            }
        case CHANGE_VOUCHER_SELECTED:
            return {
                ...state,
                voucherSelected: action.payload,
                voucherUpdatedResult: undefined
            }
        case LOAD_VOUCHERS:
            return {
                ...state,
                loading: true
            }
        case UPDATE_VOUCHER_SUCCESS:
            const index = state.vouchers.findIndex((voucher) => voucher.id === action.payload.id);
            const updatedVouchers = update(state.vouchers, { $splice: [[index, 1, action.payload]] });
            return {
                ...state,
                voucherUpdatedResult: 'success',
                voucherSelected: action.payload,
                vouchers: updatedVouchers
            }
        case UPDATE_VOUCHER_ERROR:
            return {
                ...state,
                voucherUpdatedResult: 'error'
            }
        case UPDATE_VOUCHER_CLEAR_RESULT:
            return {
                ...state,
                voucherUpdatedResult: null
            }
        default:
            return state;
    }
}

export default state;
