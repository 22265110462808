import {FETCH_ADDRESSES_SUCCESS, INIT_FETCH_ADDRESS} from "../../types/addressTypes";
import {getAddressesByClientIdAsync} from "../../../services/addressServices";

export function fetchShippingAddressesAction(clientId = localStorage.getItem("clientId")) {
    return async (dispatch) => {
        dispatch(initFetch())

        const addresses = await getAddressesByClientIdAsync(clientId);
        if (addresses instanceof Error) {
            console.log("Ver error", addresses);
        } else {
            dispatch(loadAddresses(addresses));
        }
    }
}

const initFetch = () => ({
    type: INIT_FETCH_ADDRESS
});

const loadAddresses = addresses => ({
    type: FETCH_ADDRESSES_SUCCESS,
    payload: addresses
});
