import {useDispatch, useSelector} from "react-redux";
import {fetchCategoriesAction} from "../redux/actions/category/fetchCategoriesAction";

const useCategory = () => {
    const dispatch = useDispatch();

    const categories = useSelector(state => state['categoryReducer'].categories);
    const categorySelected = useSelector(state => state['categoryReducer'].categorySelected);
    const loadingCategories = useSelector(state => state["categoryReducer"].loading);

    const fetchCategories = () => {
        dispatch(fetchCategoriesAction());
    };

    return {
        categories,
        categorySelected,
        loadingCategories,

        fetchCategories,
    }
}

export default useCategory;
