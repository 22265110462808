import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
/*
const firebaseAdmin = firebase.initializeApp(
    {
            apiKey: "AIzaSyD8oTmHnv84eAKKyEuxtMA2My9eHRqdopY",
            authDomain: "mundoguanteapp.firebaseapp.com",
            projectId: "mundoguanteapp",
            storageBucket: "mundoguanteapp.appspot.com",
            messagingSenderId: "749751757042",
            appId: "1:749751757042:web:01e18cf439b744719ec86b",
            measurementId: "G-ML4JYXG53R"
    }
);*/
const firebaseAdmin = firebase.initializeApp(
    {
    apiKey: "AIzaSyAcS5v2n-Y8IzYeXEmlD0jZUYldb-OIfWM",
    authDomain: "manzano-shop.firebaseapp.com",
    projectId: "manzano-shop",
    storageBucket: "manzano-shop.appspot.com",
    messagingSenderId: "95955311927",
    appId: "1:95955311927:web:bb29af62d7aceacb088eb2"
  }
  );

export default firebaseAdmin.auth();
