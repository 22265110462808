import React, {useEffect} from 'react';
import CreditCardsList from "./CreditCardsList";
import {CircularProgress, Drawer} from "@material-ui/core";
import DrawerHeader from '../cart/DrawerHeader'
import CreditCardEditor from './CreditCardEditor'
import {Elements} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import useAccount from "../../../hooks/useAccount";
import useCreditCart from "../../../hooks/useCreditCart";

const stripe = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const CreditCardSelector = () => {
    const { accountOpen } = useAccount();
    const { creditCardSelectorOpened, loadingCreditCards, initCards, onCloseCreditCardSelector } = useCreditCart();

    useEffect(() => {
        if (creditCardSelectorOpened) {
            initCards();
        }
        // eslint-disable-next-line
    }, [creditCardSelectorOpened]);

    return (

        <React.Fragment >
            <Drawer anchor={"right"} open={creditCardSelectorOpened} onClose={onCloseCreditCardSelector} >
                  <DrawerHeader title={accountOpen ? "Tus tarjetas":"Elige una tarjeta"} onHandleClose={onCloseCreditCardSelector}/>
                  {
              <CreditCardsList />
            }
           </Drawer>
            <Elements stripe={stripe}>
                <CreditCardEditor />
            </Elements>
        </React.Fragment>
    );
}



export default CreditCardSelector;
