import {CHANGE_ORDERS_HISTORY_VISIBILITY} from "../../types/orderTypes";

export function changeOrdersHistoryVisibilityAction(visibility) {
    return (dispatch) => {
        dispatch(changeVisibility(visibility));
    }
}

const changeVisibility = visibility => ({
    type: CHANGE_ORDERS_HISTORY_VISIBILITY,
    payload: visibility
})
