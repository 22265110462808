import React, { useEffect } from 'react';
import SelectShippingAddressList from "./SelectShippingAddressList";
import { useSelector } from "react-redux";
import { CircularProgress, Drawer } from "@material-ui/core";
import DrawerHeader from '../cart/DrawerHeader'
import EditAddressDrawer from '../address/EditAddressDrawer'
import CreateAddressDrawer from '../address/CreateAddressDrawer'
import { Fragment } from 'react';
import useAccount from "../../../hooks/useAccount";
import useAddress from "../../../hooks/useAddress";

const SelectAddressDrawer = () => {
    const { accountOpen } = useAccount();
    const { addresses, addressSelectorOpened, loadingAddresses, initAddresses, onAddressSelected, onCloseSelectAddress, onClickCreateNewAddress } = useAddress();

    const createAddressSuccess = useSelector(state => state["addressReducer"].createAddressSuccess);

    useEffect(() => {
        if (addressSelectorOpened) {
            initAddresses();
        }
        // eslint-disable-next-line
    }, [addressSelectorOpened]);

    
    // useEffect(() => {
    //     if (loadingAddresses == false && addressSelectorOpened === true && addresses.length === 0) {
    //         onClickCreateNewAddress(false)
    //     }
    // }, [loadingAddresses])

    useEffect(() => {
        if (addressSelectorOpened && createAddressSuccess && !accountOpen) {
            //Esto lo ahcemos para que cuando se esté creando una dirección durante el proceso de pedido, cerremos el selector para dejar visible el propio pedido
            onCloseSelectAddress();
        }
        // eslint-disable-next-line
    }, [createAddressSuccess]);

    return (

        <Fragment>
            <Drawer anchor={"right"} open={addressSelectorOpened} onClose={onCloseSelectAddress}>
                <DrawerHeader
                    title={accountOpen ? "Tus direcciones" : "Elige una dirección"}
                    onHandleClose={onCloseSelectAddress} />
                {
                    loadingAddresses ? <CircularProgress /> :
                        <SelectShippingAddressList
                            onAddressSelected={onAddressSelected}
                            forEditing={accountOpen} />
                }
            </Drawer>
            <EditAddressDrawer />
            <CreateAddressDrawer />
        </Fragment>
    );
}


export default SelectAddressDrawer;
