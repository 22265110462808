import { createStyles, makeStyles } from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from "@material-ui/styles";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import PagesContainer from "./views/pages/PagesContainer";
import theme from './views/ui/Theme.js';

const mainStyles = makeStyles(() => createStyles({
    '@global': {
        '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
        },
        html: {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            height: '100%',
            width: '100%'
        },
        body: {
            height: '100%',
            width: '100%'
        },
        '#root': {
            height: '100%',
            width: '100%'
        }
    }
}));

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto'
    },

}));

function App() {
    const classes = useStyles();

    mainStyles();
window.location.href = "https://hermanosmanzano.com";
    return (
        <BrowserRouter>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <div className={classes.root}>
                        <div className={classes.wrapper}>
                            <div className={classes.contentContainer}>
                                <PagesContainer/>
                            </div>
                        </div>
                    </div>
                </ThemeProvider>
            </Provider>

        </BrowserRouter>
    );
}

export default App;
