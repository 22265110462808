import {CLEAR_CART} from "../../types/cartTypes";

export function clearCartAction() {
    return (dispatch) => {
        dispatch(clearCart());
    }
}

const clearCart = () => ({
   type: CLEAR_CART
});
