import {
    CHANGE_PAYMENT_METHOD_SELECTED,
    CHANGE_PAYMENT_METHOD_SELECTOR_VISIBILITY,
    FETCH_PAYMENT_METHODS_SUCCESS,
    INIT_CREATE_PAYMENT_METHOD, INIT_FETCH_PAYMENT_METHOD,
    PAYMENT_METHOD_CREATED_SUCCESS
} from "../types/paymentMethodTypes";

const initialState = {
    paymentMethods: [],
    paymentMethodEditorOpened: false,
    paymentMethodSelectorOpened: false,
    loading: false,
    paymentMethodSelected: null
}

const state = (state = initialState, action) => {
    switch (action.type) {

        case PAYMENT_METHOD_CREATED_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentMethods: [...state.paymentMethods, action.payload],
                paymentMethodSelected: action.payload,
                paymentMethodEditorOpened: false
            }

        case INIT_FETCH_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethods: [],
                loading: true
            }

        case FETCH_PAYMENT_METHODS_SUCCESS:
            return {
                ...state,
                paymentMethods: action.payload,
                loading: false
            }

        case INIT_CREATE_PAYMENT_METHOD:
            return {
                ...state,
                loading: true
            }

        case CHANGE_PAYMENT_METHOD_SELECTOR_VISIBILITY:
            return {
                ...state,
                paymentMethodSelectorOpened: action.payload
            }

        case CHANGE_PAYMENT_METHOD_SELECTED:
            return {
                ...state,
                paymentMethodSelected: action.payload,
                paymentMethodSelectorOpened: false
            }

        default:
            return state;
    }
}

export default state;
