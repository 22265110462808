import {CHANGE_SHIPPING_CREDIT_CARD_SELECTED} from "../../types/creditCardTypes";

export function changeCreditCardSelectedAction(creditCard) {
    return (dispatch) => {
        dispatch(changeShippingAddressSelected(creditCard));
    }
}

const changeShippingAddressSelected = creditCard => ({
    type: CHANGE_SHIPPING_CREDIT_CARD_SELECTED,
    payload: creditCard
});
