import {LOGOUT} from "../../types/authTypes";
import {LOAD_CLIENT_SUCCESS} from "../../types/clientTypes";

export function logoutUserAction() {
    return (dispatch) => {
        localStorage.removeItem('clientId');
        dispatch(logout());
        dispatch(loadClient(null))
    }
}

const logout = () => ({
   type: LOGOUT
});
const loadClient = (client) => ({
    type: LOAD_CLIENT_SUCCESS,
    payload: client
});