import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {IconButton, Typography,} from '@material-ui/core/';
import {ChevronLeft as BackIcon} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    topbar: {
        zIndex: 100,
        boxShadow: "0 8px 24px 0 rgba(37, 36, 47, .06), inset 0 -1px 0 0 #e5e5e5",
        display: "flex",
        height: 56,
        minHeight: 56,
        paddingLeft: 5,

        backgroundColor: "white"
    },
    title: {
        flexGrow: 100,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column"
    },
    backButton: {
        padding: '1rem',
        position: "absolute",
        top: 0,
        left: 0,
        margin: 0
    },
}));

const DrawerHeader = (props) => {
    const classes = useStyles();
    const {onHandleClose, title} = props;

    const onClickCloseButton = () => {
        onHandleClose()
    };

    return (
        <header className={classes.topbar}>
            <IconButton edge="start" className={classes.backButton} aria-label="atras"
                        onClick={onClickCloseButton}>
                <BackIcon/>
            </IconButton>
            <Typography align="center" variant="h6" className={classes.title}>
                {title}
            </Typography>


        </header>)
};

export default DrawerHeader;
