import {CHANGE_CREDIT_CARD_EDITOR_VISIBILITY} from "../../types/creditCardTypes";

export function changeCreditCardEditorVisibilityAction(visibility) {
    return (dispatch) => {
        dispatch(changeVisibility(visibility));
    }
}

const changeVisibility = visibility => ({
    type: CHANGE_CREDIT_CARD_EDITOR_VISIBILITY,
    payload: visibility
});
