import React from 'react';
import {Badge, Box, Grid, makeStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import useDetail from "../../../hooks/useDetail";

const useStyles = makeStyles(() => ({
    optionCard: {
        marginTop: "15px",
        display: 'flex'
    },
    parent: {
        marginTop: "32px",
        marginBottom: "32px"
    },
    contentBox: {
        display: 'flex',
      flexDirection: 'row'  
    },
    actionBox: {
         display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      alignItems: "center"
    },
    media: {
        height: 120,
        width: '30%'
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'alpha'
    },
    content: {
        overflow: 'hidden',
        width: '100%',
        padding: 16,
        paddingBottom: 0,
        height: 120,
        zIndex: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
    },
    description: {
        listStylePosition: "inside",
        overflow: "hidden"
    },
    actions: {
        display: "flex",
        justifyContent: "space-between",
        zIndex: 1,
        minHeight: 40,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 12
    }
}));

const OptionCell = ({option, onClickAddOption}) => {
    const classes = useStyles();

    
   

    const AddToCartButton = () => (
        <Box sx={{width: '50%', textAlign: "end"}}>
            {
                 <Badge badgeContent={option.quantity} color="secondary">
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={()=>onClickAddOption(option)}
                    >{option.quantity > 0 ? "EDITAR" : "AÑADIR"}
      
                           
                       
      </Button> </Badge>
               /* <Button size="small" style={{minWidth: 0}} aria-label="Añadir a la bolsa" color="secondary"
                        onClick={() => ""}>
                    {<Badge badgeContent={option.quantity} color="secondary">
                            
                        </Badge>
                    }
                </Button>*/

            }
        </Box>
    );

    return (
        <Grid item={true} xs="12">
            <Card className={classes.optionCard}>
              
                
                    <CardMedia
                        className={classes.media}
                        image={option.photoUrl}
                        title={option.name}
                    />
                    <Box className={classes.content}>
                        <Typography gutterBottom variant="h6" component="h6">
                            {
                                <div className={classes.title}>{option.name}</div>
                            }
                    </Typography>
                    <Box className={classes.actionBox}>
                    <Typography variant={"h6"} justify={"right"}>
                        {
                         (option.value * 1.10).toFixed(2)
                        } €/ud

                    </Typography>

                        <AddToCartButton />
                        </Box>
                        {/*<Typography className={classes.description} variant="body2" color="textSecondary"
                            component="div">
                            {
                                <div dangerouslySetInnerHTML={{ __html: option.description }} />
                            }
                        </Typography>*/}
                    </Box>
              
            </Card>
        </Grid>

    );
}

const DetailOptions = ({ options }) => {
     const { onClickOption, onChangeInvalidOptionVisibility } = useDetail();
    const classes = useStyles();

    const onClickAddOption = (option) => {
        //SI la opción es embalaje en caja, miramos si hay ya metido algún libro
        if (option.name.toLowerCase().includes("caja")) {
            if (options.find(option => option.name.toLowerCase().includes("libro") && option.quantity > 0)) {
                onChangeInvalidOptionVisibility(true)
                return;
            } 
            
        }
         //SI la opción es libro, miramos a ver si esta metido el embalaje en caja
        if (option.name.toLowerCase().includes("libro")) {
            if (options.find(option => option.name.toLowerCase().includes("caja") && option.quantity > 0)) {
                onChangeInvalidOptionVisibility(true)
                return;
          }
        } 
        onClickOption(option)
    }


    if (!options || options.length == 0) return <div style={{ "paddingTop": "20px" }}></div>;


    
    return (
        <Box className={classes.parent}>
            <Typography  variant="h5">
                Completa tu pedido
            </Typography>
        <Grid container>
            {
                options.map((option) => {
                    return <OptionCell key={option.name} option={option} onClickAddOption={onClickAddOption} />
                })
            }
            </Grid>
            </Box>
    )
}

export default DetailOptions;
