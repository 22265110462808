import {CLEAN_ORDER_PAYMENT_INFO, ORDER_PAYMENT_ERROR, ORDER_PAYMENT_SUCCESS} from "../../types/orderTypes";

export function notifyOrderPaymentAction(type, message) {
    return (dispatch) => {
        switch (type) {
            case 'success':
                dispatch(successPayment());
                break;
            case 'error':
                dispatch(paymentError(message));
                break;
            default:
                dispatch(clearPaymentInfo());
        }
    }
}

const clearPaymentInfo = () => ({
    type: CLEAN_ORDER_PAYMENT_INFO
});

const successPayment = () => ({
   type: ORDER_PAYMENT_SUCCESS
});

const paymentError = message => ({
   type: ORDER_PAYMENT_ERROR,
   payload: message
});
