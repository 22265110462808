import {createClientAsync, getClientStatusByEmailAsync} from "../../../services/clientServices";
import firebaseAdmin from "../../../config/firebaseConfig";
import {LOGIN_SUCCESS} from "../../types/authTypes";
import {IS_REGISTERING,REGISTER_ERROR,REGISTER_SUCCESS} from "../../types/clientTypes";
import {LOAD_CLIENT_SUCCESS} from "../../types/clientTypes";

export function registerClientAction(registerProps) {
    return async (dispatch) => {
        dispatch(changeRegisterStatus());
        const clientStatus = await getClientStatusByEmailAsync(registerProps.email);

        if (clientStatus) {
            // El usuario existe hay que lanzar mensaje de error
            dispatch(changeErrorMessage("Ya existe una cuenta con ese email"));
        } else {
            // Creamos el usuario en Firebase
            const firebaseUserId = await firebaseAdmin.createUserWithEmailAndPassword(registerProps.email, registerProps.password)
                .then(result => result.user.uid).catch(error => {
                    return undefined;
                });

            if (firebaseUserId) {
                // Creamos el usuario en Distributio
                const newUser = await createClientAsync({
                    ...registerProps,
                    from: 'app'
                });

                if (newUser) {
                    localStorage.setItem('clientId', newUser.id);
                    dispatch(changeAuthStatus());
                    dispatch(changeRegisterResult());
                    dispatch(loadClient(newUser))
                }
            } else {
                // Lanzamos error de que no se ha podido crear el usuario en Firebase
                dispatch(changeErrorMessage("No se pudo crear la cuenta"));
            }
        }
    }
}

const changeRegisterResult = () => ({
   type: REGISTER_SUCCESS
});
const changeAuthStatus = () => ({
    type: LOGIN_SUCCESS
 });
const changeRegisterStatus = () => ({
    type: IS_REGISTERING
});
const changeErrorMessage = (errorMessage) => ({
    type: REGISTER_ERROR,
    payload: errorMessage
});
const loadClient = (client) => ({
    type: LOAD_CLIENT_SUCCESS,
    payload: client
});
