import {CHANGE_DELIVERY_DATE_SELECTOR_VISIBILITY} from "../../types/deliveryDateTypes";

export function changeDeliveryDateSelectorVisibilityAction(visibility) {
    return (dispatch) => {
        dispatch(changeVisibility(visibility));
    }
}

const changeVisibility = visibility => ({
    type: CHANGE_DELIVERY_DATE_SELECTOR_VISIBILITY,
    payload: visibility
})
