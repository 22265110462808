import {FETCH_NOTIFICATIONS, NOTIFICATIONS_ERROR, NOTIFICATIONS_FETCHED_SUCCESS} from "../../types/notificationTypes";
import {fetchNotificationsAsync} from "../../../services/notificationServices";

export function fetchNotificationsAction() {
    return async (dispatch) => {
        dispatch(initFetchNotifications());

        const notificationResponse = await fetchNotificationsAsync();

        if (notificationResponse instanceof Error) {
            dispatch(notificationsError(notificationResponse));
        } else {
            dispatch(notificationsFetchedSuccess(notificationResponse));
        }
    }
}

const initFetchNotifications = () => ({
    type: FETCH_NOTIFICATIONS
});

const notificationsFetchedSuccess = (notifications) => ({
   type: NOTIFICATIONS_FETCHED_SUCCESS,
   payload: notifications
});

const notificationsError = (errorMessage) => ({
    type: NOTIFICATIONS_ERROR,
    payload: errorMessage
});