import {ADD_DETAIL_TO_CART} from "../../types/cartTypes";

export function addDetailToCartAction(detail) {
    if (detail.quantity === 0) detail.addQuantity(1);
    return (dispatch) => {
        dispatch(addDetailToCart(detail))
    }
}

const addDetailToCart = detail => ({
    type: ADD_DETAIL_TO_CART,
    payload: detail
});
