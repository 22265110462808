import {CHANGE_ADDRESS_SELECTOR_VISIBILITY} from "../../types/addressTypes";

export function changeAddressSelectorVisibilityAction(visibility) {
    return (dispatch) => {
        dispatch(changeVisibility(visibility));
    }
}

const changeVisibility = visibility => ({
    type: CHANGE_ADDRESS_SELECTOR_VISIBILITY,
    payload: visibility
})
