import {
    CATEGORIES_ERROR,
    CATEGORIES_FETCHED_SUCCESS,
    FETCH_CATEGORIES,
    FETCH_CATEGORY_BY_ID, FETCH_CATEGORY_BY_ID_SUCCESS, SELECT_CATEGORY
} from "../types/categoryTypes";

const initialState = {
    categories: [],
    categorySelected: undefined,
    errorMessage: undefined,
    loading: false
};

const state = (state = initialState, action) => {

    switch (action.type) {

        case CATEGORIES_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            };

        case CATEGORIES_FETCHED_SUCCESS:
            return {
                ...state,
                categories: action.payload,
                loading: false
            }

        case FETCH_CATEGORIES:
            return {
                ...state,
                loading: true,
                errorMessage: null,
                categorySelected: null
            };

        case FETCH_CATEGORY_BY_ID:
            return {
                ...state,
                errorMessage: null,
                categorySelected: null
            };

        case FETCH_CATEGORY_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                categorySelected: action.payload
            };

        case SELECT_CATEGORY:
            return {
                ...state,
                categorySelected: action.payload
            }

        default:
            return state;
    }
}

export default state;