import {OPTION_SELECTED} from "../../types/detailTypes";

export function changeOptionSelectedAction(option) {
    return (dispatch) => {
        dispatch(changeOptionSelected(option))
    }
}

const changeOptionSelected = (option) => ({
    type: OPTION_SELECTED,
    payload: option
});