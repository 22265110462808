import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Add as AddIcon,
    CreditCard as CreditCardIcon,
    DeleteOutlined as DeleteIcon,
    Check as SelectedIcon
} from "@material-ui/icons"
import useAccount from "../../../hooks/useAccount";
import useCreditCart from "../../../hooks/useCreditCart";
import drawerStyles from "../../ui/DrawerStyles";
import { CircularProgress, Drawer } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    creditCardItem: {
        paddingLeft: 15,
        paddingRight: 0,
        alignItems: "center",
        justifyItems: "center",
        background: "white",
        borderBottom: "1px solid #f5f5f5"
    },
}));

const CreditCardCell = ({ creditCard, creditCardSelected }) => {
    const classes = useStyles();

    const { accountOpen } = useAccount();
    const { onClickCreditCard } = useCreditCart();

    if (!creditCard) {
        return;
    }

    return (
        <ListItem alignItems="flex-start" className={classes.creditCardItem}
            onClick={() => onClickCreditCard(creditCard)}>
            <ListItemIcon>
                <CreditCardIcon />
            </ListItemIcon>
            <ListItemText
                primary={creditCard.brand.toUpperCase() + " ****" + creditCard.lastCardNumbers}
                secondaryTypographyProps={{ component: 'span' }}
                secondary={
                    <div>
                        <div>{"Válida hasta: " + creditCard.expMonth + "/" + creditCard.expYear}</div>
                        <div>{creditCard.ownerName?.toUpperCase()}</div>
                    </div>
                }
                styles={{ textAlign: "center" }}
            />

            {
                accountOpen ?
                    <ListItemIcon>
                        <IconButton edge="end" aria-label="comments">
                            <DeleteIcon />
                        </IconButton>
                    </ListItemIcon>
                    :
                    ((creditCardSelected && creditCardSelected.id === creditCard.id) ?
                        <ListItemIcon>
                            <IconButton edge="end" aria-label="comments">
                                <SelectedIcon />
                            </IconButton>
                        </ListItemIcon> : "")
            }
        </ListItem>
    )
}

const CreditCardsList = () => {
    const drawerClasses = drawerStyles()
    const { creditCards, creditCardSelected, onClickCreateNewCreditCard, loadingCreditCards } = useCreditCart();


    if (loadingCreditCards) {
        return (
            <div className={drawerClasses.loadingContainer}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div className={drawerClasses.container}>
            <List>
                {

                    creditCards.map(creditCard => <CreditCardCell
                        key={creditCard.stripeId} creditCard={creditCard} creditCardSelected={creditCardSelected} />)
                }

                <ListItem key={"listItem"} onClick={onClickCreateNewCreditCard}>
                    <ListItemIcon>
                        <AddIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={`AÑADIR UNA NUEVA TARJETA`}
                    />
                </ListItem>
            </List>
        </div>
    );

}

export default CreditCardsList;
