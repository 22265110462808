import {FETCH_FEATURED_PRODUCTS_SUCCESS, FETCH_PRODUCTS, FETCH_PRODUCTS_ERROR} from "../../types/productTypes";
import {fetchFeaturedProductsAsync} from "../../../services/productServices";

export function fetchFeaturedProductsAction() {
    return async (dispatch) => {
        dispatch(initFetchFeaturedProducts());

        const products = await fetchFeaturedProductsAsync();
        
        if (products instanceof Error) {
            dispatch(fetchFeaturedProductsError(products));
        } else {
            dispatch(fetchFeaturedProductsSuccess(products));
        }

    }
}

const initFetchFeaturedProducts = () => ({
    type: FETCH_PRODUCTS
});

const fetchFeaturedProductsSuccess = featuredProducts => ({
    type: FETCH_FEATURED_PRODUCTS_SUCCESS,
    payload: featuredProducts
});

const fetchFeaturedProductsError = errorMessage => ({
    type: FETCH_PRODUCTS_ERROR,
    payload: errorMessage
});