import { makeStyles } from "@material-ui/core/styles";

const drawerStyles = makeStyles(() => ({
    paper: {
        background: "#F5F5F5",
        minWidth: "calc(min(100vw, 375px))",
        maxWidth: "calc(min(100vw, 375px))",
        overflow: 'unset'
    },
    emptyCart: {
        flexGrow: 50,
        padding: 20,
        minWidth: "calc(min(100vw, 375px))",
        maxWidth: "calc(min(100vw, 375px))",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column"
    },
    continueButtonQuantity: {
        backgroundColor: "#CCC7",
        borderRadius: ".375rem",
        color: "#FFF",
        webKitBoxFlex: "0",
        webKitFlex: "0 0 2rem",
        fontSize: "1.125rem",
        lineHeight: "1.78",
        flex: 0.1
    },
    continueButtonLabel: {
        flex: 1
    },
    continueButtonImport: {
        flex: 0.1,
    },
    footer: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        padding: '1rem',
        boxShadow: "0 -1px 8px 0 rgba(0,0,0,.1)"
    },
    acceptButton: {
        marginBottom: "1rem",
        backgroundColor: "#4fd053",
        color: "white",
        '&:hover': {
            backgroundColor: '#47ba4a',
        },
        width: "100%"
    },
    container: {
        minWidth: "calc(min(100vw, 375px))",
        maxWidth: "calc(min(100vw, 375px))",
    },
    acceptButtonDisabled: {
        opacity: "0.5",
        backgroundColor: 'lightGray',
        color: "black"
    },
    deleteButton: {
        backgroundColor: "#ff373d",
        color: "white"
    },
    loadingContainer: {
        minWidth: "calc(min(100vw, 375px))",
        maxWidth: "calc(min(100vw, 375px))",
        marginTop: "32px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    }
}));

export default drawerStyles;
