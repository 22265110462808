import React from 'react';
import { Box, Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core/';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Carousel from 'react-gallery-carousel'
import 'react-gallery-carousel/dist/index.css'
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon} from "@material-ui/icons"
import {useTheme} from '@material-ui/core/styles';
import VoucherOrderOptionsDialog from "./VoucherInOrderOptionsDialog"
import ButtonForDownloadVoucher from "../components/voucher/ButtonForDownloadVoucher";
import useVoucher from "../../hooks/useVoucher";

const useStyles = makeStyles(() => ({
    dialogTitle: {
        paddingBottom: 0,
        paddingTop: 0,
        paddingRight: 4
    },
    dialogContent: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        padding: "0px 16px 16px 16px",
    },
    voucherDescription: {
        overflow: "auto",
        marginBottom: "1rem"
    }
}));

const VoucherDetailDialog = () => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { voucherSelected, onCloseVoucherDetail,voucherOptionsOpened } = useVoucher();

    if (!voucherSelected) {
        return null;
    }

    //esto lo pide el componente de carrusel
    const images = voucherSelected.product.photoUrls.map((imageURL) => ({ src: imageURL }));
    return (
        <React.Fragment>
            <Dialog
                onClose={onCloseVoucherDetail}
                aria-labelledby="customized-dialog-title"
                open={true}
                maxWidth={"lg"}
                fullScreen={fullScreen}
                fullWidth>
                <DialogTitle className={classes.dialogTitle} >
                    <Box display="flex" alignItems="center" style={{ padding: 0 }}>
                        <Box flexGrow={1} style={{ padding: 0 }} >{voucherSelected.product.families[0].name}</Box>
                        <Box>
                            <IconButton onClick={onCloseVoucherDetail}><CloseIcon /></IconButton>
                        </Box>
                    </Box>
                </DialogTitle>

                <DialogContent dividers className={classes.dialogContent} >
                <div style={{
                         width: fullScreen ? "100%":"50%",
                    maxHeight: fullScreen ? "50vh":"85vh",
                    minWidth:"350px",
                    paddingTop: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    padding: fullScreen ? 12:24
                    }}>
                        <Carousel
                             images={images}
                            canAutoPlay={false}
                            hasIndexBoard={false}
                            style={{backgroundColor: "#FFFFFF"}}
                            hasThumbnails={!fullScreen}
                            hasDotButtons={'bottom'}
                            hasRightButton={false}
                            hasLeftButton={false}
                        />
                    </div>
                    <div style={{
                        width: fullScreen ? "100%" : "50%",
                        minWidth: "350px",
                        padding: 16,
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <Typography variant={"h4"}>
                            {
                                voucherSelected.product.name
                            }
                        </Typography>

                        <Typography component={'div'} variant={"body1"} className={classes.voucherDescription}>
                            {
                                <div dangerouslySetInnerHTML={{__html: voucherSelected.product.description}}/>
                            }
                        </Typography>
                        <ButtonForDownloadVoucher />
                    </div>
                </DialogContent>
            </Dialog>
            <VoucherOrderOptionsDialog open={voucherOptionsOpened} onHandleClose={onCloseVoucherDetail} voucher={voucherSelected} />
        </React.Fragment>
    );
}

export default VoucherDetailDialog;
