import {makeStyles} from "@material-ui/core";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import React from "react";
import {Route, Switch} from "react-router-dom";
import AccountDrawer from "../components/account/AccountDrawer";
import CreateAddressDrawer from "../components/address/CreateAddressDrawer";
import SelectAddressDrawer from "../components/address/SelectAddressDrawer";
import LoginDrawer from "../components/auth/LoginDrawer";
import RegisterDrawer from "../components/auth/RegisterDrawer";
import CartDrawer from "../components/cart/CartDrawer";
import ShoppingInfoComponent from "../components/cart/ShoppingInfoComponent";
import CreditCardSelector from "../components/credit_card/CreditCardSelector";
import SelectDeliveryDateDrawer from "../components/delivery_date/SelectDeliveryDateDrawer";
import OrdersHistoryDrawer from "../components/order/OrdersHistoryDrawer";
import SelectPaymentMethodDrawer from "../components/payment_method/SelectPaymentMethodDrawer";
import SelectShippingMethodDrawer from "../components/shipping_method/SelectShippingMethodDrawer";
import TopBar from "../components/TopBar";
import VouchersHistoryDrawer from "../components/voucher/VouchersHistoryDrawer";
import DetailDialog from "../dialogs/DetailDialog/DetailDialog";
import DetailOptionDialog from "../dialogs/DetailDialog/DetailOptionDialog";
import VoucherDetailDialog from "../dialogs/VoucherDetailDialog";
import VoucherOptionsDialog from "../dialogs/VoucherOptionsDialog";
import ProductOptionsDialog from "../dialogs/ProductOptionsDialog";
import DetailsPage from "./DetailsPage";
import MainPage from "./MainPage";
import ProductsPage from "./ProductsPage";
import Footer from '../components/Footer'
import AvisoLegalDialog from '../dialogs/AvisoLegalDialog'
import PrivacyPolicyDialog from '../dialogs/PrivacyPolicyDialog'
import CookiePolicyDialog from '../dialogs/CookiePolicyDialog'
import RSSPolicyDialog from '../dialogs/RSSPolicyDialog'
import ShopConditionsDialog from '../dialogs/ShopConditionsDialog'
import NewsletterDialog from '../dialogs/NewsletterDialog'
import IncompatibleOptionDialog from "../dialogs/DetailDialog/IncompatibleOptionDialog";

const useStyles = makeStyles({
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
        backgroundColor: '#f4f4f4',
    },
    allButFooter: {
        minHeight: "calc(100vh - 329px)",
        backgroundColor: '#f4f4f4',
    }
});

const stripe = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const PagesContainer = () => {
    const classes = useStyles();
   

    return (
        <div className={classes.content}>
            <div className={classes.allButFooter}>
                <TopBar open/>

                <Switch>
                    <Route exact path={"/"} component={MainPage}/>
                    <Route exact path={"/categories/:category_id/products"} component={ProductsPage}/>
                    <Route exact path={"/categories/:category_id/details"} component={DetailsPage}/>
                </Switch>

                <div>
                    <AccountDrawer/>
                    <CartDrawer/>
                    <CreateAddressDrawer/>
                    <LoginDrawer/>
                    <OrdersHistoryDrawer/>
                    <RegisterDrawer/>
                    <SelectAddressDrawer/>
                    <SelectDeliveryDateDrawer/>
                    <SelectPaymentMethodDrawer/>
                    <SelectShippingMethodDrawer/>
                    <VouchersHistoryDrawer/>


                    <DetailDialog />
                    <DetailOptionDialog/>
                    <VoucherDetailDialog/>
                    <AvisoLegalDialog/>
                    <PrivacyPolicyDialog/>
                    <CookiePolicyDialog/>
                    <RSSPolicyDialog/>
                    <ShopConditionsDialog/>
                    <NewsletterDialog/>
                    <VoucherOptionsDialog/>
                    <ProductOptionsDialog/>
<IncompatibleOptionDialog/>
                    
                    <CreditCardSelector/>
                    <Elements stripe={stripe}>
                        <ShoppingInfoComponent/>
                    </Elements>
                    

                </div>
            </div>
            <Footer/>

        </div>
    )
}

export default PagesContainer;
