import {INIT_ORDERS_HISTORY, UPDATE_ORDERS_HISTORY} from "../../types/orderTypes";
import {getOrdersByClientIdAsync} from "../../../services/orderServices";

export function fetchOrdersHistoryAction() {
    return async (dispatch) => {
        dispatch(initFetchOrders());

        const orders = await getOrdersByClientIdAsync(localStorage.getItem('clientId'));

        dispatch(refreshOrdersHistory(orders))
    }
}

const initFetchOrders = () => ({
   type: INIT_ORDERS_HISTORY
});

const refreshOrdersHistory = orders => ({
   type: UPDATE_ORDERS_HISTORY,
   payload: orders ?? []
});
