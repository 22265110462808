export const INIT_CREATE_ORDER = 'INIT_CREATE_ORDER';
export const CREATE_ORDER_ERROR = 'CREATE_ORDER_ERROR';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';

export const ORDER_PAYMENT_SUCCESS = 'ORDER_PAYMENT_SUCCESS';
export const ORDER_PAYMENT_ERROR = 'ORDER_PAYMENT_ERROR';
export const CLEAN_ORDER_PAYMENT_INFO = 'CLEAN_ORDER_PAYMENT_INFO';

export const INIT_ORDERS_HISTORY = 'INIT_ORDERS_HISTORY';
export const UPDATE_ORDERS_HISTORY = 'UPDATE_ORDERS_HISTORY';

export const CHANGE_ORDER_SELECTED = 'CHANGE_ORDER_SELECTED';

export const CHANGE_ORDERS_HISTORY_VISIBILITY = 'CHANGE_ORDERS_HISTORY_VISIBILITY';
