import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import {useEffect, useRef, useState} from 'react';
import 'react-gallery-carousel/dist/index.css'
import {makeStyles} from '@material-ui/core/styles';
import {Close as CloseIcon} from "@material-ui/icons"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import useDetail from "../../../hooks/useDetail";
import ButtonForVoucher from "../../components/voucher/ButtonForVoucher";
import ButtonForDetail from "../../components/detail/ButtonForDetail";
import {Box, Dialog, DialogTitle, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import YoutubePlayer from "./YoutubePlayer"
import DescriptionBlocks from "./DescriptionBlocks"
import DetailOptions from "./DetailOptions"
import Lightbox from "react-awesome-lightbox";
// Required stylesheet
import "react-awesome-lightbox/build/style.css";



function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const useStyles = makeStyles(() => ({
    closeButton: {
        position: "absolute",
        right: "0px",
        top: "0px"
    },
    detailDescription: {
        overflow: "auto",
        marginBottom: "1rem"
    },
    dialogTitle: {
        paddingBottom: 0,
        paddingTop: 0,
        paddingRight: 4
    },
    dialogContent: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        padding: "0px 16px 16px 16px",
    },

}));

const DetailDialog = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const classes = useStyles();

    const { detailSelected, onCloseDetail } = useDetail();

    if (!detailSelected) {
        return null;
    }

    //esto lo pide el componente de carrusel
    //const images = detailSelected.price.product.photoUrls.map((imageURL) => ({src: imageURL}));
    const images =  detailSelected.price.product.photoUrls.map((imageURL) =>imageURL);
    //const images = () => detailSelected.price.product.photoUrls;
    const voucherInfo = detailSelected.price.product.voucherInfo;

    return (
        <React.Fragment>
            <Dialog
                onClose={onCloseDetail}
                aria-labelledby="customized-dialog-title"
                open={true}
                maxWidth={"lg"}
                fullScreen={fullScreen}
                fullWidth>

                <DialogTitle className={classes.dialogTitle}>
                    <Box display="flex" alignItems="center" style={{padding: 0}}>
                        <Box flexGrow={1} style={{padding: 0}}>{detailSelected.price.product.families[0].name}</Box>
                        <Box>
                            <IconButton onClick={onCloseDetail}><CloseIcon/></IconButton>
                        </Box>
                    </Box>
                </DialogTitle>

                <DialogContent dividers className={classes.dialogContent}>
                <div style={{ 
                        width: fullScreen ? "100%" : "50%",
                    padding: "16px"
                  
                    }}>
                        {
                            images ?
                       <ImageList
                            sx={{ width: fullScreen ? "100%":"50%" }}
                            cols={4}
                            rowHeight={121}
                        >
                            {images.slice(0,5).map((image,index) => (
                                
                            <ImageListItem key={image} rows={index == 0 ? 4:2} cols={index == 0 ? 4:2}>
                                <img
                                        {...srcset(image, 121, 121, index == 0 ? 4:2, index == 0 ? 4:2)}
                                />
                            </ImageListItem>
                             ))}
                            </ImageList>
                            : ""}
</div>
                     

                    <div style={{
                        width: fullScreen ? "100%" : "50%",
                        minWidth: "350px",
                        padding: 16,
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <Typography variant={"h4"}>
                            {
                                detailSelected.price.product.name
                            }
                        </Typography>
                        <Typography variant={"h4"}
                                    style={{"fontWeight": 'bold', "fontSize": "30px", "marginBottom": "8px"}}>
                            {
                                (voucherInfo && voucherInfo.type === "money")
                                    ? "Desde " + voucherInfo.minUnits
                                    : detailSelected.getUnitaryPrice().toFixed(2)
                            } €
                        </Typography>
                        <Typography component={'div'} variant={"body1"} className={classes.detailDescription}>
                            {
                                <div dangerouslySetInnerHTML={{__html: detailSelected.price.product.description}}/>
                            }
                        </Typography>
                        <DescriptionBlocks />
                        {  detailSelected.price.product.videoUrl ?
                            <YoutubePlayer videoId={detailSelected.price.product.videoUrl} title={"Video de la preparación"} pdfUrl={detailSelected.price.product.pdfUrl}></YoutubePlayer> :""
                        }
                        <DetailOptions options={detailSelected.price.product.options}/>
                        
                       
                        {
                            detailSelected.price.product.voucherInfo
                                ? <ButtonForVoucher />
                                : <ButtonForDetail />
                        }
                    </div>
                </DialogContent>

            </Dialog>
        </React.Fragment>
    );
}







export default DetailDialog;
