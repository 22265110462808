import {useDispatch, useSelector} from "react-redux";
import {changePaymentMethodSelectorVisibilityAction} from "../redux/actions/payment_method/changePaymentMethodSelectorVisibilityAction";
import {fetchPaymentMethodsAction} from "../redux/actions/payment_method/fetchPaymentMethodsAction";
import {changePaymentMethodSelectedAction} from "../redux/actions/payment_method/changePaymentMethodSelectedAction";
import {changeCreditCardSelectorVisibilityAction} from "../redux/actions/credit_card/changeCreditCardSelectorVisibilityAction";

const usePaymentMethod = () => {
    const dispatch = useDispatch();


    const loadingPaymentMethods = useSelector(state => state["paymentMethodReducer"].loading);
    const paymentMethods = useSelector(state => state["paymentMethodReducer"].paymentMethods);
    const paymentMethodSelected = useSelector(state => state["paymentMethodReducer"].paymentMethodSelected);
    const paymentMethodSelectorOpened = useSelector(state => state["paymentMethodReducer"].paymentMethodSelectorOpened);

    const initPaymentMethods = () => {
        dispatch(fetchPaymentMethodsAction());
    }

    const onClickSelectPaymentMethod = () => {
        if (paymentMethods.length === 1 && paymentMethods[0].creditCardRequired) {
            dispatch(changePaymentMethodSelectedAction(paymentMethods[0]));
            dispatch(changeCreditCardSelectorVisibilityAction(true));
        } else if (paymentMethods.length > 1) {
            dispatch(changePaymentMethodSelectorVisibilityAction(true));
        } else {
            dispatch(changePaymentMethodSelectedAction(paymentMethods[0]));
        }
    }

    const onCloseSelectPaymentMethod = () => {
        dispatch(changePaymentMethodSelectorVisibilityAction(false));
    }

    return {
        loadingPaymentMethods,
        paymentMethods,
        paymentMethodSelected,
        paymentMethodSelectorOpened,

        initPaymentMethods,

        onClickSelectPaymentMethod,

        onCloseSelectPaymentMethod
    }
}

export default usePaymentMethod;
