import {fetchFeaturedProductsAction} from "../redux/actions/product/fetchFeaturedProductsAction";
import {useDispatch, useSelector} from "react-redux";
import {fetchProductsAction} from "../redux/actions/product/fetchProductsAction";
import {getProductsByCategoryAction} from "../redux/actions/product/getProductsByCategoryAction";

const useProduct = () => {
    const dispatch = useDispatch();
    const products = useSelector(state => state['productReducer'].products);
    const productsLoading = useSelector(state => state['productReducer'].loading);

    const fetchFeaturedProducts = () => {
        dispatch(fetchFeaturedProductsAction());
    };
    const fetchProducts = () => {
        dispatch(fetchProductsAction());
    };
    const getProductsByCategoryId = (categoryId) => {
        dispatch(getProductsByCategoryAction(categoryId));
    }

    return {
        products,
        productsLoading,

        fetchFeaturedProducts,
        fetchProducts,
        getProductsByCategoryId
    }
}

export default useProduct;
