import {UPDATING_CLIENT, UPDATING_CLIENT_ERROR, UPDATING_CLIENT_SUCCESS} from "../../types/clientTypes";
import {updateClientAsync} from "../../../services/clientServices";

export function updateClientAction({street,city,postalCode,phone,cif,company, name}) {
    return async (dispatch) => {
        dispatch(updatingClient(true));
        const clientId = localStorage.getItem("clientId")
        const updatedClient = await updateClientAsync(clientId,{street, city,phone,cif,company, name, postalCode});
        
        if (updatedClient instanceof Error) {
            dispatch(clientUpdatedError("Error actualizando tud datos"));
        } else {
            dispatch(clientUpdated(updatedClient));
        }
    }
}

const updatingClient = status => ({
    type: UPDATING_CLIENT,
    payload:status
});

const clientUpdated = client => ({
    type: UPDATING_CLIENT_SUCCESS,
    payload: client
});

const clientUpdatedError = error => ({
    type: UPDATING_CLIENT_ERROR,
    payload: error
});