import {SHOP_CONDITIONS_SHOWING} from "../../types/infoTypes.js";

export function changeShopConditionsVisibilityAction(visibility) {
    return (dispatch) => {
        dispatch(changeVisibility(visibility));
    }
}

const changeVisibility = visibility => ({
    type: SHOP_CONDITIONS_SHOWING,
    payload: visibility
});