import React from 'react';
import {Drawer} from "@material-ui/core";
import drawerStyles from "../../ui/DrawerStyles";
import DrawerHeader from '../cart/DrawerHeader'
import LuxonUtils from '@date-io/luxon';
import {DateTime} from 'luxon'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {createTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import Theme from "../../ui/Theme"
import {makeStyles} from '@material-ui/core/styles';
import useShippingMethod from "../../../hooks/useShippingMethod";
import useDeliveryDate from "../../../hooks/useDeliveryDate";


const myStyles = makeStyles(() => ({
    calendarWrapper: {
        justifyContent: "center",
        alignContent: "center",
        display: "flex"
    },actionBar:{
        marginTop:20,
        marginRight:20,
        float:"right",
    }

}));

const palette = Theme.palette;
const materialTheme = createTheme({
    overrides: {
        MuiPickersDay: {
            daySelected: {
                backgroundColor: "#888888",
                color: "#FFFFFF",
                '&:hover': {
                    backgroundColor: "#888888",
                }
            },
            dayDisabled: {
                color: "#cccccc"
            }
            ,
            day: {
                color: "#000000",
                fontWeight: "bold"
            },

        }
    },
    palette
});

const SelectDeliveryDateDrawer = () => {
    const classes = drawerStyles();
    const myClasses = myStyles()

    const { deliveryDateSelectorOpened, onCloseSelectDeliveryDate } = useDeliveryDate();
    const { deliveryDateSelected, shippingMethodSelected, onSelectDeliveryDate } = useShippingMethod();

    const disableDates = ((date) => {

        let preparationDays = shippingMethodSelected.preparationDays;
    
        console.log(DateTime.now().hour)
       if (DateTime.now().hour > shippingMethodSelected.limitHour) {
            preparationDays = preparationDays + 1;
        }

        //si la fecha es inferior a la primera fecha disponible desabilitamos esa fecha
        const firstDateAvailable = DateTime.now().plus({days: preparationDays});
        if (date < firstDateAvailable) return true;

        //comprobamos si la fecha está entre los días de reparto y si no desabilitamos la fecha
        const weekDayName = date.setLocale("en").weekdayLong.toLowerCase();
        return shippingMethodSelected.deliveryDays.includes(weekDayName) === false;

    });
    return (
        <Drawer anchor={"right"} open={deliveryDateSelectorOpened} onClose={onCloseSelectDeliveryDate} >
            <DrawerHeader title="Elige la fecha de entrega" onHandleClose={onCloseSelectDeliveryDate}/>
            <div className={classes.container} style={{backgroundColor: "white"}}>
                <div className={myClasses.calendarWrapper}>
                    <ThemeProvider theme={materialTheme}>
                        <MuiPickersUtilsProvider utils={LuxonUtils} locale={"es"}>
                            <DatePicker variant="static" disablePast openTo="date" value={deliveryDateSelected}
                                        shouldDisableDate={disableDates}
                                        onChange={() => {}}
                                        onAccept={onSelectDeliveryDate}/>
                        </MuiPickersUtilsProvider>
                    </ThemeProvider>
                </div>
                <div className={myClasses.actionBar}>

                    </div>

            </div>
        </Drawer>
    );
}

export default SelectDeliveryDateDrawer;
