import {CHANGE_EDITING_ADDRESS} from "../../types/addressTypes";

export function changeEditingAddressAction(address) {
    return (dispatch) => {
        dispatch(changeEditingAddress(address));
    }
}

const changeEditingAddress = address => ({
    type: CHANGE_EDITING_ADDRESS,
    payload: address
});