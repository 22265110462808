import {
    CHANGE_ORDERS_HISTORY_VISIBILITY, 
    CHANGE_ORDER_SELECTED,
    CLEAN_ORDER_PAYMENT_INFO,
    CREATE_ORDER_ERROR,
    CREATE_ORDER_SUCCESS,
    INIT_CREATE_ORDER,
    INIT_ORDERS_HISTORY,
    ORDER_PAYMENT_ERROR,
    ORDER_PAYMENT_SUCCESS, UPDATE_ORDERS_HISTORY
} from "../types/orderTypes";

const initialState = {
    error: null,
    loading: false,
    newOrder: null,
    ordersHistory: [],
    ordersHistoryOpened: false,
    orderSelected: null,
    paymentAlert: null
}

const state = (state = initialState, action) => {
    switch (action.type) {
        case INIT_CREATE_ORDER:
            return {
                ...state,
                loading: true
            }

        case INIT_ORDERS_HISTORY:
            return {
                ...state,
                ordersHistory: [],
                loading: true
            }

        case CREATE_ORDER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CREATE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                newOrder: action.payload
            }

        case UPDATE_ORDERS_HISTORY:
            return {
                ...state,
                ordersHistory: action.payload,
                loading: false
            }

        case CHANGE_ORDER_SELECTED:
            return {
                ...state,
                orderSelected: action.payload
            }

        case CHANGE_ORDERS_HISTORY_VISIBILITY:
            return {
                ...state,
                ordersHistoryOpened: action.payload
            }

        case ORDER_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentAlert: {
                    status: 'success',
                    message: 'Pedido completado'
                },
                newOrder: null
            }

        case ORDER_PAYMENT_ERROR:
            return {
                ...state,
                loading: false,
                paymentAlert: {
                    status: 'error',
                    message: action.payload
                }
            }

        case CLEAN_ORDER_PAYMENT_INFO:
            return {
                ...state,
                paymentAlert: null
            }
        default:
            return state;
    }
}

export default state;
