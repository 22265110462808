export const CHANGE_AUTH_FORM_VISIBILITY = 'CHANGE_AUTH_FORM_VISIBILITY';
export const CHANGE_REGISTER_DRAWER_VISIBILITY = 'CHANGE_REGISTER_DRAWER_VISIBILITY';
export const IS_LOGGING = 'IS_LOGGING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const RECOVER_PASSWORD_START = 'RECOVER_PASSWORD_START'
export const RECOVER_PASSWORD_COMPLETE = 'RECOVER_PASSWORD_COMPLETE'
export const RECOVER_PASSWORD_ERROR = 'RECOVER_PASSWORD_ERROR'
export const RECOVER_PASSWORD_RESET = 'RECOVER_PASSWORD_RESET'
