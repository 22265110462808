import {FETCH_PAYMENT_METHODS_SUCCESS, INIT_FETCH_PAYMENT_METHOD} from "../../types/paymentMethodTypes";
import {fetchPaymentMethodsAsync} from "../../../services/paymentMethodServices";

export function fetchPaymentMethodsAction() {
    return async (dispatch) => {
        dispatch(initFetch())

        const paymentMethods = await fetchPaymentMethodsAsync();
        if (paymentMethods instanceof Error) {
            console.log("Ver error", paymentMethods);
        } else {
            dispatch(loadPaymentMethods(paymentMethods));
        }
    }
}

const initFetch = () => ({
    type: INIT_FETCH_PAYMENT_METHOD
});

const loadPaymentMethods = paymentMethods => ({
    type: FETCH_PAYMENT_METHODS_SUCCESS,
    payload: paymentMethods
});
