import {CHANGE_SHIPPING_METHOD_SELECTED} from "../../types/shippingMethodTypes";

export function changeShippingMethodSelectedAction(shippingMethod) {
    return (dispatch) => {
        dispatch(changeShippingMethodSelected(shippingMethod));
    }
}

const changeShippingMethodSelected = shippingMethod => ({
    type: CHANGE_SHIPPING_METHOD_SELECTED,
    payload: shippingMethod
});
