export const INIT_FETCH_ADDRESS = 'INIT_FETCH_ADDRESS';
export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESSES_SUCCESS';

export const CHANGE_ADDRESS_SELECTOR_VISIBILITY = 'CHANGE_ADDRESS_SELECTOR_VISIBILITY';
export const CHANGE_CREATE_ADDRESS_VISIBILITY = 'CHANGE_CREATE_ADDRESS_VISIBILITY';
export const CHANGE_EDITING_ADDRESS = 'CHANGE_EDITING_ADDRESS';

export const CHANGE_SHIPPING_ADDRESS_SELECTED = 'CHANGE_SHIPPING_ADDRESS_SELECTED';

export const INIT_CREATE_ADDRESS = 'INIT_CREATE_ADDRESS';
export const ADDRESS_CREATED_SUCCESS = 'ADDRESS_CREATED_SUCCESS';
export const ADDRESS_CREATED_ERROR = 'ADDRESS_CREATED_ERROR';


export const ADDRESS_ERROR = 'ADDRESS_ERROR';

export const INIT_DELETE_ADDRESS = 'INIT_DELETE_ADDRESS';
export const ADDRESS_DELETED_SUCCESS = 'ADDRESS_DELETED_SUCCESS';
export const ADDRESS_DELETED_ERROR = 'ADDRESS_DELETED_ERROR';

export const INIT_UPDATE_ADDRESS = 'INIT_UPDATE_ADDRESS';
export const ADDRESS_UPDATED_SUCCESS = 'ADDRESS_UPDATED_SUCCESS';
export const ADDRESS_UPDATED_ERROR = 'ADDRESS_UPDATED_ERROR';
