import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    body: {
        overflowY: "auto"
    }
}));

const GloriaRssPolicy = () => {
    const classes = useStyles();

    return (
        <div className={classes.body}dangerouslySetInnerHTML={{ __html: `<p class="p1"><span class="s1"><strong>POL&Iacute;TICA PRIVACIDAD REDES SOCIALES</strong></span></p>
<p class="p2">De conformidad con lo establecido en la normativa vigente y aplicable en Protecci&oacute;n de Datos de Car&aacute;cter Personal (RGPD) y la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Informaci&oacute;n y de Comercio Electr&oacute;nico (LSSI-CE), LA FORNELLONA, S.L. informa a los usuarios, que ha procedido a crear un perfil en las Redes Sociales Facebook e Instagram, con la finalidad principal de publicitar sus productos y servicios.&nbsp;&nbsp;</p>
<p class="p2">Datos de la entidad:&nbsp;&nbsp;</p>
<p class="p2">Denominaci&oacute;n social: LA FORNELLONA, S.L.</p>
<p class="p2">C.I.F.: B74256439</p>
<p class="p2">Domicilio social: LA SALGAR S/N 33549 - PARRES (ASTURIAS) <span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">Tel&eacute;fono: 985841525</p>
<p class="p2">Correo electr&oacute;nico a efectos de comunicaci&oacute;n:<span class="Apple-converted-space">&nbsp; </span>comunicacion@casamarcial.com</p>
<p class="p3"><span class="s2">Dominio WEB: </span><span class="s1"><em>manzanoshop.com</em></span><span class="s2"><span class="Apple-converted-space">&nbsp;</span></span></p>
<p class="p4">&nbsp;</p>
<p class="p2">El usuario dispone de un perfil en la misma Red Social y ha decidido unirse a la p&aacute;gina creada por LA FORNELLONA, S.L. mostrando as&iacute; inter&eacute;s en la informaci&oacute;n que se publicite en la Red. Al unirse a nuestra p&aacute;gina, nos facilita su consentimiento para el tratamiento de aquellos datos personales publicados en su perfil.&nbsp;&nbsp;</p>
<p class="p2">El usuario puede acceder en todo momento a las pol&iacute;ticas de privacidad de la propia Red Social, as&iacute; como configurar su perfil para garantizar su privacidad.&nbsp;&nbsp;</p>
<p class="p2">LA FORNELLONA, S.L. tiene acceso y trata aquella informaci&oacute;n p&uacute;blica del usuario, en especial, su nombre de contacto. Estos datos, s&oacute;lo son utilizados dentro de la propia Red Social. No son incorporados a ning&uacute;n sistema de tratamiento.&nbsp;&nbsp;</p>
<p class="p2">En relaci&oacute;n a los derechos de acceso, rectificaci&oacute;n, limitaci&oacute;n de tratamiento, supresi&oacute;n, portabilidad y oposici&oacute;n al tratamiento de sus datos de car&aacute;cter personal de los que usted dispone y que pueden ser ejercitados ante LA FORNELLONA, S.L., de acuerdo con la RGPD, debe tener en cuenta los siguientes matices:&nbsp;</p>
<ul class="ul1">
<li class="li2"><span class="s1">Derecho de Acceso:</span> Es el derecho del usuario a obtener informaci&oacute;n sobre sus datos concretos de car&aacute;cter personal y del tratamiento que se haya realizado o realice, as&iacute; como de la informaci&oacute;n disponible sobre el origen de dichos datos y las comunicaciones realizadas o previstas de los mismos.</li>
<li class="li2"><span class="s1">Derecho de Rectificaci&oacute;n</span>: Es el derecho del afectado a que se modifiquen los datos que resulten ser inexactos o incompletos. S&oacute;lo podr&aacute; satisfacerse en relaci&oacute;n a aquella informaci&oacute;n que se encuentre bajo el control LA FORNELLONA, S.L. por ejemplo, eliminar comentarios publicados en la propia p&aacute;gina, im&aacute;genes o contenidos web donde consten datos de car&aacute;cter personal del usuario.</li>
<li class="li2"><span class="s1">Derecho a la Limitaci&oacute;n de tratamiento</span>: Es el derecho a que se limiten los fines del tratamiento previstos de forma original por el responsable del tratamiento.</li>
<li class="li2"><span class="s1">Derecho de Supresi&oacute;n</span>: Es el derecho a suprimir los datos de car&aacute;cter personal del usuario, a excepci&oacute;n de lo previsto en el propio RGPD o en otras normativas aplicables que determinen la obligatoriedad de la conservaci&oacute;n de los mismos, en tiempo y forma.</li>
<li class="li2"><span class="s1">Derecho de portabilidad</span>: El derecho a recibir los datos personales que el usuario, haya facilitado, en un formato estructurado, de uso com&uacute;n y lectura mec&aacute;nica, y a transmitirlos a otro responsable.</li>
<li class="li2"><span class="s1">Derecho de Oposici&oacute;n</span>: Es el derecho del usuario a que no se lleve a cabo el tratamiento de sus datos de car&aacute;cter personal o se cese el tratamiento de los mismos por parte de LA FORNELLONA, S.L.&nbsp;&nbsp;</li>
</ul>
<p class="p2">LA FORNELLONA, S.L. realizar&aacute; las siguientes actuaciones:&nbsp;&nbsp;</p>
<ul class="ul1">
<li class="li2">Acceso a la informaci&oacute;n p&uacute;blica del perfil.&nbsp;</li>
<li class="li2">Publicaci&oacute;n en el perfil del usuario de toda aquella informaci&oacute;n ya publicada en la p&aacute;gina de LA FORNELLONA, S.L.</li>
<li class="li2">Enviar mensajes personales e individuales a trav&eacute;s de los canales de la Red Social.</li>
<li class="li2">Actualizaciones del estado de la p&aacute;gina que se publicar&aacute;n en el perfil del usuario.&nbsp;&nbsp;</li>
</ul>
<p class="p2">El usuario siempre puede controlar sus conexiones, eliminar los contenidos que dejen de interesarle y restringir con qui&eacute;n comparte sus conexiones, para ello deber&aacute; acceder a su configuraci&oacute;n de privacidad.&nbsp;</p>
<p class="p2"><strong>Publicaciones&nbsp;</strong></p>
<p class="p2">El usuario, una vez unido a la p&aacute;gina de LA FORNELLONA, S.L. podr&aacute; publicar en &eacute;sta &uacute;ltima comentarios, enlaces, im&aacute;genes o fotograf&iacute;as o cualquier otro tipo de contenido multimedia soportado por la Red Social. El usuario, en todos los casos, debe ser el titular de los mismos, gozar de los derechos de autor y de propiedad intelectual o contar con el consentimiento de los terceros afectados. Se proh&iacute;be expresamente cualquier publicaci&oacute;n en la p&aacute;gina, ya sean textos, gr&aacute;ficos, fotograf&iacute;as, v&iacute;deos, etc. que atenten o sean susceptibles de atentar contra la moral, la &eacute;tica, el buen gusto o el decoro, y/o que infrinjan, violen o quebranten los derechos de propiedad intelectual o industrial, el derecho a la imagen o la Ley. En estos casos, LA FORNELLONA, S.L. se reserva el derecho a retirar de inmediato el contenido, pudiendo solicitar el bloqueo permanente del usuario.&nbsp;</p>
<p class="p2">LA FORNELLONA, S.L. no se har&aacute; responsable de los contenidos que libremente ha publicado un usuario.&nbsp;</p>
<p class="p2">El usuario debe tener presente que sus publicaciones ser&aacute;n conocidas por los otros usuarios, por lo que &eacute;l mismo es el principal responsable de su privacidad.&nbsp;</p>
<p class="p2">Las im&aacute;genes que puedan publicarse en la p&aacute;gina no ser&aacute;n almacenadas en ning&uacute;n sistema de tratamiento por parte de LA FORNELLONA, S.L., pero s&iacute; que permanecer&aacute;n en la Red Social.&nbsp;</p>
<p class="p2"><strong>Concursos y promociones&nbsp;</strong></p>
<p class="p2">LA FORNELLONA, S.L. se reserva el derecho a realizar concursos y promociones, en los que podr&aacute; participar el usuario unido a su p&aacute;gina. Las bases de cada uno de ellos, cuando se utilice para ello la plataforma de la Red Social, ser&aacute;n publicadas en la misma. Cumpliendo siempre con la LSSI-CE y con cualquier otra norma que le sea de aplicaci&oacute;n.&nbsp;</p>
<p class="p2">La Red Social no patrocina, avala ni administra, de modo alguno, ninguna de nuestras promociones, ni est&aacute; asociada a ninguna de ellas.&nbsp;</p>
<p class="p2"><strong>Publicidad</strong></p>
<p class="p2">LA FORNELLONA, S.L. utilizar&aacute; la Red Social para publicitar sus productos y servicios, en todo caso, si decide tratar sus datos de contacto para realizar acciones directas de prospecci&oacute;n comercial, ser&aacute; siempre, cumpliendo con las exigencias legales de la RGPD y de la LSSI-CE.&nbsp;</p>
<p class="p2">No se considerar&aacute; publicidad el hecho de recomendar a otros usuarios la p&aacute;gina de LA FORNELLONA, S.L. para que tambi&eacute;n ellos puedan disfrutar de las promociones o estar informados de su actividad.&nbsp;</p>
<p class="p2">A continuaci&oacute;n detallamos el enlace a la pol&iacute;tica de privacidad de la Red Social:&nbsp;</p>
<ul class="ul1">
<li class="li5"><span class="s5">Facebook:&nbsp; <a href="https://es-es.facebook.com/privacy/explanation"><span class="s6">https://es-es.facebook.com/privacy/explanation</span></a></span></li>
<li class="li6"><span class="s7">Twitter:&nbsp;<a href="https://twitter.com/es/privacy"><span class="s8">https://twitter.com/es/privacy</span></a></span></li>
<li class="li5"><span class="s5">Instagram: <a href="https://help.instagram.com/519522125107875"><span class="s6">https://help.instagram.com/519522125107875</span></a><span class="Apple-converted-space">&nbsp;</span></span></li>
<li class="li5"><span class="s5">Youtube:&nbsp;<a href="https://policies.google.com/privacy"><span class="s6">https://policies.google.com/privacy</span></a></span><span class="s9"><span class="Apple-converted-space">&nbsp;</span></span></li>
</ul>
<p>.</p>
<p><strong>&nbsp;</strong></p>`}} />
    )
}

export default GloriaRssPolicy;
