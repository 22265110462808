export const INIT_FETCH_CREDIT_CARD = 'INIT_FETCH_CREDIT_CARD';
export const FETCH_CREDIT_CARDS_SUCCESS = 'FETCH_CREDIT_CARDS_SUCCESS';

export const CHANGE_CREDIT_CARD_SELECTOR_VISIBILITY = 'CHANGE_CREDIT_CARD_SELECTOR_VISIBILITY';
export const CHANGE_CREDIT_CARD_EDITOR_VISIBILITY = 'CHANGE_CREDIT_CARD_EDITOR_VISIBILITY';

export const CHANGE_SHIPPING_CREDIT_CARD_SELECTED = 'CHANGE_SHIPPING_CREDIT_CARD_SELECTED';

export const INIT_CREATE_CREDIT_CARD = 'INIT_CREATE_CREDIT_CARD';
export const CREDIT_CARD_CREATED_SUCCESS = 'CREDIT_CARD_CREATED_SUCCESS';

export const CREDIT_CARD_ERROR = 'CREDIT_CARD_ERROR';
export const SET_SETUP_CLIENT_SECRET = 'SET_SETUP_CLIENT_SECRET'
