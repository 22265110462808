import {DETAIL_SELECTED} from "../../types/detailTypes";

export function changeDetailSelectedAction(detail) {
    return (dispatch) => {
        dispatch(changeDetailSelected(detail))
    }
}

const changeDetailSelected = (detail) => ({
    type: DETAIL_SELECTED,
    payload: detail
});
