import {Divider, IconButton, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Redeem as VoucherIcon} from "@material-ui/icons"
import useVoucher from "../../../hooks/useVoucher";

const useStyles = makeStyles(() => ({
    container: {
        margin: 0,
        minWidth:"calc(min(100vw, 375px))",
        maxWidth:"calc(min(100vw, 375px))",
        overflowY: 'scroll'
    },
    voucherItem: {
        paddingLeft: 15,
        paddingRight: 0,
        alignItems: "center",
        justifyItems: "center",
        background: "white",
        voucherBottom: "1px solid #f5f5f5"
    },
}));

const VoucherCell = ({voucher}) => {
    const classes = useStyles();

    const { onVoucherSelected } = useVoucher();

    if (!voucher) {
        return null;
    }
    const onClickVoucher = () => {
        onVoucherSelected(voucher)
    }
    return (
        <React.Fragment>
            <ListItem alignItems="flex-start" className={classes.voucherItem} key={voucher.id} onClick={onClickVoucher}>
                <ListItemIcon>
                    <VoucherIcon/>
                </ListItemIcon>
                {(voucher.type === "product") ?
                    <ListItemText
                        primary={voucher.product.name}
                        secondary={
                            "Número de adultos: " + voucher.amount
                        }
                    />
                    :
                    <ListItemText
                        primary={voucher.product.name}
                        secondary={
                            "Importe restante: " + voucher.getRemainingAmount().toFixed(2) + "€"
                        }
                    />
                }
                <ListItemIcon>
                    <IconButton aria-label="comments">
                        <span style={voucher.getStatusStyle()}>{voucher.getStatus().toUpperCase()}</span>
                    </IconButton>
                </ListItemIcon>

            </ListItem>
            <Divider/>
        </React.Fragment>

    )
}

const VouchersList = ({vouchers, voucherSelected}) => {
    const classes = useStyles();

    if (!vouchers) {
        vouchers = [];
    }

    return (
        <div className={classes.container}>
            <List>
                {
                    vouchers.map(voucher => <VoucherCell voucher={voucher} voucherSelected={voucherSelected}/>)
                }
            </List>

        </div>

    )

}

export default VouchersList;
