import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {getPrivacyPolicy} from "../components/legal_data/getLegalData";
import useLegalPolicy from "../../hooks/useLegalPolicy";

export default function PrivacyPolicyDialog() {
    const { privacyPolicyOpened, onClosePrivacyPolicy } = useLegalPolicy();
    return (
        <Dialog
            open={privacyPolicyOpened}
            onClose={onClosePrivacyPolicy}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}
        >

            <DialogContent>
                { getPrivacyPolicy() }
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    onClick={onClosePrivacyPolicy}
                >
                    DE ACUERDO
                </Button>
            </DialogActions>
        </Dialog>
    );
}
