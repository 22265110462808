import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Drawer, InputAdornment, Typography } from "@material-ui/core";
import drawerStyles from "../../ui/DrawerStyles";
import DrawerHeader from '../cart/DrawerHeader'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Link, TextField, DialogContent } from "@material-ui/core";
import {
    MailOutline as EmailIcon,
    LocationOnOutlined as LocationIcon,
    LockOpen as PasswordIcon,
    PhoneOutlined as PhoneIcon,
    PermIdentity as UserIcon
} from '@material-ui/icons';
import { changeShopConditionsVisibilityAction } from "../../../redux/actions/info/changeShopConditionsVisibilityAction"
import { changePrivacyPolicyVisibilityAction } from "../../../redux/actions/info/changePrivacyPolicyVisibilityAction"

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { registerClientAction } from "../../../redux/actions/client/registerClientAction";
import { Formik } from 'formik';
import * as Yup from 'yup';
import useRegister from "../../../hooks/useRegister";

const useStyles = makeStyles(() => ({
    mainInputs: {
        overflow: "auto"
    },
    emailTextField: {
        paddingBottom: "1rem"
    },
    conditions: {
        textAlign: "left",
        lineHeight: "1"
    },
    conditionsGroup: {
        paddingBottom: "0.8rem"
    },
    errorMessage: {
        marginTop: "5px",
        borderColor: "red",
        color: "red",
        fontSize: "15px"
    },
    littleAdvise: {
        fontSize: "10px",
        fontStyle: "italic",
        marginTop: "16px"
    }, footer: {
        left: 0,
        right: 0,
        paddingTop: "1rem",
    },

}));

const registerSchema = Yup.object({
    name: Yup.string().trim()
        .required('Debes introducir un nombre'),
    email: Yup.string().email("Debes de introducir un email váilido").trim().lowercase()
        .required('Debes introducir un email válido'),
    phone: Yup.string().trim().matches(/^[0-9\-+]{9,15}$/, "Formato de teléfono incorrecto").min(9, "Número de teléfono incorrecto").required("Debes proporcionar un código postal"),
    postalCode: Yup.string().trim().matches(/^[0-9]+$/, "Código postal incorrecto").length(5, "Código postal incorrecto").required("Debes proporcionar un código postal"),
    password: Yup.string().trim()
        .min(5, 'Mínimo 5 carácteres')
        .required('Debes de introducir la contraseña'),
    repeatedPassword: Yup.string()
        .matches(Yup.ref('password'), 'Las contraseñas no coinciden'),
    conditionsAccepted: Yup.bool().oneOf([true]),
    acceptAds: Yup.bool()
})


const RegisterDrawer = () => {
    const dispatch = useDispatch();
    const classes = drawerStyles();

    const myClasses = useStyles();
    const { registerOpen, onClickLogin, onCloseRegisterDrawer } = useRegister();

    const isLogged = useSelector(state => state["authReducer"].isLogged);
    const isRegistering = useSelector(state => state["clientReducer"].isRegistering);
    const errorMessage = useSelector(state => state["clientReducer"].errorMessage);

    useEffect(() => {
        if (!isRegistering && isLogged) {
            onCloseRegisterDrawer()
        }
        // eslint-disable-next-line
    }, [isRegistering]);

    const [registerForm, setRegisterForm] = React.useState({
        type: 'retail',
        showPassword: false,
        showRepeatPassword: false,
        conditionsAccepted: false,
        acceptAds: false
    });

    const handleInputChange = (prop) => (event) => {
        event.preventDefault();

        setRegisterForm({
            ...registerForm,
            [prop]: event.target.value
        });
    };

    const handleClickShowPassword = () => {
        setRegisterForm({ ...registerForm, showPassword: !registerForm.showPassword });
    };
    const handleClickShowRepeatPassword = () => {
        setRegisterForm({ ...registerForm, showRepeatPassword: !registerForm.showRepeatPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const showShopConditions = (event) => {
        event.preventDefault();
        dispatch(changeShopConditionsVisibilityAction(true));
    }
    const showPrivacyPolicy = (event) => {
        event.preventDefault();
        dispatch(changePrivacyPolicyVisibilityAction(true));
    }

    return (
        <Drawer anchor={"right"} open={registerOpen} onClose={onCloseRegisterDrawer}
            styles={{ backgroundColor: "white" }}>
            <DrawerHeader title="Datos del comprador" onHandleClose={onCloseRegisterDrawer} />

            <Formik
                initialValues={{
                    type: "retail",
                    name: "",
                    email: "",
                    phone: "",
                    postalCode: "",
                    password: "",
                    repeatedPassword: "",
                    conditionsAccepted: false,
                    acceptAds: false
                }}

                validationSchema={
                    registerSchema
                }
                onSubmit={(values) => {
                    //esto es necesario para que las transformaciones (por ejemplo, el trim) se apliquen
                    const registerForm = registerSchema.cast(values);
                    if (!registerForm.conditionsAccepted) {
                        alert("Tienes que aceptar los términos legales y condiciones de privacidad")
                    } else {
                        dispatch(registerClientAction(registerForm));
                    }

                }}
            >
                {props => (
                    <form
                        className={myClasses.mainInputs}
                        onSubmit={props.handleSubmit}>
                        <DialogContent className={classes.container}>
                            <TextField
                                key="name"
                                fullWidth
                                className={myClasses.emailTextField}
                                value={registerForm.name}
                                color="secondary"
                                label="Nombre y apellidos"
                                variant="outlined"
                                onChange={handleInputChange('name')}
                                placeholder="Nombre y apellidos del comprador"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <UserIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                {...props.getFieldProps('name')}
                                helperText={props.touched.name && props.errors.name}
                                error={props.touched.name && props.errors.name !== undefined}
                            />
                            <TextField
                                key="email"
                                fullWidth
                                className={myClasses.emailTextField}
                                value={registerForm.email}
                                color="secondary"
                                label="Email del comprador"
                                variant="outlined"
                                onChange={handleInputChange('email')}
                                placeholder="usuario@correo.com"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                {...props.getFieldProps('email')}
                                helperText={props.touched.email && props.errors.email}
                                error={props.touched.email && props.errors.email !== undefined}
                            />
                            <TextField
                                key="phone"
                                fullWidth
                                className={myClasses.emailTextField}
                                value={registerForm.phone}
                                color="secondary"
                                label="Tu número de teléfono"
                                variant="outlined"
                                onChange={handleInputChange('phone')}
                                placeholder="Número de teléfono del comprador"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                {...props.getFieldProps('phone')}
                                helperText={props.touched.phone && props.errors.phone}
                                error={props.touched.phone && props.errors.phone !== undefined}
                            />
                            <TextField
                                key="postalCode"
                                fullWidth
                                className={myClasses.emailTextField}
                                value={registerForm.postalCode}
                                color="secondary"
                                label="Código Postal"
                                variant="outlined"
                                onChange={handleInputChange('postalCode')}
                                placeholder="33000"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LocationIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                {...props.getFieldProps('postalCode')}
                                helperText={props.touched.postalCode && props.errors.postalCode}
                                error={props.touched.postalCode && props.errors.postalCode !== undefined}
                            />


                            <TextField
                                key="password"
                                className={myClasses.emailTextField}
                                fullWidth
                                value={registerForm.password}
                                color="secondary"
                                label="Contraseña"
                                variant="outlined"
                                type={registerForm.showPassword ? 'text' : 'password'}
                                onChange={handleInputChange('password')}
                                placeholder="Introduce tu password"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PasswordIcon />
                                        </InputAdornment>
                                    ), endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}>

                                                {registerForm.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                {...props.getFieldProps('password')}
                                helperText={props.touched.password && props.errors.password}
                                error={props.touched.password && props.errors.password !== undefined}
                            />
                            <TextField
                                key="repeatedPassword"
                                name="repeatedPassword"
                                id="repeatedPassword"
                                className={myClasses.emailTextField}
                                fullWidth
                                value={registerForm.repeatedPassword}
                                color="secondary"
                                label="Repite la contraseña"
                                variant="outlined"
                                type={registerForm.showRepeatPassword ? 'text' : 'password'}
                                onChange={handleInputChange('repeatedPassword')}
                                placeholder="Repite tu password"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PasswordIcon />
                                        </InputAdornment>
                                    ), endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={handleClickShowRepeatPassword}
                                                onMouseDown={handleMouseDownPassword}>

                                                {registerForm.showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                {...props.getFieldProps('repeatPassword')}
                                helperText={props.touched.repeatPassword && props.errors.repeatPassword}
                                error={props.touched.repeatPassword && props.errors.repeatPassword !== undefined}
                            />


                            <FormGroup className={myClasses.conditionsGroup}>

                                <FormControlLabel
                                    control={<Checkbox id="conditionsAccepted"
                                        checked={props.values.conditionsAccepted}  {...props.getFieldProps('conditionsAccepted')} />}
                                    label={<Typography className={myClasses.conditions}>He leído y acepto los <Link href="#"
                                        to={""}
                                        onClick={showShopConditions}
                                        color="secondary"
                                        style={{ textDecorationLine: 'underline', }}>
                                        Términos y condiciones de uso
                                </Link> y <Link href="#" to={""} onClick={showPrivacyPolicy} color="secondary"
                                            style={{ textDecorationLine: 'underline', }}>
                                            Política de Privacidad
                                </Link></Typography>

                                    }
                                />
                                {(props.errors.conditionsAccepted && (props.submitCount > 0)) ?
                                    <Typography className={myClasses.errorMessage}>Tienes que aceptar los términos legales y
                                    política de privacidad</Typography> : ""}
                            </FormGroup>
                            <FormGroup className={myClasses.conditionsGroup}>
                                <FormControlLabel
                                    control={<Checkbox id="acceptAds"
                                        checked={props.values.acceptAds}  {...props.getFieldProps('acceptAds')} />}
                                    label={<Typography className={myClasses.conditions}>Deseo recibir información y ofertas
                                    del Grupo Manzano (La Fornellona SL)</Typography>}
                                />
                                <Typography className={myClasses.littleAdvise}>De conformidad con lo que establece la
                                legislación vigente en materia de Protección de Datos de Carácter Personal, se le
                                informa que los datos personales que nos facilite a través de este formulario serán
                                tratados por LA FORNELLONA, S.L., con la finalidad de gestionar sus compras. Para más
                                información consultar la <Link href="#" to={""} onClick={showPrivacyPolicy}
                                        color="secondary"
                                        style={{ textDecorationLine: 'underline', }}>
                                        Política de Privacidad
                                </Link>
                                </Typography>
                            </FormGroup>

                            {errorMessage ? <Typography className={myClasses.errorMessage}>{errorMessage}</Typography> : ""}
                            <Box className={myClasses.footer}>
                                <Button
                                    className={classes.acceptButton}
                                    variant={"contained"}
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    disabled={isRegistering}
                                >
                                    {isRegistering ? "Creando...." : "Crear cuenta"}
                                </Button>
                                <Button
                                    variant={"contained"}
                                    color="primary"
                                    fullWidth
                                    onClick={onClickLogin}>
                                    Ya tengo cuenta
                            </Button>

                            </Box>
                        </DialogContent>
                    </form>)}
            </Formik>
        </Drawer>
    );
}

export default RegisterDrawer;
