import {ADDRESS_CREATED_ERROR,ADDRESS_CREATED_SUCCESS, INIT_CREATE_ADDRESS} from "../../types/addressTypes";
import {createAddressByClientAsync} from "../../../services/addressServices";

export function createAddressAction({city, clientId, name, phone, postalCode, street}) {
    return async (dispatch) => {
        dispatch(initCreate());
        const newAddress = await createAddressByClientAsync(city, clientId = localStorage.getItem("clientId"), name, phone, postalCode, street);
        
        if (newAddress instanceof Error) {
            
            dispatch(addressCreatedError("Error creando la dirección"));
        } else {
            dispatch(addressCreated(newAddress));
        }
    }
}

const initCreate = () => ({
    type: INIT_CREATE_ADDRESS
});

const addressCreated = address => ({
    type: ADDRESS_CREATED_SUCCESS,
    payload: address
});

const addressCreatedError = error => ({
    type: ADDRESS_CREATED_ERROR,
    payload: error
});
