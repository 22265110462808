import React from 'react';
import {Badge, Box, Grid, makeStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {ShoppingBag as BagIcon} from "react-feather";
import useDetail from "../../../hooks/useDetail";
import useCart from "../../../hooks/useCart";

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 360,
        margin: 15
    },
    media: {
        height: 180,
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'alpha'
    },
    content: {
        overflow: 'hidden',
        maxWidth: '100%',
        padding: 20,
        height: 275,
        zIndex: 2
    },
    description: {
        listStylePosition: "inside",
        overflow: "hidden"
    },
    actions: {
        display: "flex",
        justifyContent: "space-between",
        zIndex: 1,
        minHeight: 50,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 15
    }
}));

const DetailCell = ({detail}) => {
    const classes = useStyles();
    const voucherInfo = detail.price.product.voucherInfo;
    const {detailsInCart} = useCart();
    const { onClickDetail } = useDetail();
    const detailInCart = detailsInCart.find(element => element.price.product.id === detail.price.product.id);
    const actualDetail = detailInCart ? detailInCart:detail;

    const AddToCartButton = () => (
        <Box sx={{width: '50%', textAlign: "end"}}>
            {
                <Button size="small" style={{minWidth: 0}} aria-label="Añadir a la bolsa" color="secondary"
                        onClick={() => onClickDetail(actualDetail)}>
                    {voucherInfo ?
                        <Badge badgeContent={(actualDetail.quantity > 0) ? 1 : null} color="secondary">
                            <BagIcon/>
                        </Badge>
                        : <Badge badgeContent={actualDetail.quantity} color="secondary">
                            <BagIcon/>
                        </Badge>
                    }
                </Button>

            }
        </Box>
    );

    return (
        <Grid item={true}>
            <Card className={classes.root}>
                <CardActionArea onClick={() => onClickDetail(actualDetail)}>
                    <CardMedia
                        className={classes.media}
                        image={detail.price.product.photoUrls[0]}
                        title={detail.price.product.name}
                    />
                    <CardContent className={classes.content}>
                        <Typography gutterBottom variant="h6" component="h5">
                            {
                                <div className={classes.title}>{detail.price.product.name}</div>
                            }
                        </Typography>
                        <Typography className={classes.description} variant="body2" color="textSecondary"
                                    component="div">
                            {
                                <div dangerouslySetInnerHTML={{__html: detail.price.product.description}}/>
                            }
                        </Typography>
                    </CardContent>

                </CardActionArea>
                <CardActions className={classes.actions}>
                    <Typography variant={"h6"} justify={"right"}>
                        {
                            voucherInfo && voucherInfo.type === "money"
                                ? "Desde " + voucherInfo.minUnits
                                : actualDetail.getUnitaryPrice().toFixed(2)
                        } €

                    </Typography>

                    <AddToCartButton/>

                </CardActions>
            </Card>
        </Grid>

    );
}

const DetailList = ({details}) => {
    if (!details) return "Precios no encontrados";

    return (
        <Grid container style={{display: 'flex', justifyContent: 'space-evenly'}}>
            {
                details.map((detail) => {
                    return <DetailCell key={detail.price.id} detail={detail}/>
                })
            }
        </Grid>
    )
}

export default DetailList;
