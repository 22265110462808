import axiosClient from "../config/axios";

export function getClientStripeKeyAsync(clientId = localStorage.getItem('clientId')) {
    return axiosClient.post(`/clients/${clientId}/stripe_keys`)
        .then(result => result.data.clientSecret)
        .catch(error => new Error(error));
}

export function getClientStatusByEmailAsync(email) {
    return axiosClient.get(`/client_status?email=${email}`)
        .then(result => result.data)
        .catch(error => {
            console.error(error);
            return undefined;
        });
}

export function createClientAsync(clientBody) {
    return axiosClient.post(`/clients`, clientBody)
        .then(result => result.data)
        .catch(error => {
            return null;
        });
}

export function getClientByEmailAsync(email) {
    return axiosClient.get(`/clients?email=${email}`)
        .then(result => result.data)
        .catch(error => {
            return null;
        });
}
export function getClientByIdAsync(clientId = localStorage.getItem('clientId')) {
    return axiosClient.get(`/clients/${clientId}`)
        .then(result => result.data)
        .catch(error => {
            return null;
        });
}

export function updateClientAsync(clientId = localStorage.getItem('clientId'), {street, city,phone,cif,company, name, postalCode}) {
    const clientBody = {name,postalCode,phone}
    if (cif && company){
        clientBody.billingInfo = {name:company,nif:cif,phone,postalCode,street,city}
    }

    return axiosClient.put(`/clients/${clientId}`,clientBody)
        .then(result => result.data)
        .catch(error => {
            return null;
        });
}
