import axiosClient from "../config/axios";

export function getShippingAreaByProvinceIdAsync(provinceId) {
    return axiosClient.get(`shipping_areas?province_code=${provinceId}`)
        .then(result => result.data)
        .catch(error => new Error(error))
}

export function getShippingMethodsByType(type){
    return axiosClient.get(`shipping_methods?type=${type}`)
        .then(result => result.data)
        .catch(error => new Error(error))
}