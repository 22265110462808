export const ADD_PRODUCT = 'ADD_PRODUCT';
export const FETCH_FEATURED_PRODUCTS_SUCCESS = 'FETCH_FEATURED_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const PRODUCT_ADDED_ERROR = 'PRODUCT_ADDED_ERROR';
export const PRODUCT_ADDED_SUCCESS = 'PRODUCT_ADDED_SUCCESS';

export const GET_PRODUCT_TO_REMOVE = 'GET_PRODUCT_TO_REMOVE';
export const PRODUCT_REMOVED_SUCCESS = 'PRODUCT_REMOVED_SUCCESS';
export const PRODUCT_REMOVED_ERROR = 'PRODUCT_REMOVED_ERROR';

export const GET_PRODUCT_TO_EDIT = 'GET_PRODUCT_TO_EDIT';
export const PRODUCT_EDITED_SUCCESS = 'PRODUCT_EDITED_SUCCESS';
export const PRODUCT_EDITED_ERROR = 'PRODUCT_EDITED_ERROR';

export const GET_PRODUCTS_BY_CATEGORY = 'GET_PRODUCTS_BY_CATEGORY';

export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
