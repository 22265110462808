import React from 'react';
import { FormGroup, Link, FormControlLabel, Checkbox } from '@material-ui/core'
import { useDispatch, useSelector } from "react-redux";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import useLegalPolicy from "../../hooks/useLegalPolicy";
import { changeShopConditionsVisibilityAction } from "../../redux/actions/info/changeShopConditionsVisibilityAction"
import { changePrivacyPolicyVisibilityAction } from "../../redux/actions/info/changePrivacyPolicyVisibilityAction"

const useStyles = makeStyles(() => ({
    bigTitle: {
        padding: "24px",
        fontSize: "40px",
        textAlign: "center",
        color: "white"
    },
    dialogActions: {
        display: "flex",
        justifyContent: "center",
        padding: "12px",
    },
    dialogBody: {
        overflowY: "auto",
        maxHeight: "calc(100vh - 112px)",
        paddingBottom: "20px"
    },
    gridItem: {
        display: "flex",
        justifyContent: "center"

    },
    emailTextField: {
        marginTop: "10px",
        paddingBottom: "20px",
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },


        '& input': {
            color: "white !important"
        },
        color: 'white'
    },
    errorMessage: {
        top: "20px",
        borderColor: "red",
        borderStyle: "dashed",
        borderWidth: "thin",
        padding: "10px",
        borderRadius: "8px",
        color: "red",
    },
    conditionsGroup: {
        paddingBottom: "0.8rem",
        alignItems:"center"
    }, conditions: {
        textAlign: "left",
        lineHeight: "1",
        color:"lightgray"
    },
}));


const newsletterSchema = Yup.object({
    email: Yup.string().email("Debes de introducir un email válido").trim().lowercase().required('Debes introducir un email válido'),
    name: Yup.string().trim().required('Debes introducir un nombre'),
    surname: Yup.string().trim().required('Debes introducir, al menos, un apellido'),
    conditionsAccepted: Yup.bool().oneOf([true]).required(),
})
const mailchimpURL = "https://manzanoshop.us20.list-manage.com/subscribe/post?u=5c161c22b2a5050692b8df509&amp;id=3fc1dd435d";

export default function NewsletterForm({mobileScreen}) {
    const classes = useStyles();
    const { newsletterOpened, onCloseNewsletter } = useLegalPolicy();
    return (
        <Container
            style={
                {
                    position: "absolute",
                    right: 0,
                    left: 0,
                    top: "50%",
                    transform: "translateY(-50%)",
                }
            }
        >
            <Typography className={classes.bigTitle} variant="h2">Boletín de noticias, novedades y eventos</Typography>
            <MailchimpSubscribe url={mailchimpURL} render={({ subscribe, status, message }) => (
                <CustomForm
                    mobileScreen={mobileScreen
                    }
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                    onHandleClose={onCloseNewsletter}
                />
            )}>

            </MailchimpSubscribe>
        </Container>
    );
}

const CustomForm = ({ mobileScreen, status, message, onValidated, onHandleClose }) => {
    
    const ReactSwal = withReactContent(Swal);
    const dispatch = useDispatch();


    const useFormStyles = makeStyles(() => ({
   
    emailTextField: {
        marginTop: "10px",
        paddingBottom: "20px",
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
 minWidth: mobileScreen ? "80%":"500px",
        '& input': {
            color: "white !important"
        },
        color: 'white'
    },
   
    }));
    
    const classes = useStyles();
    const formClasses = useFormStyles();
    
    const showShopConditions = (event) => {
        event.preventDefault();
        dispatch(changeShopConditionsVisibilityAction(true));
    }
    const showPrivacyPolicy = (event) => {
        event.preventDefault();
        dispatch(changePrivacyPolicyVisibilityAction(true));
    };
    const messageTranslated = () => {
        if (message.includes("already")) {
            return "Ya estás apuntado a esta lista"
        }
        return "Se ha producido un error"
    }

    React.useEffect(() => {
        if (status === "success") {
            ReactSwal.fire({
                icon: "success",
                text: "Te mantendremos informado",
                customClass: {
                    container: "swalert"
                },
                confirmButtonColor: "#90594c",
                timer: 3000,
                timerProgressBar: true,
                title: "Gracias por subscribirte",
            });
            onHandleClose()
        }
        // eslint-disable-next-line
    }, [status]);

    return (<Formik
        initialValues={{
            email: "",
            name: ""
        }
        }
        validationSchema={
            newsletterSchema
        }
        onSubmit={(values) => {
            //esto es necesario para que las transformaciones (por ejemplo, el trim) se apliquen
            const form = newsletterSchema.cast(values)
            if (!form.conditionsAccepted) {
                alert("Tienes que aceptar los términos legales y condiciones de privacidad")
            } else {
                onValidated({
                    "EMAIL": form.email,
                    "FNAME": form.name,
                    "LNAME": form.surname
                });
            }
           
        }}
    >
        {props => (
            <form
                onSubmit={props.handleSubmit}
            >
                <div className={classes.dialogBody}>
                    <Grid>
                        <Grid
                            item xs={12}
                            className={classes.gridItem}
                        >
                            <TextField
                                id="email"
                                className={formClasses.emailTextField}
                                label="Email"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {
                                        color: "white"
                                    }
                                }}
                               
                                placeholder="Escriba su email"
                                {...props.getFieldProps('email')}
                                helperText={props.touched.email && props.errors.email}
                                error={props.touched.email && props.errors.email}
                            />
                        </Grid>
                        <Grid
                            item xs={12}
                            className={classes.gridItem}
                        >
                            <TextField
                                id="name"
                                className={formClasses.emailTextField}
                                label="Nombre"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {
                                        color: "white"
                                    }
                                }}
                                placeholder="Escriba su nombre"
                                {...props.getFieldProps('name')}
                                helperText={props.touched.name && props.errors.name}
                                error={props.touched.name && props.errors.name}
                            />
                        </Grid>
                        <Grid
                            item xs={12}
                            className={classes.gridItem}
                        >
                            <TextField
                                id="surname"
                                className={formClasses.emailTextField}
                               
                                label="Apellidos"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {
                                        color: "white"
                                    }
                                }}
                                placeholder="Escriba su/s apellido/s"
                                {...props.getFieldProps('surname')}
                                helperText={props.touched.surname && props.errors.surname}
                                error={props.touched.surname && props.errors.surname}
                            />
                        </Grid>
                        <Grid
                            item xs={12}
                            className={classes.gridItem}
                        >
                            <Container className={classes.dialogActions}>
                                {status === "error"
                                    ?
                                    <Typography variant="body1" className={classes.errorMessage}>{messageTranslated()} </Typography>
                                    : ""
                                }
                                <Button
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    disabled={status === "sending"}>
                                    {status === "sending"
                                        ? "ENVIANDO..."
                                        : "SUBSCRÍBETE"
                                    }
                                </Button>
                            </Container>
                        </Grid>
                    </Grid>
                    <FormGroup className={classes.conditionsGroup}>

                        <FormControlLabel
                            control={<Checkbox id="conditionsAccepted"
                                style={{color:"lightgray"}}
                                checked={props.values.conditionsAccepted}  {...props.getFieldProps('conditionsAccepted')} />}
                            label={
                                <Typography className={classes.conditions}>He leído y acepto los <Link href="#"
                                to={""}
                                onClick={showShopConditions}
                                color="secondary"
                                style={{ textDecorationLine: 'underline',color:"lightgray" }}>
                                 Términos y condiciones de uso
                                </Link> y <Link href="#" to={""} onClick={showPrivacyPolicy} color="secondary"
                                    style={{ textDecorationLine: 'underline',color:"lightgray" }}>
                                    Política de Privacidad
                                </Link></Typography>

                            }
                        />
                        {(props.errors.conditionsAccepted && (props.submitCount > 0)) ?
                            <Typography className={classes.errorMessage}>Tienes que aceptar los términos legales y
                                    política de privacidad</Typography> : ""}
                    </FormGroup>
                </div>

            </form>
        )}
    </Formik>)
}