import {useDispatch, useSelector} from "react-redux";
import {changeAuthFormVisibilityAction} from "../redux/actions/auth/changeAuthFormVisibilityAction";
import {changeShoppingInfoVisibilityAction} from "../redux/actions/cart/changeShoppingInfoVisibilityAction";
import useCart from "./useCart";
import {logoutUserAction} from "../redux/actions/auth/logoutUserAction";
import useAccount from "./useAccount";

const useAuth = () => {
    const dispatch = useDispatch();

    const isLogged = useSelector(state => state["authReducer"].isLogged);
    const isLogging = useSelector(state => state["authReducer"].isLogging);
    const authFormOpen = useSelector(state => state["authReducer"].authFormOpen);

    const { cartOpen } = useCart();
    const { onCloseAccountDrawer } = useAccount();

    const onClickCloseSession = () => {
        dispatch(logoutUserAction());
        onCloseAccountDrawer();
    }

    const onCloseLoginForm = () => {
        dispatch(changeAuthFormVisibilityAction(false));
        if (cartOpen && isLogged) {
            //SI EL CARRITO ESTÁ ABIERTO AL HACER LOGIN TENEMOS QUE PASAR A SHOPPING_INFO
            dispatch(changeShoppingInfoVisibilityAction(true))
        }
    }

    return {
        authFormOpen,
        isLogged,
        isLogging,

        onClickCloseSession,

        onCloseLoginForm
    }
}

export default useAuth;
