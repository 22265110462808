import {useDispatch, useSelector} from "react-redux";
import {changeAddressSelectorVisibilityAction} from "../redux/actions/address/changeAddressSelectorVisibilityAction";
import {changeAddressEditorVisibilityAction} from "../redux/actions/address/changeAddressEditorVisibilityAction";
import {changeEditingAddressAction} from "../redux/actions/address/changeEditingAddressAction";
import {fetchShippingAddressesAction} from "../redux/actions/address/fetchShippingAddressesAction";
import {changeShippingAddressSelectedAction} from "../redux/actions/address/changeShippingAddressSelectedAction";

const useAddress = () => {
    const dispatch = useDispatch();

    const addressEditorOpened = useSelector(state => state["addressReducer"].addressEditorOpened);
    const addresses = useSelector(state => state["addressReducer"].addresses);
    const addressSelectorOpened = useSelector(state => state["addressReducer"].addressSelectorOpened);
    const addressSelected = useSelector(state => state["addressReducer"].addressSelected);
    const billingAddressSelected = useSelector(state => state["addressReducer"].billingAddressSelected);
    const creatingAddress = useSelector(state => state["addressReducer"].creatingAddress);
    const deletingAddress = useSelector(state => state["addressReducer"].deletingAddress);
    const loadingAddresses = useSelector(state => state["addressReducer"].loading);
    const shippingAddresses = useSelector(state => state["addressReducer"].shippingAddresses);
    const shippingAddressSelected = useSelector(state => state["addressReducer"].shippingAddressSelected);
    const updatingAddress = useSelector(state => state["addressReducer"].updatingAddress);

    const initAddresses = () => {
        dispatch(fetchShippingAddressesAction());
    }
    const onAddressSelected = (forEditing, address) => {
        forEditing
            ? dispatch(changeEditingAddressAction(address))
            : dispatch(changeShippingAddressSelectedAction(address));
        onCloseSelectAddress();
    }

    const onClickCreateNewAddress = (isEditing) => {
        if(!isEditing) {
            //SI NO ESTÁ EDITANDO, CUANDO VUELVA DE CREAR UNA DIRECCIÖN DEBERÏA DE HABER DESAPARECIDO EL SELECTOR
            dispatch(changeAddressSelectorVisibilityAction(false));
        }
        dispatch(changeAddressEditorVisibilityAction(true));
    }
    const onClickSelectShippingAddress = () => {
        dispatch(changeAddressSelectorVisibilityAction(true));
    }
    const onCloseAddressEditor = () => {
        dispatch(changeAddressEditorVisibilityAction(false));
        dispatch(changeEditingAddressAction(null));
    }
    const onCloseSelectAddress = () => {
        dispatch(changeAddressSelectorVisibilityAction(false));
    }
    const openSelectAddress = () => {
        dispatch(changeAddressSelectorVisibilityAction(true));
    }

    return {
        addressEditorOpened,
        addresses,
        addressSelected,
        addressSelectorOpened,
        billingAddressSelected,
        creatingAddress,
        deletingAddress,
        loadingAddresses,
        shippingAddresses,
        shippingAddressSelected,
        updatingAddress,

        initAddresses,
        onAddressSelected,
        openSelectAddress,

        onClickCreateNewAddress,
        onClickSelectShippingAddress,

        onCloseAddressEditor,
        onCloseSelectAddress
    }

}

export default useAddress;
