import axiosClient from "../config/axios";

export function getVouchersByClientIdAsync(clientId) {
    return axiosClient.get(`/clients/${clientId}/vouchers`)
        .then(result => result.data)
        .catch(error => new Error(error));
}

export function updateVoucherAsync(voucherId, voucherBody) {
    return axiosClient.put(`/vouchers/${voucherId}`, voucherBody)
        .then(result => result.data)
        .catch(error => new Error(error));
}

export function sendVoucherByEmailAsync(voucherId) {
    return axiosClient.get(`/vouchers/${voucherId}/email`).then(result => result.data).catch(_ => undefined);
}
