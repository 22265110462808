import {SELECT_CATEGORY} from "../../types/categoryTypes";

export function selectCategoryAction(category) {
    return (dispatch) => {
        dispatch(selectCategory(category));
    }
}

const selectCategory = category => ({
    type: SELECT_CATEGORY,
    payload: category
})