import {
    CHANGE_SHIPPING_METHOD_SELECTED,
    CHANGE_SHIPPING_METHOD_SELECTOR_VISIBILITY,
    FETCH_DIGITAL_SHIPPING_METHOD_SUCCESS,
    FETCH_SHIPPING_METHODS_SUCCESS, 
    INIT_CREATE_SHIPPING_METHOD,
    INIT_FETCH_SHIPPING_METHOD,
    SHIPPING_METHOD_CREATED_SUCCESS
} from "../types/shippingMethodTypes";

const initialState = {
    shippingMethods: [],
    shippingMethodEditorOpened: false,
    shippingMethodSelectorOpened: false,
    loading: false,
    shippingMethodSelected: null,
    digitalShippingMethod: null
}

const state = (state = initialState, action) => {
    switch (action.type) {
        
        case SHIPPING_METHOD_CREATED_SUCCESS:
            return {
                ...state,
                loading: false,
                shippingMethods: [...state.shippingMethods, action.payload],
                shippingMethodSelected: action.payload,
                shippingMethodEditorOpened: false
            }

        case INIT_FETCH_SHIPPING_METHOD:
            return {
                ...state,
                shippingMethods: [],
                loading: true
            }

        case FETCH_SHIPPING_METHODS_SUCCESS:
            return {
                ...state,
                shippingMethods: action.payload,
                loading: false
            }
            case FETCH_DIGITAL_SHIPPING_METHOD_SUCCESS:
                return {
                    ...state,
                    digitalShippingMethod: action.payload,
                }

        case INIT_CREATE_SHIPPING_METHOD:
            return {
                ...state,
                loading: true
            }

        case CHANGE_SHIPPING_METHOD_SELECTOR_VISIBILITY:
            return {
                ...state,
                shippingMethodSelectorOpened: action.payload
            }

        case CHANGE_SHIPPING_METHOD_SELECTED:
            return {
                ...state,
                shippingMethodSelected: action.payload,
                shippingMethodSelectorOpened: false
            }

        default:
            return state;
    }
}

export default state;
