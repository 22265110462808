import axiosClient from "../config/axios";

export function createOrderAsync(clientId = localStorage.getItem('clientId'), orderBody) {
    return axiosClient.post(`/clients/${clientId}/orders`, orderBody)
        .then(result => result.data)
        .catch(error => new Error(error));
}

export function getOrdersByClientIdAsync(clientId) {
    return axiosClient.get(`/clients/${clientId}/orders`)
        .then(result => result.data)
        .catch(error => new Error(error));
}

export function getStripeOrderKeyAsync(orderId) {
    return axiosClient.post(`/orders/${orderId}/stripe_keys`)
        .then(result => result.data.clientSecret)
        .catch(error => new Error(error));
}
