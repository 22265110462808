import {
    ADD_DETAIL_TO_CART, CHANGE_CART_VISIBILITY,
    CHANGE_CREDIT_CARD_PAYMENT_METHOD, CHANGE_PAY_ORDER_VISIBILITY, CHANGE_SHOPPING_INFO_VISIBILITY,
    CLEAR_CART, INIT_PAYMENT,
    PAYMENT_ERROR, PAYMENT_SUCCESS, REMOVE_DETAIL_FROM_CART, SET_CLIENT_SECRET
} from "../types/cartTypes";

const initialState = {
    detailsInCart: [],
    cartOpened: false,
    shoppingInfoOpened: false,
    loading: false,
    paymentError: null,
    creditCardPaymentMethod: null,
    orderClientSecret: null,
    paymentFormOpened: false
}

const state = (state = initialState, action) => {
    switch (action.type) {

        case ADD_DETAIL_TO_CART:
            const detailIndex = state.detailsInCart.findIndex(detail => detail.price.id === action.payload.price.id);
            let updatedDetails = [...state.detailsInCart];
            if (detailIndex < 0) {
                updatedDetails = [...updatedDetails, action.payload];
            } else {
                updatedDetails[detailIndex] = action.payload;
            }
            return {
                ...state,
                detailsInCart: updatedDetails.filter(detail => detail.quantity > 0)
            }
        case REMOVE_DETAIL_FROM_CART:
            const detailToRemoveIndex = state.detailsInCart.findIndex(detail => detail.price.id === action.payload.price.id);
            let details = [...state.detailsInCart];
            if (detailToRemoveIndex < 0) {
                details = [...details, action.payload];
            } else {
                details.splice(detailToRemoveIndex, 1)
            }
            return {
                ...state,
                detailsInCart: details.filter(detail => detail.quantity > 0)
            }


        case CHANGE_CART_VISIBILITY:
            return {
                ...state,
                cartOpened: action.payload
            }

        case CHANGE_CREDIT_CARD_PAYMENT_METHOD:
            return {
                ...state,
                creditCardPaymentMethod: action.payload
            }

        case CHANGE_PAY_ORDER_VISIBILITY:
            return {
                ...state,
                paymentFormOpened: action.payload
            }

        case CHANGE_SHOPPING_INFO_VISIBILITY:
            return {
                ...state,
                shoppingInfoOpened: action.payload
            }

        case INIT_PAYMENT:
            return {
                ...state,
                loading: true,
                paymentError: null
            }

        case PAYMENT_ERROR:
            return {
                ...state,
                loading: false,
                paymentError: action.payload
            }

        case PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentError: null
            }

        case SET_CLIENT_SECRET:
            return {
                ...state,
                orderClientSecret: action.payload
            }

        case CLEAR_CART:
            return initialState;

        default:
            return state
    }
}

export default state;
