import {CHANGE_ACCOUNT_DRAWER_VISIBILITY} from "../../types/accountTypes.js";

export function changeAccountDrawerVisibilityAction(visibility) {
    return (dispatch) => {
        dispatch(changeVisibility(visibility));
    }
}

const changeVisibility = visibility => ({
    type: CHANGE_ACCOUNT_DRAWER_VISIBILITY,
    payload: visibility
});
