/*
 * IMPORTS
 */
import React, { useEffect } from 'react';
import {
    CircularProgress,
    Container,
    Typography,
    makeStyles,
    CardMedia
} from "@material-ui/core";

import CategoryCardList from "../components/category/CategoryCardList";
import NewsletterForm from "../components/NewsletterForm";
import NotificationsCarouselFull from "../components/notification/NotificationCarouselFull";
import DetailList from "../components/detail/DetailList";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import useCategory from "../../hooks/useCategory";
import useNotifications from "../../hooks/useNotifications";
import useProduct from "../../hooks/useProduct";
import useDetail from "../../hooks/useDetail";
import DetailListMinimal from '../components/detail/DetailListMinimal';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f4f4f4',
        minHeight: '100%',
        width:'100%',
        maxWidth:'100%',
        paddingLeft:0,
        paddingTop:0,
        paddingRight:0
    },toolbar: theme.mixins.toolbar,
}));

const MainPage = () => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { categories, loadingCategories, fetchCategories } = useCategory();
    const { loadingNotifications, fetchNotifications } = useNotifications();
    const { fetchFeaturedProducts, fetchProducts } = useProduct();
    const { detailsFeatured, isLoading, fetchFeaturedDetails } = useDetail();

    useEffect(() => {
        fetchCategories();
        fetchNotifications();
        fetchFeaturedProducts();
        fetchFeaturedDetails();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (categories.length === 1) {
            fetchProducts();
        }
        // eslint-disable-next-line
    }, [categories]);

    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
    /*
     * VISTA
     */

    return (
        <Container className={classes.root}>
            { mobileScreen ? "":
                <div >
                    {loadingNotifications
                        ? <CircularProgress />
                        : <NotificationsCarouselFull fullScreen={fullScreen} />
                    }
                </div>
            }
                <Container>
                <div>
                {
                    loadingCategories
                        ? <CircularProgress />
                        : <React.Fragment>
                                { mobileScreen ? "" : <Typography variant="h2" style={{ textAlign: "center", marginTop: "4rem", marginBottom: "2rem" }}>Categorías</Typography>}
                        <CategoryCardList /></React.Fragment>
                }
            </div>
                <div style={{"marginTop":"1.5rem"}}>
                {
                    isLoading
                        ? <CircularProgress />
                        : <React.Fragment> <Typography variant="h2" style={{textAlign:"center",marginTop:"4rem",marginBottom:"2rem"}}>Destacados</Typography><DetailListMinimal details={detailsFeatured} /></React.Fragment>
                }

            </div>
           </Container>


          
           <Container
              style={{
                    width: "100vw",
                    height: mobileScreen ? "156vw":"35vw",
                    minHeight:"500px",
                    maxWidth:"100vw",
                  padding: 0,
                  position:"relative",
                  marginTop:"4rem",
                  bottom:0,
                  left:0,
                  right:0
              }}
             
            > 
                <CardMedia
                    style={{
                        width: "100%",
                        height: "100%",
                        padding: "0",
                        filter: "brightness(0.5)",
                    }}
                    image={
                    "https://firebasestorage.googleapis.com/v0/b/distributio-clean-panel.appspot.com/o/images%2Ffooter_manzano_asturias.jpg?alt=media&token=0423dd47-f0ef-43f5-8f50-6e2fea9d3ba0"
                }></CardMedia>
                <NewsletterForm mobileScreen={mobileScreen} />
            </Container>
        </Container>
    )
}

export default MainPage;
