import {CHANGE_ACCOUNT_DRAWER_VISIBILITY} from "../types/accountTypes.js";

const initialState = {
    accountDrawerOpened: false
}

const state = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_ACCOUNT_DRAWER_VISIBILITY:
            return {
                ...state,
                accountDrawerOpened: action.payload
            }
        default:
            return state;
    }
}

export default state;