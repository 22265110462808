import {SET_CLIENT_SECRET} from "../../types/cartTypes";
import {getStripeOrderKeyAsync} from "../../../services/orderServices";

export function getPayCreditCardPaymentClientSecretAction(orderId) {
    return async (dispatch) => {
        const clientSecret = await getStripeOrderKeyAsync(orderId);

        if (clientSecret instanceof Error) {
            console.log('Algo va mal', clientSecret);
        } else {
            dispatch(setClientSecret(clientSecret));
        }
    }
}

const setClientSecret = clientSecret => ({
    type: SET_CLIENT_SECRET,
    payload: clientSecret
})
