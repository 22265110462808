import {CHANGE_DELIVERY_DATE_SELECTED} from "../../types/deliveryDateTypes";

export function changeDeliveryDateSelectedAction(date) {
    return (dispatch) => {
        dispatch(changeDeliveryDateSelected(date));
    }
}

const changeDeliveryDateSelected = date => ({
    type: CHANGE_DELIVERY_DATE_SELECTED,
    payload: date
});