import React from 'react';

import { Box, Container, Typography, makeStyles } from "@material-ui/core";
import NotificationDialog from "../../dialogs/NotificationDialog";

import { Card, CardMedia } from "@material-ui/core/";
import { useCoverCardMediaStyles } from '@mui-treasury/styles/cardMedia/cover';
import {
    Info,
} from '@mui-treasury/components/info';

import Carousel from 'react-gallery-carousel'
import useNotifications from "../../../hooks/useNotifications";

const useStyles = makeStyles(() => ({
    card: {
        boxShadow: 'none',
        position: 'relative',
        minWidth: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        '&:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            bottom: 0,
            zIndex: 1,
            //background: 'linear-gradient(to top, #00000055, rgba(0,0,0,0))',
        },
    },
    content: {
        position: 'absolute',
        zIndex: 2,
        bottom: "10%",
        width: '100%',
        paddingLeft: "3rem"
    },
    contentResponsive: {
        zIndex: 2,
        position: "relative",
        width: '100%',
        textAlign: "center"
    },
    carousel: {
        backgroundColor: "#FFFFFF",
        width: "100%"
    },
    container: {
        height: "100%",
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        padding: 0
    },
    title: {
        color: "white",
        fontSize: "2rem",
        fontFamily: "Baskervville"
    },
    subtitle: {
        color: "white",
        fontSize: "1.5rem",
        fontStyle: "italic",
        fontFamily: "Baskervville"
    },
    titleResponsive: {
        color: "white",
        fontSize: "2rem",
        fontFamily: "Baskervville"
    },
    subtitleResponsive: {
        color: "white",
        fontSize: "2em",
        fontFamily: "Baskerville"
    }
}));

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) {
        newWindow.opener = null;
    }
}

const NotificationCell = ({ notification, fullScreen }) => {
    const classes = useStyles();
    const mediaStyles = useCoverCardMediaStyles({ bgPosition: 'top' });

    if (!notification) {
        return "NO SE PUEDE CARGAR LA NOTIFICACIÓN";
    }

    const onClickNotification = () => {
        switch (notification.action) {
            case "web":
                openInNewTab(notification.actionable);
                break;
            case "product":
                break;
            case "family":
                break;
            default:
                break;
        }
    }

    return (
        <Card className={classes.card} onClick={onClickNotification}>
            <CardMedia
                classes={mediaStyles}
                image={
                    notification.photoUrl
                }
            />
            <Box py={3} px={2} className={fullScreen ? classes.contentResponsive : classes.content}>
                <Info styles={{ paddingBottom: "5rem" }}>
                    <Typography
                        className={fullScreen ? classes.subtitleResponsive : classes.subtitle}>{notification.description}</Typography>
                    <Typography
                        className={fullScreen ? classes.titleResponsive : classes.title}>{notification.title}</Typography>
                </Info>
            </Box>
        </Card>

    );
}

const NotificationsCarouselFull = ({ fullScreen }) => {
    const styles = useStyles();

    const { notifications, notificationSelected, onCloseNotificationDialog } = useNotifications();

    if (!notifications) {
        return;
    }

    return (
        <Container className={styles.container}>
            <Carousel
                children={
                    notifications.map(notification => {
                        return <NotificationCell key={notification.id} notification={notification}
                            fullScreen={fullScreen} />
                    })
                }
                isAutoPlaying={true}
                hasIndexBoard={false}
                hasThumbnails={false}

                autoPlayInterval={5000} //en miliseconds
                className={styles.carousel}
                maxIcon={""}
                pauseIcon={""}
                playIcon={""}
                hasDotButtons={'bottom'}
                hasRightButton={false}
                hasLeftButton={false}
            />
            {
                <NotificationDialog open={notificationSelected !== null} notification={notificationSelected}
                    handleClose={onCloseNotificationDialog} />
            }

        </Container>
    );
}

export default NotificationsCarouselFull;
