import {useDispatch, useSelector} from "react-redux";
import {changeDeliveryDateSelectorVisibilityAction} from "../redux/actions/delivery_date/changeDeliveryDateSelectorVisibilityAction";

const useDeliveryDate = () => {
    const dispatch = useDispatch();

    const deliveryDateSelectorOpened = useSelector(state => state["deliveryDateReducer"].deliveryDateSelectorOpened);

    const onCloseSelectDeliveryDate = () => {
        dispatch(changeDeliveryDateSelectorVisibilityAction(false));
    }

    return {
        deliveryDateSelectorOpened,

        onCloseSelectDeliveryDate
    }
}

export default useDeliveryDate;
