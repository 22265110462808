import {
    FETCH_NOTIFICATIONS,
    NOTIFICATIONS_ERROR,
    NOTIFICATIONS_FETCHED_SUCCESS,
    SELECT_NOTIFICATION
} from "../types/notificationTypes";

const initialState = {
    notifications: [],
    notificationSelected: null,
    errorMessage: null,
    loading: false
};

const state = (state = initialState, action) => {

    switch (action.type) {

        case NOTIFICATIONS_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            };

        case NOTIFICATIONS_FETCHED_SUCCESS:
            return {
                ...state,
                notifications: action.payload,
                loading: false
            }

        case FETCH_NOTIFICATIONS:
            return {
                ...state,
                loading: true,
                errorMessage: undefined,
                notificationSelected: undefined
            };

        case SELECT_NOTIFICATION:
            return {
                ...state,
                notificationSelected: action.payload
            };

        default:
            return state;
    }
}

export default state;