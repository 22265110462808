import {CHANGE_AUTH_FORM_VISIBILITY} from "../../types/authTypes";

export function changeAuthFormVisibilityAction(visibility) {
    return ((dispatch) => {
        dispatch(changeAuthFormVisibility(visibility));
    });
}

const changeAuthFormVisibility = (visibility) => ({
    type: CHANGE_AUTH_FORM_VISIBILITY,
    payload: visibility
})
