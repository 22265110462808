import { IconButton, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { changeOrderSelectedAction } from "../../../redux/actions/order/changeOrderSelectedAction";
import { useDispatch} from "react-redux";
import { LocalMallOutlined as OrderIcon, ChevronRight as RightIcon } from "@material-ui/icons"
import {DateTime} from 'luxon'
import useOrder from "../../../hooks/useOrder";

const useStyles = makeStyles((theme) => ({
    container: {
        margin: 0,
        minWidth:"calc(min(100vw, 375px))",
        maxWidth:"calc(min(100vw, 375px))",
        overflowY:'scroll'
    },
    orderItem: {
        paddingLeft: 15,
        paddingRight: 0,
        alignItems: "center",
        justifyItems: "center",
        background: "white",
        borderBottom: "1px solid #f5f5f5"

    },
}));



const OrderCell = ({ order}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { onClickOrderInHistory } = useOrder();

    if (!order) return null;

    const onClickOrder = () => {
        onClickOrderInHistory(order);
    }

    return (
        <ListItem alignItems="flex-start" className={classes.orderItem} key={order.id} onClick={onClickOrder}>
            <ListItemIcon>
                <OrderIcon/>
            </ListItemIcon>
            <ListItemText
                primary={DateTime.fromISO(order.createdAt).setLocale('es').toLocaleString(DateTime.DATE_FULL)}
                secondary={
                    "Importe total: "+(order.finalPrice + order.taxes).toFixed(2) + "€"
                }
            />
            <ListItemIcon>
                <IconButton edge="end" aria-label="comments">
                    <RightIcon />
                </IconButton>
            </ListItemIcon>
        </ListItem>
    )
}

const OrdersList = ({ orders, orderSelected }) => {
    const classes = useStyles();

    if (!orders) {
        orders = []
    }
    return (
        <div className={classes.container}>
            <List >
                {
                    orders.map(order => <OrderCell order={order} orderSelected={orderSelected} />)
                }
            </List>

        </div>

    )

}

export default OrdersList;
