import {useEffect} from 'react';
import {useParams} from "react-router";
import {CircularProgress, Container, Typography} from "@material-ui/core";
import DetailList from "../components/detail/DetailList";
import useCategory from "../../hooks/useCategory";
import useDetail from "../../hooks/useDetail";

const DetailsPage = () => {
    const {category_id} = useParams();

    const { categorySelected } = useCategory();
    const {details, detailsLoading, getDetailsByCategoryId} = useDetail();

    useEffect(() => {
        if (category_id) {
            getDetailsByCategoryId(category_id);
        }
        // eslint-disable-next-line
    }, [category_id]);

    if (!categorySelected) return null;

    return (
        <Container>
            <Typography variant="h2" style={{textAlign: "center", margin: "3rem"}}>{categorySelected.name}</Typography>
            {
                detailsLoading
                    ? <CircularProgress/>
                    : <div>
                        <DetailList details={details}/>
                    </div>
            }
        </Container>
    )
}

export default DetailsPage;
