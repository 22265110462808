import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import HomeIcon from '@material-ui/icons/Home';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { Drawer, Typography } from "@material-ui/core";
import { Today as DateSelectedIcon, Info as StatusIcon } from "@material-ui/icons";
import DrawerHeader from "../cart/DrawerHeader"
import { DateTime } from 'luxon'
import DetailsInOrderList from './DetailsInOrderList';
import useOrder from "../../../hooks/useOrder";
import useShippingMethod from "../../../hooks/useShippingMethod";


const useStyles = makeStyles((theme) => ({
    container: {
        minWidth:"calc(min(100vw, 375px))",
        maxWidth:"calc(min(100vw, 375px))",
        backgroundColor: theme.palette.background.paper,
        overflowY: 'scroll',
        paddingRight: "0px"
    },
    paper: {
        background: "#F5F5F5",
    },
    sectionDivider: {
        height: "10px"
    }
}));

const OrderDetail = () => {
    const classes = useStyles();

    const { orderSelected } = useOrder();
    const { shippingMethodActualValue, shippingMethodValueDescription } = useShippingMethod();
    const { onCloseOrderDetail } = useOrder();

    if (!orderSelected) return null;

    return (
        <React.Fragment >
            <Drawer anchor={"right"} open={orderSelected} onClose={onCloseOrderDetail} >
                <DrawerHeader title="Pedido" onHandleClose={onCloseOrderDetail} />
                <div className={classes.container}>
                    <List component="nav" aria-label="main mailbox folders" >
                        <ListItem>
                            <ListItemIcon>
                                <StatusIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Estado del pedido"} secondary={orderSelected.status.toUpperCase()} />
                        </ListItem>
                        <Divider className={classes.sectionDivider} />
                        <ListItem button>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Dirección de Envío"} secondary={orderSelected.shippingAddress.street} />
                        </ListItem>
                        <Divider />
                        <ListItem button>
                            <ListItemIcon>
                                <LocalShippingIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Método de envío"} secondary={orderSelected.shippingMethod.name} />
                        </ListItem>
                        <Divider />
                        <ListItem button >
                            <ListItemIcon>
                                <DateSelectedIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Fecha de pedido"} secondary={DateTime.fromISO(orderSelected.createdAt).setLocale('es').toLocaleString(DateTime.DATE_FULL)} />
                        </ListItem>
                        <Divider />
                        <ListItem button >
                            <ListItemIcon>
                                <DateSelectedIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Fecha de entrega"} secondary={DateTime.fromISO(orderSelected.estimatedDeliveryDate).setLocale('es').toLocaleString(DateTime.DATE_FULL)} />
                        </ListItem>
                        <Divider />
                        <ListItem button>
                            <ListItemIcon>
                                <CreditCardIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Método de pago"} secondary={orderSelected.paymentMethod.name} />
                        </ListItem>
                        <Divider />
                        <Divider className={classes.sectionDivider} />
                        <DetailsInOrderList details={orderSelected.details} />
                        <Divider className={classes.sectionDivider} />
                        <ListItem style={{ paddingBottom: 0 }}>

                            <ListItemText primary="Compra " secondary={orderSelected.details.length + ((orderSelected.details.length > 1) ? " productos" : " producto")} />
                            <ListItemSecondaryAction>
                                {(orderSelected.finalPrice + orderSelected.taxes).toFixed(2)} €
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem style={{ paddingTop: 0 }}>

                            <ListItemText primary="Gastos de envío" secondary={orderSelected.shippingMethod.name} />
                            <ListItemSecondaryAction>
                                {shippingMethodValueDescription(orderSelected.details, orderSelected.shippingMethod)}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary="Total" primaryTypographyProps={{ variant: "h6" }} />
                            <ListItemSecondaryAction>
                                <Typography variant="h6">{(orderSelected.finalPrice + orderSelected.taxes + shippingMethodActualValue(orderSelected)).toFixed(2)} €</Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </div>
            </Drawer>

        </React.Fragment>
    );
}

export default OrderDetail;
