import React from "react";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import logo from '../assets/manzanoshop_white.png'
import {changeAvisoLegalVisibilityAction} from "../../redux/actions/info/changeAvisoLegalVisibilityAction"
import {changePrivacyPolicyVisibilityAction} from "../../redux/actions/info/changePrivacyPolicyVisibilityAction"
import {changeShopConditionsVisibilityAction} from "../../redux/actions/info/changeShopConditionsVisibilityAction"
import {changeCookiePolicyVisibilityAction} from "../../redux/actions/info/changeCookiePolicyVisibilityAction"
import {changeRSSPolicyVisibilityAction} from "../../redux/actions/info/changeRSSPolicyVisibilityAction"
import {useDispatch} from "react-redux";
import CookieConsent from "react-cookie-consent";

const useStyles = makeStyles(theme => ({
    root: {
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    footer: {
        backgroundColor: theme.palette.secondary.main,
        width: "100%",
        position: "relative",

    },
    adornment: {
        width: "25em",
        verticalAlign: "bottom",
        [theme.breakpoints.down("md")]: {
            width: "21em"
        },
        [theme.breakpoints.down("xs")]: {
            width: "15em"
        }
    },
    mainContainer: {
        position: "absolute",
        backgroundColor: theme.palette.secondary.main,

    },
    link: {
        color: "white",
        fontFamily: "Roboto",
        fontSize: "0.75rem",
        textDecoration: "none"
    },


    gridItem: {
        margin: "3em"
    },
    icon: {
        height: "4em",
        width: "4em",
        [theme.breakpoints.down("xs")]: {
            height: "2.5em",
            width: "2.5em"
        }
    },
    socialContainer: {
        position: "absolute",
        marginTop: "-6em",
        right: "1.5em",
        [theme.breakpoints.down("xs")]: {
            right: "0.6em"
        }
    },
    title: {
        fontSize: "23px",
        color: "white",
        fontFamily: "Roboto Slab"
    },
    logo: {
        height: "8em",
        padding: "0.5em"
    },
    newsletter: {
        color: "white",
        fontFamily: "Roboto",
        fontSize: "0.75rem",
        fontWeight: "bold"
    }
}));


export default function Footer() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const showAvisoLegal = (event) => {
        event.preventDefault();
        dispatch(changeAvisoLegalVisibilityAction(true));
    }
    const showShopConditions = (event) => {
        event.preventDefault();
        dispatch(changeShopConditionsVisibilityAction(true));
    }
    const showPrivacyPolicy = (event) => {
        event.preventDefault();
        dispatch(changePrivacyPolicyVisibilityAction(true));
    }
    const showCookiePolicy = (event) => {
        event.preventDefault();
        dispatch(changeCookiePolicyVisibilityAction(true));
    }
    const showRSSPolicy = (event) => {
        event.preventDefault();
        dispatch(changeRSSPolicyVisibilityAction(true));
    }

    return (
        <footer className={classes.footer}>
            <CookieConsent
                location="bottom"
                buttonText="Aceptar"
                style={{background: "#2B373B"}}
                buttonStyle={{color: "white", background: "#90594C", fontSize: "13px"}}
            >
                Esta página tan sólo utiliza las cookies necesarias para completar el proceso de compra. NO utilizamos
                cookies con fines de seguimiento ni análisis. Para más información, ver la <Link to={""}
                style={{color: "white"}} onClick={showCookiePolicy}>Política de cookies</Link>
            </CookieConsent>

            <Grid container justifyContent="flex-start" direction={"row"} alignItems={"flex-end"} className={classes.mainContainer}>
                <Grid item className={classes.gridItem}>
                    <Link to={"/"}><img src={logo} className={classes.logo} alt={""}/></Link>
                </Grid>
                <Grid item className={classes.gridItem}>
                    <Grid container direction="column" spacing={2}>
                        <Grid
                            item
                            className={classes.title}
                        >
                            Contacto
                        </Grid>
                        <Grid item>
                            <a className={classes.link} href="tel:+34608503543"> +34 608503543</a>
                        </Grid>
                        <Grid item>
                            <a className={classes.link} href="mailto:info@manzanoshop.com"> info@manzanoshop.com</a>
                        </Grid>
                        <Grid item>
                            <a className={classes.link}
                               href="https://www.facebook.com/CasaMarcialNachoManzano">Facebook</a> | <a
                            className={classes.link}
                            href="https://www.instagram.com/nachomanzano_casamarcial/?hl=es">Instagram</a>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.gridItem}>
                    <Grid item container direction="column" spacing={2} justifyContent="flex-end">
                        <Grid
                            item
                            component={Link}
                            to=""
                            onClick={showAvisoLegal}
                            className={classes.link}>
                            Aviso Legal
                        </Grid>
                        <Grid
                            item
                            component={Link}
                            to=""
                            className={classes.link}
                            onClick={showPrivacyPolicy}
                        >
                            Política de privacidad
                        </Grid>
                        <Grid
                            item
                            component={Link}
                            to=""
                            className={classes.link}
                            onClick={showCookiePolicy}
                        >
                            Política de cookies
                        </Grid>
                        <Grid
                            item
                            component={Link}
                            to=""
                            className={classes.link}
                            onClick={showShopConditions}
                        >
                            Términos y condiciones de uso
                        </Grid>
                        <Grid
                            item
                            component={Link}
                            to=""
                            className={classes.link}
                            onClick={showRSSPolicy}
                        >
                            Política de redes sociales
                        </Grid>
                    </Grid>
                    <Grid/>
                </Grid>
            </Grid>
        </footer>
    );
}
