import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {CircularProgress, Drawer} from "@material-ui/core";
import DrawerHeader from '../cart/DrawerHeader'
import {getVouchersByClientIdAction} from "../../../redux/actions/voucher/getVouchersByClientIdAction";
import VouchersList from './VouchersList';
import useVoucher from "../../../hooks/useVoucher";

const VouchersHistoryDrawer = () => {
    const dispatch = useDispatch();

    const { voucherHistoryOpened, vouchers, voucherSelected, onCloseVoucherDrawer } = useVoucher();

    const loadingVouchers = useSelector(state => state["voucherReducer"].loading);

    useEffect(() => {
        if (voucherHistoryOpened) {
            initVouchers();
        }
        // eslint-disable-next-line
    }, [voucherHistoryOpened]);

    const initVouchers = () => {
        dispatch(getVouchersByClientIdAction(localStorage.getItem('clientId')));
    }

    return (
            <Drawer anchor={"right"} open={voucherHistoryOpened} onClose={onCloseVoucherDrawer} >
                  <DrawerHeader title={"Tus Bonos"} onHandleClose={onCloseVoucherDrawer}/>
                  {
                loadingVouchers ? <CircularProgress /> : <VouchersList vouchers={vouchers} voucherSelected={voucherSelected}/>
            }
           </Drawer>
    );
}


export default VouchersHistoryDrawer;
