import React, {useEffect, useState} from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import {Button, DialogContent, Drawer, InputBase, InputLabel, Paper, Snackbar, Typography} from '@material-ui/core';
import stripeIcon from '../../assets/poweredStripe.png'
import {useDispatch} from "react-redux";
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {getSetupCreditCardClientSecretAction} from "../../../redux/actions/credit_card/getSetupCreditCardClientSecretAction"
import DrawerHeader from '../cart/DrawerHeader';
import {makeStyles} from '@material-ui/core/styles';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import drawerStyles from "../../ui/DrawerStyles";
import useCreditCart from "../../../hooks/useCreditCart";
import useAccount from "../../../hooks/useAccount";

const styles = makeStyles(() => ({

    actionBar: {
        marginTop: 20,
    },
    cardElement: {
        marginTop: 10,
        borderRadius: 4,
        borderColor: "#CCCCCC",
        borderWidth: 1,

        padding: 10
    },
    cardInputLabel: {
        paddingBottom: "4px"
    },
    nameInput: {
        padding: "0px",
        input: {
            padding: "0px"
        }
    },
    inlineElements: {
        display: "flex",
        flexDirection: "row"
    },
    logo: {
        height: "3em",
        float: "right",
        marginTop: "1rem"
    },
    errorMessage:{
        paddingTop:"1rem",
        color:"red",
        fontWeight:"bold",
        fontSize:"18px"
    }
}))
const ELEMENT_OPTIONS = {
    style: {
        base: {
            fontSize: '16px',
            fontFamily: 'Roboto',
            color: 'rgba(0, 0, 0, 0.87)',
            letterSpacing: '0.025em',
            '::placeholder': {
                color: '#aab7c4',
            }
        },
        invalid: {
            color: '#9e2146',
        },
    },
};



const ErrorSnack = ({open, message, setErrorMessage}) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorMessage(undefined);
    };
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
            <MuiAlert onClose={handleClose} severity="error">
                {message}
            </MuiAlert>
        </Snackbar>)
};

const SuccessSnack = ({open, message, setSuccessMessage}) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessMessage(undefined);
    };
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
            <MuiAlert onClose={handleClose} severity="success">
                {message}
            </MuiAlert>
        </Snackbar>)
};

const CreditCardEditor = () => {
    const dispatch = useDispatch();
    const classes = drawerStyles();
    const myClasses = styles();
    const ReactSwal = withReactContent(Swal)
    const stripe = useStripe();
    const elements = useElements();
    const account = useAccount();

    const { creditCardEditorOpened, setupClientSecret, onCloseCreditCardEditor } = useCreditCart();

    const [form, setForm] = useState({
        name: ""
    });
    const [isLoading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [successMessage, setSuccessMessage] = useState(null);

    useEffect(() => {
       if(creditCardEditorOpened){
       dispatch(getSetupCreditCardClientSecretAction());
       if (!account.client){
        account.initClient()
       }
        }
        // eslint-disable-next-line
    }, [creditCardEditorOpened]);


    const onHandleChange = event => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        });
    }

    const onClickSaveButton = async event => {
        event.preventDefault();
        const result = await ReactSwal.fire({
            customClass: {
                container: "swalert",
                confirmButton: "okButton"
            },
            title: 'Validar tarjeta',
            text: 'Por seguridad, y para validar el uso legítimo de tu tarjeta, es posible que el emisor realice un cargo de 0.00€ en la misma',
            showCancelButton: false,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Entendido',
            confirmButtonColor: "#90594C",
        })
        if (!result.isConfirmed) {
            return
        }
        if (!stripe || !elements) {
            return;
        }

        setLoading(true)
        const { error } = await stripe
            .confirmCardSetup(setupClientSecret, {
                payment_method: {
                    type: 'card',
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: form.name,
                        address: {
                            postal_code:(account.client && account.client.postalCode) ? account.client.postalCode:"",
                        }
                    }
                }
            });

        if (error) {
            setLoading(false);
            setErrorMessage(error.message);
        } else {
            ReactSwal.fire({
                customClass: {
                    container: "swalert"
                },
                title: "Tarjeta creada",
                timer: "1500",
                timerProgressBar: true,
                icon: "success",
                showConfirmButton: false
            });
            onCloseCreditCardEditor();
            setLoading(false);
        }
    }

    return (
        <Drawer ModalProps={{disableEnforceFocus: true}} anchor={"right"} disabled={isLoading} open={creditCardEditorOpened}
                onClose={onCloseCreditCardEditor} width={1 / 3}>

            <ErrorSnack open={errorMessage !== undefined} message={errorMessage}
                        setErrorMessage={setErrorMessage} />
            <SuccessSnack open={successMessage !== undefined} message={successMessage}
                          setSuccessMessage={setSuccessMessage} />

            <DrawerHeader title="Introduce los datos de la tarjeta" onHandleClose={onCloseCreditCardEditor}/>
            <DialogContent className={classes.container}>
                <Paper variant="outlined" id="nombre" className={myClasses.cardElement}>
                    <InputLabel htmlFor="nombre" className={myClasses.cardInputLabel}>Nombre del
                        propietario</InputLabel>

                    <InputBase fullWidth name="name" id="name" onChange={onHandleChange}
                               placeholder="Nombre y apellidos"
                    />
                </Paper>
                <Paper variant="outlined" id="numero" className={myClasses.cardElement}>
                    <InputLabel htmlFor="numero" className={myClasses.cardInputLabel}>Número de tarjeta</InputLabel>
                    <CardNumberElement options={ELEMENT_OPTIONS}/>
                </Paper>

                <div className={myClasses.inlineElements}>
                    <Paper variant="outlined" id="caducidad" className={myClasses.cardElement} style={{flexGrow: 2}}>
                        <InputLabel htmlFor="caducidad" className={myClasses.cardInputLabel}>Fecha de
                            caducidad</InputLabel>
                        <CardExpiryElement options={ELEMENT_OPTIONS}/>
                    </Paper>
                    <Paper variant="outlined" id="cvc" className={myClasses.cardElement}
                           style={{flexGrow: 2, marginLeft: "1rem"}}>
                        <InputLabel htmlFor="cvc" className={myClasses.cardInputLabel}>CVC</InputLabel>
                        <CardCvcElement options={ELEMENT_OPTIONS}/>
                    </Paper>
                </div>

                {errorMessage ? <Typography className={myClasses.errorMessage} >{errorMessage}</Typography> : ""}
                <div>
                    <div className={myClasses.actionBar}>
                        <Button variant="contained" className={classes.acceptButton} onClick={onClickSaveButton}
                                disabled={setupClientSecret === undefined || isLoading}>
                            {isLoading ? "GUARDANDO..." : "GUARDAR"}
                        </Button>
                    </div>
                    <img src={stripeIcon} className={myClasses.logo} alt={""} />
                </div>
            </DialogContent>

        </Drawer>
    );
}


export default CreditCardEditor;
