import {useDispatch, useSelector} from "react-redux";
import {changeCreditCardSelectorVisibilityAction} from "../redux/actions/credit_card/changeCreditCardSelectorVisibilityAction";
import {fetchCreditCardsAction} from "../redux/actions/credit_card/fetchCreditCardsAction";
import {changeCreditCardEditorVisibilityAction} from "../redux/actions/credit_card/changeCreditCardEditorVisibilityAction";
import {changeCreditCardSelectedAction} from "../redux/actions/credit_card/changeCreditCardSelectedAction";

const useCreditCart = () => {
    const dispatch = useDispatch();

    const creditCards = useSelector(state => state["creditCardReducer"].creditCards);
    const loadingCreditCards = useSelector(state => state["creditCardReducer"].loading);
    const creditCardEditorOpened = useSelector(state =>state["creditCardReducer"].creditCardEditorOpened);
    const creditCardSelected = useSelector(state => state["creditCardReducer"].creditCardSelected);
    const creditCardSelectorOpened = useSelector(state => state["creditCardReducer"].creditCardSelectorOpened);
    const setupClientSecret = useSelector(state => state["creditCardReducer"].setupClientSecret);


    const initCards = () => {
        dispatch(fetchCreditCardsAction());
    }

    const onClickCreateNewCreditCard = () => {
        dispatch(changeCreditCardEditorVisibilityAction(true));
    }
    const onClickCreditCard = (creditCard) => {
            dispatch(changeCreditCardSelectedAction(creditCard))
    }
    const onClickSelectCreditCard = () => {
        dispatch(changeCreditCardSelectorVisibilityAction(true));
    }

    const onCloseCreditCardEditor = () => {
        dispatch(fetchCreditCardsAction());
        dispatch(changeCreditCardEditorVisibilityAction(false));
    }
    const onCloseCreditCardSelector = () => {
        dispatch(changeCreditCardSelectorVisibilityAction(false));
    }

    return {
        creditCards,
        creditCardEditorOpened,
        creditCardSelected,
        creditCardSelectorOpened,
        loadingCreditCards,
        setupClientSecret,

        initCards,

        onClickCreateNewCreditCard,
        onClickCreditCard,
        onClickSelectCreditCard,

        onCloseCreditCardEditor,
        onCloseCreditCardSelector
    }
}

export default useCreditCart;
