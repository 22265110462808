import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    body:{
        overflowY:"auto"
    }
}));

const GloriaPrivacyPolicy = () => {
    const classes = useStyles();

    return (
        <div className={classes.body}dangerouslySetInnerHTML={{ __html: `<p class="p1"><strong>POL&Iacute;TICA DE PRIVACIDAD</strong></p>
<p class="p1">Se le informa que los datos personales que nos facilite voluntariamente, a trav&eacute;s de los distintos formularios de la web, ser&aacute;n tratados por LA FORNELLONA, S.L. con domicilio en&nbsp;LA SALGAR S/N 33549 - PARRES (ASTURIAS), que cumple con las medidas de seguridad exigidas en la legislaci&oacute;n vigente en materia de Protecci&oacute;n de Datos de Car&aacute;cter Personal. La finalidad de dicho tratamiento es la descrita en cada uno de los formularios<span class="Apple-converted-space">&nbsp; </span>establecidos en la p&aacute;gina web en el que Usted nos haya facilitado sus datos.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p1">Los datos facilitados no ser&aacute;n cedidos a terceros salvo en los casos en que exista una obligaci&oacute;n legal, y se conservar&aacute;n durante el tiempo necesario para atender su solicitud, siempre y cuando no nos indique lo contrario ejerciendo su derecho de supresi&oacute;n, o durante el tiempo necesario para cumplir con las obligaciones legales y atender las posibles responsabilidades que pudieran derivar del cumplimiento de la finalidad para la que los datos fueron recabados.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p1">LA FORNELLONA, S.L. no elaborar&aacute; ning&uacute;n tipo de &ldquo;perfil&rdquo;, en base a la informaci&oacute;n facilitada. No se tomar&aacute;n decisiones automatizadas en base a perfiles.</p>
<p class="p1">Asimismo, LA FORNELLONA, S.L. cancelar&aacute; o rectificar&aacute; los datos cuando resulten inexactos, incompletos o hayan dejado de ser necesarios para su finalidad, de conformidad con lo previsto en la legislaci&oacute;n vigente en materia de Protecci&oacute;n de Datos de Car&aacute;cter Personal.</p>
<p class="p1">Mientras no nos comunique lo contrario, entenderemos que sus datos no han sido modificados, que usted se compromete a notificarnos cualquier variaci&oacute;n y que tenemos el consentimiento para utilizarlos a fin de poder fidelizar la relaci&oacute;n entre las partes.</p>
<p class="p1">El usuario podr&aacute; ejercer los derechos de acceso, rectificaci&oacute;n, supresi&oacute;n, oposici&oacute;n, limitaci&oacute;n del tratamiento, portabilidad de datos y a no ser objeto de decisiones individualizadas automatizadas, en relaci&oacute;n con los datos objeto del tratamiento, ante el responsable del tratamiento en la direcci&oacute;n anteriormente mencionada, o por correo electr&oacute;nico a<span class="Apple-converted-space">&nbsp; </span><a href="mailto:info@manzanoshop.com"><span class="s1">info@manzanoshop.com</span></a>, adjuntando copia de su DNI o documento equivalente. En caso de que no haya obtenido satisfacci&oacute;n en el ejercicio de sus derechos, podr&aacute; presentar una reclamaci&oacute;n ante la<span class="Apple-converted-space">&nbsp; </span><em>Autoridad de Control en materia Protecci&oacute;n de Datos competente, </em>siendo &eacute;sta la <em>Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos, </em>y cuyos datos de contacto est&aacute;n accesibles en <a href="https://sedeagpd.gob.es/sede-electronica-web/vistas/formNuevaReclamacion/reclamacion.jsf"><span class="s1"><em>https://sedeagpd.gob.es/sede-electronica-web/vistas/formNuevaReclamacion/reclamacion.jsf</em></span></a><span class="s2"><em>.</em></span><em><span class="Apple-converted-space">&nbsp;</span></em></p>
<p class="p1">LA FORNELLONA, S.L. adopta las medidas t&eacute;cnicas, controles y procedimientos de car&aacute;cter f&iacute;sico, organizativo y tecnol&oacute;gico, razonablemente fiables y efectivos, orientados a preservar la integridad y la seguridad de sus datos y garantizar su privacidad. No obstante, no asume ninguna responsabilidad por los da&ntilde;os y perjuicios derivados de alteraciones que terceros puedan causar en los sistemas inform&aacute;ticos, documentos electr&oacute;nicos o ficheros del usuario.</p>
<p class="p1">Adem&aacute;s, todo el personal con acceso a los datos personales ha sido formado y tiene conocimiento de sus obligaciones con relaci&oacute;n a los tratamientos de sus datos personales.</p>
<p class="p1">En el caso de los contratos que suscribimos con nuestros proveedores incluimos cl&aacute;usulas en las que se les exige mantener el deber de secreto respecto a los datos de car&aacute;cter personal a los que hayan tenido acceso en virtud del encargo realizado, as&iacute; como implantar las medidas de seguridad t&eacute;cnicas y organizativas necesarias para garantizar la confidencialidad, integridad, disponibilidad y resiliencia permanentes de los sistemas y servicios de tratamiento de los datos personales.</p>
<p class="p1">Todas estas medidas de seguridad son revisadas de forma peri&oacute;dica para garantizar su adecuaci&oacute;n y efectividad.</p>
<p class="p1">Sin embargo, la seguridad absoluta no se puede garantizar y no existe ning&uacute;n sistema de seguridad que sea impenetrable por lo que, en el caso de cualquier informaci&oacute;n objeto de tratamiento y bajo nuestro control se viese comprometida como consecuencia de una brecha de seguridad, tomaremos las medidas adecuadas para investigar el incidente, notificarlo a la Autoridad de Control y, en su caso, a aquellos usuarios que se hubieran podido ver afectados para que tomen las medidas adecuadas.</p>
<p class="p1">De acuerdo con lo que establece la Ley 34/2002, de Servicios de la Sociedad de la Informaci&oacute;n y el Comercio Electr&oacute;nico en su art&iacute;culo 21, solicitaremos su consentimiento para poder realizar comunicaciones publicitarias que consideremos puedan ser de su inter&eacute;s, por correo electr&oacute;nico o por cualquier otro medio de comunicaci&oacute;n electr&oacute;nica equivalente.</p>
<p class="p1">Usted tiene derecho a oponerse y/o retirar el/los consentimiento/s al tratamiento de los datos indicados anteriormente, en su caso, y en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada, mediante notificaci&oacute;n al responsable del tratamiento en<span class="Apple-converted-space">&nbsp; </span>la/s direcci&oacute;n/es indicada/s.</p>`}} />
    );
}

export default GloriaPrivacyPolicy;
