import { getClientByIdAsync } from "../../../services/clientServices";
import {LOADING_CLIENT,LOAD_CLIENT_SUCCESS} from "../../types/clientTypes";

export function loadClientAction(clientId = localStorage.getItem("clientId")) {
    return async (dispatch) => {
        if (!clientId) return;
        dispatch(changeLoadingStatus());
        const client = await getClientByIdAsync(clientId)
        if (client) { 
            dispatch(loadClient(client));
        } else {
            alert("NO SE PUDO ENCONTRAR EL USUARIO")
            localStorage.removeItem("clientId")
        }
    }
}

const changeLoadingStatus = (status) => ({
    type: LOADING_CLIENT,
    payload: status
 });
 const loadClient = (client) => ({
    type: LOAD_CLIENT_SUCCESS,
    payload: client
});