import {CHANGE_SHOPPING_INFO_VISIBILITY} from "../../types/cartTypes";

export function changeShoppingInfoVisibilityAction(visibility) {
    return ((dispatch) => {
       
        dispatch(changeShoppingInfoVisibility(visibility));
    });
}

const changeShoppingInfoVisibility = (visibility) => ({
    type: CHANGE_SHOPPING_INFO_VISIBILITY,
    payload: visibility
})
