import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useLegalPolicy from "../../hooks/useLegalPolicy";
import {getShopConditions} from "../components/legal_data/getLegalData";

export default function ShopConditionsDialog() {

    const { shopConditionsOpened, onCloseShopConditions } = useLegalPolicy();

    return (
            <Dialog
                open={shopConditionsOpened}
                onClose={onCloseShopConditions}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"sm"}
            >
                <DialogContent>
                    { getShopConditions() }
               </DialogContent>
      <DialogActions dividers >


                    <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    onClick={onCloseShopConditions}
                    >
                       DE ACUERDO
                        </Button>
                </DialogActions>
            </Dialog>
    );
}
