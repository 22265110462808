import {
    FETCH_PRODUCTS,
    FETCH_PRODUCTS_ERROR,
    FETCH_PRODUCTS_SUCCESS
} from "../../types/productTypes";
import {getProductsByCategoryIdAsync} from "../../../services/productServices";

export function getProductsByCategoryAction(categoryId) {
    return async (dispatch) => {
        dispatch(initGetProductsByCategory());

        const productsResponse = await getProductsByCategoryIdAsync(categoryId);

        if (productsResponse instanceof Error) {
            dispatch(productsByCategoryError(productsResponse.toString()));
        } else {
            dispatch(productsByCategorySuccess(productsResponse));
        }
    }
}

const initGetProductsByCategory = () => ({
    type: FETCH_PRODUCTS
});

const productsByCategoryError = errorMessage => ({
    type: FETCH_PRODUCTS_ERROR,
    payload: errorMessage
});

const productsByCategorySuccess = products => ({
    type: FETCH_PRODUCTS_SUCCESS,
    payload: products
});