import {CHANGE_INVALID_OPTION_VISIBILITY} from "../../types/detailTypes";

export function changeInvalidOptionVisibilityAction(visibility) {
    return (dispatch) => {
        dispatch(changeVisibility(visibility));
    }
}

const changeVisibility = visibility => ({
    type: CHANGE_INVALID_OPTION_VISIBILITY,
    payload: visibility
})