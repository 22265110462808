import {
    FETCH_DETAILS, FETCH_DETAILS_ERROR, FETCH_DETAILS_SUCCESS
} from '../../types/detailTypes';

import { fetchPricesByCategoryIdAsync, fetchPricesByFeeIdAndCategoryId } from '../../../services/priceServices';
import Detail from "../../../models/Detail";

export function fetchDetailsByCategoryAction(familyId) {

    return async (dispatch) => {
        dispatch(initFetchDetails());

        let prices;
        if (localStorage.getItem('clientId')) {
            prices = await fetchPricesByCategoryIdAsync(familyId);
        } else {
            prices = await fetchPricesByFeeIdAndCategoryId(familyId, process.env.REACT_APP_UNREGISTERED_FEE_ID);
        }

        if (prices instanceof Error) {
            dispatch(pricesFetchError());
        } else {
            dispatch( detailsLoaded(prices.map(price => Detail.createFromPrice(price))));
        }
    };
}

const initFetchDetails = () => ({
   type: FETCH_DETAILS
});

const detailsLoaded = (details) => ({
    type: FETCH_DETAILS_SUCCESS,
    payload: details
});

const pricesFetchError = () => ({
   type: FETCH_DETAILS_ERROR
});
