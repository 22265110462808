export const ADD_DETAIL_TO_CART = 'ADD_DETAIL_TO_CART';
export const REMOVE_DETAIL_FROM_CART = 'REMOVE_DETAIL_FROM_CART';
export const INC_DETAIL_IN_CART = 'INC_DETAIL_IN_CART';
export const DEC_DETAIL_IN_CART = 'DEC_DETAIL_IN_CART';

export const CHANGE_CART_VISIBILITY = 'CHANGE_CART_VISIBILITY';
export const CHANGE_SHOPPING_INFO_VISIBILITY = 'CHANGE_SHOPPING_INFO_VISIBILITY';
export const CHANGE_SELECT_ADDRESS_VISIBILITY = 'SELECT_ADDRESS_VISIBILITY';
export const CHANGE_CREATE_ADDRESS_VISIBILITY = 'SELECT_CREATE_ADDRESS_VISIBILITY';

export const INIT_PAYMENT = 'INIT_PAYMENT';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';

export const CHANGE_CREDIT_CARD_PAYMENT_METHOD = 'CHANGE_CREDIT_CARD_PAYMENT_METHOD';

export const SET_CLIENT_SECRET = 'SET_CLIENT_SECRET';

export const CHANGE_PAY_ORDER_VISIBILITY = 'CHANGE_PAY_ORDER_VISIBILITY';

export const CLEAR_CART = 'CLEAR_CART';

