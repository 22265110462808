import {
    IS_REGISTERING,
    LOADING_CLIENT, 
    LOAD_CLIENT_SUCCESS, 
    REGISTER_ERROR,
    REGISTER_SUCCESS,
    UPDATING_CLIENT,
    UPDATING_CLIENT_ERROR,
    UPDATING_CLIENT_SUCCESS
} from "../types/clientTypes";

const initialState = {
    isRegistering:false,
    errorMessage: undefined,
    client:undefined,
    loadingClient:false,
    updatingClient:false,
    updatingClientError:false,
    updatingClientSuccess:false
}

const state = (state = initialState, action) => {
    switch (action.type) {
        
        case IS_REGISTERING:
            return {
                ...state,
                isRegistering:true,
                errorMessage:undefined,
            }
        case REGISTER_ERROR:
            return {
                ...state,
                errorMessage: action.payload,
                isRegistering: false,
            }
        case REGISTER_SUCCESS:
            return {
                ...state,
                isRegistering: false,
                errorMessage:undefined
            }
        case LOADING_CLIENT:
            return {
                ...state,
                loadingClient:action.payload,
            }
        case LOAD_CLIENT_SUCCESS:
            return {
                ...state,
                client:action.payload,
                loadingClient:false
            }
            case UPDATING_CLIENT:
            return {
                ...state,
                updatingClient:action.payload,
                updatingClientSuccess:false,
                updatingClientError:false
            }
        case UPDATING_CLIENT_SUCCESS:
            return {
                ...state,
                client:action.payload,
                updatingClient:false,
                updatingClientSuccess:true,
                updatingClientError:false
            }
            case UPDATING_CLIENT_ERROR:
            return {
                ...state,
                updatingClient:false,
                updatingClientError:action.payload,
                updatingClientSuccess:false
            }

        default:
            return state;
    }
}

export default state;