import {useDispatch, useSelector} from "react-redux";
import {changeAccountDrawerVisibilityAction} from "../redux/actions/account/changeAccountDrawerVisibilityAction";
import {loadClientAction} from "../redux/actions/client/loadClientAction";

const useAccount = () => {
    const dispatch = useDispatch();

    const accountOpen = useSelector(state => state["accountReducer"].accountDrawerOpened);
    const client = useSelector(state => state["clientReducer"].client);
    const isLoadingClient = useSelector(state => state["clientReducer"].loadingClient);
    const isUpdatingClient = useSelector(state => state["clientReducer"].updatingClient);
    const updatingClientError = useSelector(state => state["clientReducer"].updatingClientError);
    const updatingClientSuccess = useSelector(state => state["clientReducer"].updatingClientSuccess);

    const initClient = () => {
        dispatch(loadClientAction());
    }

    const onCloseAccountDrawer = () => {
        dispatch(changeAccountDrawerVisibilityAction(false));
    }

    return {
        accountOpen,
        client,
        isLoadingClient,
        isUpdatingClient,
        updatingClientError,
        updatingClientSuccess,

        initClient,

        onCloseAccountDrawer
    }

}

export default useAccount;
