import {FETCH_DIGITAL_SHIPPING_METHOD_SUCCESS} from "../../types/shippingMethodTypes";
import {getShippingMethodsByType} from "../../../services/shippingAreaServices";

export function fetchDigitalShippingMethodAction() {
    return async (dispatch) => {
        
        const shippingMethods = await getShippingMethodsByType("digital");//ESTO AQUÍ MUY GUAPO HARDCODEADO
        if (shippingMethods instanceof Error || !shippingMethods || !shippingMethods[0]) {
            dispatch(loadDigitalShippingMethod());
        } else {
            dispatch(loadDigitalShippingMethod(shippingMethods[0]));
        }
    }
}



const loadDigitalShippingMethod = shippingMethod => ({
    type: FETCH_DIGITAL_SHIPPING_METHOD_SUCCESS,
    payload: shippingMethod
});