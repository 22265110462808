import {applyMiddleware, createStore} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducer from './reducers';


/*const store = createStore(
    reducer,
    compose(
        applyMiddleware(thunk),
        typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : f => f
    )
);

export default store;*/


    const composeEnhancers = composeWithDevTools({trace: true, traceLimit: 25 });
    const store = createStore(reducer, /* preloadedState, */ composeEnhancers(
      applyMiddleware(thunk)
    ));


    export default store;