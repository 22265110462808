import {useDispatch, useSelector} from "react-redux";
import {fetchNotificationsAction} from "../redux/actions/notification/fetchNotificationsAction";
import {selectNotificationAction} from "../redux/actions/notification/selectNoticationAction";

const useNotifications = () => {
    const dispatch = useDispatch();

    const notifications = useSelector(state => state['notificationReducer'].notifications);
    const notificationSelected = useSelector(state => state['notificationReducer'].notificationSelected);
    const loadingNotifications = useSelector(state => state["notificationReducer"].loading);

    const fetchNotifications = () => {
        dispatch(fetchNotificationsAction());
    };

    const onCloseNotificationDialog = () => {
        dispatch(selectNotificationAction(null));
    }

    return {
        loadingNotifications,
        notifications,
        notificationSelected,

        fetchNotifications,

        onCloseNotificationDialog
    }
}

export default useNotifications;
