import React from "react";

const GloriaLegalAdvise = () => (
    <div style={{overflowY: "auto"}} dangerouslySetInnerHTML={{
        __html: `<p class="p1"><strong>AVISO LEGAL</strong></p>
<ul class="ul1">
<li class="li1"><strong>DATOS DE LA EMPRESA</strong></li>
</ul>
<p class="p2">En cumplimiento del art&iacute;culo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Informaci&oacute;n y Comercio Electr&oacute;nico, LA FORNELLONA, S.L.<span class="Apple-converted-space">&nbsp; </span>le informa que se encuentra inscrita en el Registro Mercantil de Asturias, Tomo 3791, Libro 0, Folio 74, Secci&oacute;n 8, Hoja AS39749, Inscripci&oacute;n 1, siendo sus datos identificativos los siguientes:</p>
<p class="p3">&nbsp;</p>
<p class="p4">Denominaci&oacute;n social: LA FORNELLONA, S.L.</p>
<p class="p4">Raz&oacute;n Comercial: Manzano Shop<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p4">C.I.F.: B74256439</p>
<p class="p4">Domicilio social: LA SALGAR S/N 33549 - PARRES (ASTURIAS) <span class="Apple-converted-space">&nbsp;</span></p>
<p class="p4">Tel&eacute;fono: 985841525</p>
<p class="p4">Correo electr&oacute;nico a efectos de comunicaci&oacute;n: <a href="mailto:info@manzanoshop.com"><span class="s2">info@manzanoshop.com</span></a><span class="s3"><span class="Apple-converted-space">&nbsp;</span></span></p>
<p class="p4">Todas las notificaciones y comunicaciones entre los usuarios y LA FORNELLONA, S.L. se considerar&aacute;n eficaces, a todos los efectos, cuando se realicen a trav&eacute;s de correo postal o cualquier otro medio de los detallados anteriormente.</p>
<p class="p4">LA FORNELLONA, S.L. cuenta con la autorizaci&oacute;n administrativa N&ordm; RGSEAA 26.12528/O necesaria para desarrollar las actividades desempe&ntilde;adas por el mismo. El &oacute;rgano competente encargado de su supervisi&oacute;n es el Ministerio de Sanidad.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p4">As&iacute; mismo, cuenta con la autorizaci&oacute;n administrativa para los establecimientos:</p>
<ul class="ul1">
<li class="li4">LA SALGAR N&ordm; REAT: 2802 para la actividad de restauraci&oacute;n,</li>
<li class="li4">CASA MARCIAL N&ordm; REAT: 2867 para la actividad de restauraci&oacute;n,</li>
<li class="li4">NARBASU N&ordm; REAT: 33583 para la actividad de restauraci&oacute;n y alojamiento tur&iacute;stico,</li>
</ul>
<p class="p4">siendo el &oacute;rgano competente encargado de su supervisi&oacute;n es el Ministerio de Turismo.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p5">&nbsp;</p>
<p class="p6">&nbsp;</p>
<ul class="ul1">
<li class="li4"><strong>CONDICIONES DE ACCESO Y UTILIZACI&Oacute;N</strong></li>
</ul>
<p class="p4">El presente aviso legal regula el uso del sitio web <span class="s3">manzanoshop.com </span>(en adelante, LA WEB), del que es titular LA FORNELLONA, S.L.</p>
<p class="p4">La navegaci&oacute;n por la web de LA FORNELLONA, S.L. atribuye la condici&oacute;n de usuario del mismo e implica la aceptaci&oacute;n plena y sin reservas de todas y cada una de las disposiciones incluidas en este Aviso Legal, que pueden sufrir modificaciones.</p>
<p class="p4">El sitio web y sus servicios son de acceso libre y gratuito, no obstante, LA FORNELLONA, S.L. condiciona la utilizaci&oacute;n de algunos de los servicios ofrecidos en su web a la previa cumplimentaci&oacute;n del correspondiente formulario.</p>
<p class="p4">Todos los contenidos del sitio web, como textos, fotograf&iacute;as, gr&aacute;ficos, im&aacute;genes, iconos, tecnolog&iacute;a, software, as&iacute; como su dise&ntilde;o y c&oacute;digos fuente, constituyen una obra cuya propiedad pertenece a LA FORNELLONA, S.L., sin que puedan entenderse cedidos al usuario ninguno de los derechos de explotaci&oacute;n sobre los mismos m&aacute;s all&aacute; de lo estrictamente necesario para el correcto uso de la web.</p>
<p class="p4">El usuario garantiza la autenticidad y actualidad de todos aquellos datos que comunique a LA FORNELLONA, S.L. y ser&aacute; el &uacute;nico responsable de las manifestaciones falsas o inexactas que realice.</p>
<p class="p4">El usuario se obliga a hacer un uso correcto del sitio web de conformidad con las leyes, la buena fe, el orden p&uacute;blico, los usos del tr&aacute;fico y el presente Aviso Legal. El usuario responder&aacute; frente a LA FORNELLONA, S.L. o frente a terceros, de cualesquiera da&ntilde;os y perjuicios que pudieran causarse como consecuencia del incumplimiento de dicha obligaci&oacute;n.</p>
<ul class="ul1">
<li class="li1"><strong>POL&Iacute;TICA DE ENLACES Y EXENCIONES DE RESPONSABILIDADES</strong></li>
</ul>
<p class="p4">LA FORNELLONA, S.L. no se hace responsable del contenido de las p&aacute;ginas web a las que el usuario pueda acceder a trav&eacute;s de los enlaces establecidos en LA WEB y declara que en ning&uacute;n caso proceder&aacute; a examinar o ejercitar ning&uacute;n tipo de control sobre el contenido de otras p&aacute;ginas de la red. Asimismo, tampoco garantizar&aacute; la disponibilidad t&eacute;cnica, exactitud, veracidad, validez o legalidad de p&aacute;ginas ajenas a su propiedad a las que se pueda acceder por medio de los enlaces.</p>
<p class="p4">LA FORNELLONA, S.L. declara haber adoptado todas las medidas necesarias para evitar cualesquiera da&ntilde;os que, a los usuarios de LA WEB, pudieran derivarse de la navegaci&oacute;n por la misma. En consecuencia, LA FORNELLONA, S.L. no se hace responsable, en ning&uacute;n caso, de los eventuales da&ntilde;os que por la navegaci&oacute;n por Internet pudiera sufrir el usuario.</p>
<ul class="ul1">
<li class="li1"><strong>USO DE COOKIES Y DATOS ESTAD&Iacute;STICOS</strong></li>
</ul>
<p class="p4">Esta p&aacute;gina web puede recoger datos de sus visitantes por medio del uso de cookies, donde se recabar&aacute; informaci&oacute;n personal relacionada con su navegaci&oacute;n. Para conocer de manera clara y precisa las cookies que utilizamos, cu&aacute;les son sus finalidades y c&oacute;mo puede configurarlas o deshabilitarlas, consulte nuestra <span class="s5"><strong><em>pol&iacute;tica de cookies</em></strong></span>, en su caso.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p4">El usuario tiene la posibilidad de configurar su navegador de modo que se le informe de la recepci&oacute;n de cookies, pudiendo, si as&iacute; lo desea, impedir que sean instaladas en su disco duro.</p>
<ul class="ul1">
<li class="li7"><strong>PROTECCI&Oacute;N DE DATOS<span class="Apple-converted-space">&nbsp;</span></strong></li>
</ul>
<p class="p4">En el caso de que en la web se recojan datos de car&aacute;cter personal, para m&aacute;s informaci&oacute;n consultar la <span class="s5"><strong><em>pol&iacute;tica de privacidad</em></strong></span>.</p>`
    }}/>);

export default GloriaLegalAdvise;
