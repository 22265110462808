import {CHANGE_PAYMENT_METHOD_SELECTOR_VISIBILITY} from "../../types/paymentMethodTypes";

export function changePaymentMethodSelectorVisibilityAction(visibility) {
    return (dispatch) => {
        dispatch(changeVisibility(visibility));
    }
}

const changeVisibility = visibility => ({
    type: CHANGE_PAYMENT_METHOD_SELECTOR_VISIBILITY,
    payload: visibility
})
