import {CHANGE_VOUCHERS_VISIBILITY} from "../../types/voucherTypes";

export function changeVouchersVisibilityAction(visible) {
    return (dispatch) => {
        dispatch(changeVisibility(visible));
    }
}

const changeVisibility = visible => ({
   type: CHANGE_VOUCHERS_VISIBILITY,
   payload: visible
});
