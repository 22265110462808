import axiosClient from "../config/axios";

export function fetchProductsAsync(limit = 10, offset = 0) {
    return axiosClient.get(`/products?offset=${offset}&limit=${limit}`)
        .then(response => response.data)
        .catch(error => new Error(error.toString()));
}

export function fetchFeaturedProductsAsync() {
    return axiosClient.get('/products?featured=true')
        .then(response => response.data)
        .catch(error => new Error(error.toString()));
}

export function getProductsByCategoryIdAsync(categoryId) {
    return axiosClient.get(`families/${categoryId}/products`)
        .then(response => response.data)
        .catch(error => new Error(error.toString()));
}